import { useEffect } from 'react'

/**
 * A custom hook to allow for invoking a callback function when there is a pointer up outside of the provided
 * DOM element (in the "el" option).
 *
 * @param [opts.allowTargetNotInDom] Allows for the provided callback to get invoked even if the Document no
 *  longer contains the target of the event.
 * @param [opts.exceptionClassName] Sometimes there's an element outside of the hierarchy of the child that
 *  we don't want to count as an outside click. Provide this className option to use that class as an exception.
 * @param [opts.restrictToClass] This will only react to click events that occur on an element that has this
 *  value in their classList.
 */
export function useTouchOutside({
  allowTargetNotInDom = false,
  el,
  enabled,
  exceptionClassName = null,
  onTouchOutside,
  restrictToClass = '',
}: {
  allowTargetNotInDom?: boolean
  el: HTMLElement | null
  enabled: boolean
  exceptionClassName?: string | null
  onTouchOutside: (event: MouseEvent) => void
  restrictToClass?: string
}): void {
  useEffect(() => {
    let lastMouseDownX = 0
    let lastMouseDownY = 0
    let lastMouseDownWasOutside = false

    function isEventOutsideElement(event: MouseEvent) {
      const eventTargetNode = event.target as Element

      return (
        !el?.contains(eventTargetNode) && // Make sure our wrapper doesn't contain the target.
        (!restrictToClass ||
          eventTargetNode.classList.contains(restrictToClass)) &&
        // Make sure the target is still in the DOM. May be destroyed due to a re-render.
        (allowTargetNotInDom || document.contains(eventTargetNode)) &&
        // Sometimes there's an element outside of the hierarchy of the child that we don't want
        // to count as an outside click. Perhaps there's a better way to do this but this will
        // work for now.
        (!exceptionClassName ||
          !eventTargetNode.closest(`.${exceptionClassName}`))
      )
    }

    function onDocumentPointerDown(event: MouseEvent) {
      lastMouseDownX = event.offsetX
      lastMouseDownY = event.offsetY
      lastMouseDownWasOutside = isEventOutsideElement(event)
    }

    function onDocumentPointerUp(event: MouseEvent) {
      // The calculations here for the drag were taken from https://stackoverflow.com/a/3028037/838209.
      const deltaX = event.offsetX - lastMouseDownX
      const deltaY = event.offsetY - lastMouseDownY
      const distSq = deltaX * deltaX + deltaY * deltaY
      const isDrag = distSq > 3
      const isDragException = isDrag && !lastMouseDownWasOutside

      if (isEventOutsideElement(event) && !isDragException) {
        onTouchOutside(event)
      }
    }

    if (enabled) {
      // I'd like to use pointer events here but Safari 12 doesn't support them.
      document.addEventListener('mousedown', onDocumentPointerDown)
      document.addEventListener('mouseup', onDocumentPointerUp)
    }

    return () => {
      document.removeEventListener('mousedown', onDocumentPointerDown)
      document.removeEventListener('mouseup', onDocumentPointerUp)
    }
  }, [
    allowTargetNotInDom,
    el,
    enabled,
    exceptionClassName,
    onTouchOutside,
    restrictToClass,
  ])
}
