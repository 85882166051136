import { useEffect, useRef } from 'react'

const InfiniteScrollTrigger = ({
  onTriggered,
}: {
  onTriggered(): void
}): JSX.Element => {
  const target = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0] && entries[0].isIntersecting) {
          onTriggered()
        }
      },
      { rootMargin: '50px 0px 0px' }
    )

    if (target.current) {
      observer.observe(target.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [onTriggered])

  return <div ref={target} />
}

export default InfiniteScrollTrigger
