import { sortBy } from 'lodash-es'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { IngredientShort } from 'types/domainModels/recipes'

import { useDebounce } from 'hooks/general'
import { useSearchIngredients } from 'hooks/recipes/ingredients'
import SearchField from 'components/common/SearchField'

const SearchParts = (): JSX.Element => {
  const navigate = useNavigate()

  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 250)

  const {
    data: searchIngredientsResults,
    error: searchIngredientsError,
    isError: hasSearchIngredientsError,
    isLoading: isSearchingIngredients,
  } = useSearchIngredients({ limit: 50, nameLike: debouncedSearch })

  const results = searchIngredientsResults?.ingredients
  const sortedResults = sortByRelevance({ results, search })

  return (
    <SearchField
      OptionComponent={SearchPartsResult}
      hasQuery={!!search}
      isSearching={!!search && isSearchingIngredients}
      name="parts"
      onChangeQuery={(e) => {
        setSearch(e.target.value)
      }}
      onSelectOption={(part) => {
        if (part) {
          navigate(part.id)
        }
      }}
      options={sortedResults}
      searchError={hasSearchIngredientsError ? searchIngredientsError : null}
    />
  )
}

export default SearchParts

const SearchPartsResult = ({
  option,
}: {
  option: IngredientShort
}): JSX.Element => {
  return <div>{option.name}</div>
}

const sortByRelevance = ({
  results,
  search,
}: {
  results: IngredientShort[] | undefined
  search: string
}) => {
  return sortBy(results, (result) => {
    return result.name.toLowerCase().indexOf(search.toLowerCase())
  })
}
