import { ReactNode } from 'react'
import { useField } from 'formik'

import FormGroup from './FormGroup'
import Select, { SelectProps, SelectRef } from './Select'

export type { SelectRef }

function FormSelect<Option, IsMulti extends boolean = false>({
  label,
  labelFor,
  ...props
}: Omit<SelectProps<Option, IsMulti>, 'hasError' | 'onChange' | 'value'> & {
  label?: ReactNode
  labelFor?: string
  name: string
}): JSX.Element {
  const [field, meta, helpers] = useField(props.name)
  const hasError = !!(meta.touched && meta.error)

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      labelFor={labelFor}
    >
      <Select
        {...field}
        {...props}
        hasError={hasError}
        onBlur={() => {
          helpers.setTouched(true)
        }}
        onChange={(value) => {
          helpers.setValue(value)
        }}
      />
    </FormGroup>
  )
}

export default FormSelect
