import { useEffect, useState } from 'react'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'

export function useDebounce(value: string, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export function useUpdateOnValueChange<ValueType>(value: ValueType) {
  const stateReturn = useState(value)

  useDeepCompareEffectNoCheck(() => {
    stateReturn[1](value)
  }, [value])

  return stateReturn
}
