import { apiDelete, get, patch, post } from './baseAPI'
import { Procedure } from 'types/domainModels/recipes'

export interface ApproveProcedure {
  procedureID: string
}

export interface CreateProcedureStep {
  data: { instructions: string }
  procedureID: string
}

export interface DeleteProcedureStep {
  procedureID: string
  stepID: string
}

export interface EditProcedureStep {
  data: { instructions: string }
  procedureID: string
  stepID: string
}

export interface GetProcedure {
  procedureID: string
}

export const ENDPOINTS = {
  APPROVE_PROCEDURE: ({ procedureID }: ApproveProcedure) => ({
    path: `/procedures/${procedureID}/approve`,
    version: 'procurement/v1' as const,
  }),
  CREATE_PROCEDURE_STEP: ({
    procedureID,
  }: Omit<CreateProcedureStep, 'data'>) => ({
    path: `/procedures/${procedureID}/steps`,
    version: 'procurement/v1' as const,
  }),
  DELETE_PROCEDURE_STEP: ({ procedureID, stepID }: DeleteProcedureStep) => ({
    path: `/procedures/${procedureID}/steps/${stepID}`,
    version: 'procurement/v1' as const,
  }),
  EDIT_PROCEDURE_STEP: ({
    procedureID,
    stepID,
  }: Omit<EditProcedureStep, 'data'>) => ({
    path: `/procedures/${procedureID}/steps/${stepID}`,
    version: 'procurement/v1' as const,
  }),
  GET_PROCEDURE: ({ procedureID }: GetProcedure) => ({
    path: `/procedures/${procedureID}`,
    version: 'procurement/v1' as const,
  }),
}

export async function approveProcedure({
  procedureID,
}: ApproveProcedure): Promise<Procedure> {
  return post<Procedure>(ENDPOINTS.APPROVE_PROCEDURE({ procedureID }))
}

export async function createProcedureStep({
  data,
  procedureID,
}: CreateProcedureStep): Promise<Procedure> {
  return post<Procedure>({
    data,
    ...ENDPOINTS.CREATE_PROCEDURE_STEP({ procedureID }),
  })
}

export async function deleteProcedureStep(
  opts: DeleteProcedureStep
): Promise<void> {
  return apiDelete<void>(ENDPOINTS.DELETE_PROCEDURE_STEP(opts))
}

export async function editProcedureStep({
  data,
  procedureID,
  stepID,
}: EditProcedureStep): Promise<Procedure> {
  return patch<Procedure>({
    data,
    ...ENDPOINTS.EDIT_PROCEDURE_STEP({ procedureID, stepID }),
  })
}

export async function getProcedure(opts: GetProcedure): Promise<Procedure> {
  return get<Procedure>(ENDPOINTS.GET_PROCEDURE(opts))
}
