import { get, post, put } from './baseAPI'
import { Recipe, RecipeProcurability } from 'types/domainModels/recipes'

export interface CreateRecipe {
  data: { name: string }
}

export interface EditRecipe {
  data: { name: string }
  recipeID: string
}

export interface GetRecipeProcurability {
  recipeID: string
}

export const ENDPOINTS = {
  CREATE_RECIPE: () => ({
    path: '/recipes',
    version: 'recipes/v1' as const,
  }),
  EDIT_RECIPE: ({ recipeID }: Omit<EditRecipe, 'data'>) => ({
    path: `/recipes/${recipeID}`,
    version: 'recipes/v1' as const,
  }),
  GET_RECIPE_PROCURABILITY: ({
    recipeID,
  }: Pick<GetRecipeProcurability, 'recipeID'>) => ({
    path: `/recipes/${recipeID}/procurability`,
    version: 'procurement/v1' as const,
  }),
}

export function createRecipe({ data }: CreateRecipe): Promise<Recipe> {
  return post<Recipe>({ data, ...ENDPOINTS.CREATE_RECIPE() })
}

export function editRecipe({ data, recipeID }: EditRecipe): Promise<Recipe> {
  return put<Recipe>({
    data,
    ...ENDPOINTS.EDIT_RECIPE({ recipeID }),
  })
}

export function getRecipeProcurability(opts: GetRecipeProcurability) {
  return get<RecipeProcurability>(ENDPOINTS.GET_RECIPE_PROCURABILITY(opts))
}
