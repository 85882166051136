import { flatMap } from 'lodash-es'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { getCategoryOptions } from 'utils/categories'
import { PurchasedGood } from 'types/domainModels/purchasedGoods'

import { useCategories } from 'hooks/purchasedGoods/tools'
import { useDebounce } from 'hooks/general'
import { useFilters } from 'contexts/filters'
import {
  usePurchasedGoods,
  useSearchPurchasedGoods,
} from 'hooks/purchasedGoods/purchasedGoods'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Button from 'components/common/Button'
import CreatePurchasedGoodModal from './CreatePurchasedGoodModal'
import FormGroup from 'components/common/FormGroup'
import InfiniteScrollTrigger from 'components/common/InfiniteScrollTrigger'
import SearchField from 'components/common/SearchField'
import Select from 'components/common/Select'
import TableHeader from 'components/common/TableHeader'

export const LIST_PURCHASED_GOODS_FILTER_KEYS = ['category'] as const
type FilterKey = (typeof LIST_PURCHASED_GOODS_FILTER_KEYS)[number]

const ListPurchasedGoodsPage = (): JSX.Element => {
  const { filters, onChangeFilters } = useFilters()

  const {
    data: getPurchasedGoodsResult,
    error: loadPurchasedGoodsError,
    fetchNextPage,
    hasNextPage,
    isError: hasLoadPurchasedGoodsError,
    isFetchingNextPage,
    isLoading: isLoadingPurchasedGoods,
  } = usePurchasedGoods({ filters, limit: 50 })

  const purchasedGoods = flatMap(getPurchasedGoodsResult?.pages, (page) => {
    return page.purchasedGoods
  })

  return (
    <>
      <div className="mb-4 flex justify-between">
        <h1 className="text-2xl" id="purchased-goods-header">
          Purchased Goods
        </h1>

        <div className="w-48">
          <CreatePurchasedGood />
        </div>
      </div>

      <div className="mb-4 flex items-end divide-x divide-light-grey">
        <div className="pr-4">
          <PurchasedGoodsSearch />
        </div>
        <div className="w-96 pl-4">
          <PurchasedGoodsFilters
            filters={filters}
            onChangeFilters={onChangeFilters}
          />
        </div>
      </div>

      <PurchasedGoodsTable
        hasLoadPurchasedGoodsError={hasLoadPurchasedGoodsError}
        hasNextPage={!!hasNextPage}
        isLoadingNextPage={isFetchingNextPage}
        isLoadingPurchasedGoods={isLoadingPurchasedGoods}
        loadPurchasedGoodsError={loadPurchasedGoodsError}
        onLoadMorePurchasedGoods={fetchNextPage}
        purchasedGoods={purchasedGoods}
      />
    </>
  )
}

export default ListPurchasedGoodsPage

const CreatePurchasedGood = (): JSX.Element => {
  const navigate = useNavigate()

  const [isCreatePGModalOpen, setIsCreatePGModalOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => {
          setIsCreatePGModalOpen(true)
        }}
      >
        + New Purchased Good
      </Button>

      {isCreatePGModalOpen && (
        <CreatePurchasedGoodModal
          onCloseModal={() => {
            setIsCreatePGModalOpen(false)
          }}
          onCreatePurchasedGood={(purchasedGood) => {
            navigate(purchasedGood.id)
          }}
        />
      )}
    </>
  )
}

const PurchasedGoodsSearch = () => {
  const navigate = useNavigate()

  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 250)

  const {
    data: searchPurchasedGoodsResults,
    error: searchPurchasedGoodsError,
    isError: hasSearchPurchasedGoodsError,
    isLoading: isSearchingPurchasedGoods,
  } = useSearchPurchasedGoods({ limit: 50, query: debouncedSearch })

  const purchasedGoodsResults = flatMap(
    searchPurchasedGoodsResults?.pages,
    (page) => {
      return page.purchasedGoods
    }
  )

  return (
    <div className="text-sm">
      <SearchField
        OptionComponent={PurchasedGoodsSearchResult}
        hasQuery={!!search}
        isSearching={!!search && isSearchingPurchasedGoods}
        name="purchased goods"
        onChangeQuery={(e) => {
          setSearch(e.target.value)
        }}
        onSelectOption={(purchasedGood) => {
          if (purchasedGood) {
            navigate(purchasedGood.id)
          }
        }}
        options={purchasedGoodsResults}
        resultsHeader={
          <div className="grid grid-cols-3 py-1 text-xs uppercase text-dark-grey">
            <div>Base SKU</div>
            <div>Name</div>
            <div>Category</div>
          </div>
        }
        searchError={
          hasSearchPurchasedGoodsError ? searchPurchasedGoodsError : null
        }
      />
    </div>
  )
}

const PurchasedGoodsSearchResult = ({
  option,
}: {
  option: PurchasedGood
}): JSX.Element => {
  return (
    <div className="grid grid-cols-3">
      <div>{option.baseSKU}</div>
      <div>{option.ingredientName}</div>
      <div>{option.category}</div>
    </div>
  )
}

const PurchasedGoodsFilters = ({
  filters,
  onChangeFilters,
}: {
  filters: Record<FilterKey, string>
  onChangeFilters(filters: Partial<Record<FilterKey, string>>): void
}): JSX.Element => {
  const {
    data: categories = [],
    error: loadCategoriesError,
    isError: hasLoadCategoriesError,
    isLoading: isLoadingCategories,
  } = useCategories()

  const categoryOptions = getCategoryOptions(categories)

  const selectedCategroy =
    categoryOptions.find(({ value }) => value === filters.category) ?? null

  return (
    <div>
      <FormGroup label="Category" labelFor="category-filter">
        <Select
          inputId="category-filter"
          isClearable
          isLoading={isLoadingCategories}
          loadError={
            hasLoadCategoriesError ? loadCategoriesError.message : undefined
          }
          name="category-filter"
          onChange={(category) => {
            onChangeFilters({ category: category?.value ?? '' })
          }}
          options={categoryOptions}
          value={selectedCategroy}
        />
      </FormGroup>
    </div>
  )
}

const PurchasedGoodsTable = ({
  hasLoadPurchasedGoodsError,
  hasNextPage,
  isLoadingPurchasedGoods,
  isLoadingNextPage,
  loadPurchasedGoodsError,
  onLoadMorePurchasedGoods,
  purchasedGoods,
}: {
  hasLoadPurchasedGoodsError: boolean
  hasNextPage: boolean
  isLoadingPurchasedGoods: boolean
  isLoadingNextPage: boolean
  loadPurchasedGoodsError: Error | null
  onLoadMorePurchasedGoods(): void
  purchasedGoods: PurchasedGood[]
}): JSX.Element => {
  const purchasedGoodsGridClasses = 'grid grid-cols-3 gap-4 items-center'
  const purchasedGoodsTableRowClasses = `${purchasedGoodsGridClasses} py-5 sm:text-sm border-b border-light-grey`

  return (
    <div
      aria-describedby="purchased-goods-header"
      aria-label="Purchased Goods"
      role="grid"
    >
      <div className="sticky top-[45px] bg-white">
        <TableHeader>
          <div className={`${purchasedGoodsGridClasses} pt-4`} role="row">
            <span role="columnheader">Base Sku</span>
            <span role="columnheader">Name</span>
            <span role="columnheader">Category</span>
          </div>
        </TableHeader>
      </div>
      <div role="rowgroup">
        {purchasedGoods.length === 0 && (
          <>
            {isLoadingPurchasedGoods ? (
              <PurchasedGoodsTableLoadingRows
                purchasedGoodsTableRowClasses={purchasedGoodsTableRowClasses}
              />
            ) : (
              <>
                {hasLoadPurchasedGoodsError ? (
                  <div className="mt-2">
                    <APIErrorDisplay error={loadPurchasedGoodsError} />
                  </div>
                ) : (
                  <p className="py-2 text-sm">No purchased goods</p>
                )}
              </>
            )}
          </>
        )}

        {purchasedGoods.map(({ baseSKU, category, id, ingredientName }) => {
          return (
            <div key={id} className={purchasedGoodsTableRowClasses} role="row">
              <div role="cell">
                <Link className="text-blue" to={`/purchased-goods/${id}`}>
                  {baseSKU}
                </Link>
              </div>
              <span role="cell">{ingredientName}</span>
              <span role="cell">{category}</span>
            </div>
          )
        })}

        {isLoadingNextPage ? (
          <PurchasedGoodsTableLoadingRows
            purchasedGoodsTableRowClasses={purchasedGoodsTableRowClasses}
          />
        ) : hasNextPage ? (
          <InfiniteScrollTrigger onTriggered={onLoadMorePurchasedGoods} />
        ) : null}
      </div>
    </div>
  )
}

const PurchasedGoodsTableLoadingRows = ({
  purchasedGoodsTableRowClasses,
}: {
  purchasedGoodsTableRowClasses: string
}): JSX.Element => {
  return (
    <>
      {new Array(5).map((_unused, i) => {
        return (
          <div key={i} className={purchasedGoodsTableRowClasses}>
            <div className="h-4 w-1/2 animate-pulse justify-self-start bg-light-grey" />
            <div className="h-4 w-1/2 animate-pulse justify-self-start bg-light-grey" />
            <div className="h-4 w-1/2 animate-pulse justify-self-start bg-light-grey" />
          </div>
        )
      })}
    </>
  )
}
