import { clsx } from 'clsx'
import { ReactNode, TextareaHTMLAttributes } from 'react'

export type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  hasError?: boolean
  rightIcon?: ReactNode
  smallerText?: boolean
}

const Textarea = ({
  hasError = false,
  rightIcon = null,
  smallerText = false,
  ...rest
}: Props): JSX.Element => {
  const textarea = (
    <textarea
      className={clsx('w-full rounded border p-2', {
        'border-red': hasError,
        'border-light-grey': !hasError,
        'pr-8': !!rightIcon,
        'text-xs': smallerText,
        'text-sm': !smallerText,
      })}
      data-testid={rest.name}
      {...rest}
    />
  )

  if (rightIcon) {
    return (
      <div className="relative">
        {textarea}
        <div className="absolute right-2 top-2">{rightIcon}</div>
      </div>
    )
  }

  return textarea
}

export default Textarea
