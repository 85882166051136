import { Link } from 'react-router-dom'

import { DraftLineItem } from 'types/domainModels/recipes'

const LineItemName = ({
  lineItem,
}: {
  lineItem: DraftLineItem
}): JSX.Element => {
  if (lineItem.ingredientType === 'recipe') {
    return (
      <Link
        className="text-blue"
        target="_blank"
        to={`/parts/${lineItem.id}/versions`}
      >
        {lineItem.name}
      </Link>
    )
  }

  return <span>{lineItem.name}</span>
}

export default LineItemName
