import { useState } from 'react'

import { Draft, IngredientRecipe } from 'types/domainModels/recipes'
import { getOrderedDrafts } from 'utils/drafts'
import { ReactSelectValue } from 'types/internal'

import { useCreateDraft } from 'hooks/recipes/drafts'
import FormGroup from 'components/common/FormGroup'
import Modal, {
  ModalBody,
  ModalButtons,
  ModalHeader,
} from 'components/common/Modal'
import Select from 'components/common/Select'

const NewDraftModal = ({
  existingDrafts,
  onCloseModal,
  onNewDraftCreated,
  part,
}: {
  existingDrafts: Draft[]
  onCloseModal(): void
  onNewDraftCreated(newDraft: Draft): void
  part: IngredientRecipe
}): JSX.Element => {
  const [copyFromDraftID, setCopyFromDraftID] =
    useState<ReactSelectValue<string> | null>(null)

  const {
    error: createNewDraftError,
    isError: hasCreateNewDraftError,
    isLoading: isCreatingNewDraft,
    mutate: createNewDraft,
  } = useCreateDraft({
    onSuccess: (draft) => {
      onNewDraftCreated(draft)
    },
    partID: part.id,
  })

  const orderedDrafts = getOrderedDrafts(existingDrafts)
  const copyFromDraftOptions = orderedDrafts.map((draft) => {
    return { label: `Draft ${draft.draftNumber}`, value: draft.id }
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="w-create-new-draft-modal">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>
              Create New Draft
            </ModalHeader>
          </div>

          <FormGroup label="Copy From (optional)">
            <Select
              isClearable
              name="copy-from-draft-id"
              onChange={setCopyFromDraftID}
              options={copyFromDraftOptions}
              value={copyFromDraftID}
            />
          </FormGroup>

          <div className="mt-8">
            <ModalButtons
              apiError={hasCreateNewDraftError ? createNewDraftError : null}
              isLoading={isCreatingNewDraft}
              mainActionBtnText="Create"
              onCancel={onCloseModal}
              onMainAction={() => {
                return createNewDraft({
                  copyFromDraftID: copyFromDraftID?.value,
                })
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default NewDraftModal
