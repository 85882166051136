import { Form, Formik, useField } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import { ReactNode, useEffect, useRef, useState } from 'react'

import {
  canPromoteDraft,
  DRAFT_STATUS_OPTIONS,
  getDraftStatusDisplay,
  getDraftStatusOptions,
  getLineItemCostSums,
  getLineItemCostVersion,
  getLineItemOptions,
  getPostCookingStats,
  getTotalWeight,
  isDraftEditable,
  LineItemFormData,
  LineItemFormDataValidated,
  validateAddLineItemData,
} from 'utils/drafts'
import {
  Draft,
  DraftLineItem as IDraftLineItem,
} from 'types/domainModels/recipes'
import { formatPercent, highlightMatchingText } from 'utils/general'
import { getAPIErrorMessage } from 'utils/api'

import {
  useCreateLineItem,
  useDeleteLineItem,
  useEditDraft,
  useEditDraftStatus,
  useEditLineItem,
  useLineItemCosts,
  useLineItemsCosts,
} from 'hooks/recipes/drafts'
import { useCreateVersion } from 'hooks/recipes/versions'
import { usePartVersions } from 'hooks/recipes/parts'
import { useSearchIngredients } from 'hooks/recipes/ingredients'
import { useToast } from 'contexts/toast'
import Badge, { BadgeStyle } from 'components/common/Badge'
import Button from 'components/common/Button'
import ButtonLoading from 'components/common/ButtonLoading'
import CircleLoader from 'components/common/CircleLoader'
import CreatePartModal from './CreatePartModal'
import ExclamationIcon from 'components/common/icons/ExclamationIcon'
import FormFieldError from 'components/common/FormFieldError'
import FormInput from 'components/common/FormInput'
import FormSelect, { SelectRef } from 'components/common/FormSelect'
import HoverHighlight from 'components/common/HoverHighlight'
import InlineEditInput, {
  Props as InlineEditInputProps,
} from 'components/common/InlineEditInput'
import InlineEditSelect from 'components/common/InlineEditSelect'
import InlineEditTextarea, {
  Props as InlineEditTextareaProps,
} from 'components/common/InlineEditTextarea'
import LabeledField from './LabeledField'
import LineItemName from './LineItemName'
import MinusCircleIcon from 'components/common/icons/MinusCircleIcon'
import RecipeCostsGrid, {
  Props as RecipeCostsGridProps,
} from './RecipeCostsGrid'
import TableHeader from 'components/common/TableHeader'
import { compact, orderBy, round } from 'lodash-es'

const PartDraft = ({
  draft,
  partID,
}: {
  draft: Draft
  partID: string
}): JSX.Element => {
  const totalWeight = getTotalWeight(draft)

  return (
    <div className="flex justify-between divide-x divide-light-grey lg:flex-wrap lg:space-y-4 lg:divide-x-0">
      <div className="w-2/3 pr-4 lg:w-full lg:pr-0">
        <DraftRecipe draft={draft} partID={partID} totalWeight={totalWeight} />
      </div>
      <div className="w-1/3 pl-4 lg:w-1/2 lg:pl-0 md:w-full">
        <div className="space-y-6">
          <DraftMetaSection draft={draft} partID={partID} />
          <DraftNotesSection draft={draft} partID={partID} />
          <DraftPostCookingSection draft={draft} partID={partID} />
        </div>
      </div>
    </div>
  )
}

export default PartDraft

const DraftRecipe = ({
  draft,
  partID,
  totalWeight,
}: {
  draft: Draft
  partID: string
  totalWeight: number
}): JSX.Element => {
  const isEditable = isDraftEditable(draft)
  const recipeTableClasses = 'grid grid-cols-draft-line-items-table gap-4'

  const orderedLineItems = orderBy(draft.lineItems, ({ name }) => {
    return name.toLowerCase()
  })

  const {
    costs: lineItemCosts,
    isError: hasLoadLineItemsConstsError,
    isLoading: isLoadingLineItemsCosts,
  } = useLineItemsCosts({
    lineItems: draft.lineItems,
  })
  const { chicago: chicagoCost, slc: slcCost } = getLineItemCostSums({
    costs: compact(lineItemCosts),
    draft,
  })

  const commonCostProps: Partial<RecipeCostsGridProps> = {
    isLoading: isLoadingLineItemsCosts,
    loadError: hasLoadLineItemsConstsError
      ? new Error('Failed to load cost information')
      : null,
    showVersion: false,
  }

  return (
    <>
      <h2 className="mb-2 text-xl">Recipe</h2>
      <div className="-mt-6">
        <TableHeader>
          <div className={`${recipeTableClasses} items-end`}>
            <div>Line Item</div>
            <div className="text-center">Weight (G)</div>
            <div className="text-center">Percent (%)</div>
            <div className="text-center">
              <div className="mb-1">Facility Networks</div>
              <div className="grid grid-cols-3 items-end">
                <div className="text-left">Name</div>
                <div>Active Version</div>
                <div>Cost / lb</div>
              </div>
            </div>
            <div />
          </div>
        </TableHeader>
        <div>
          {orderedLineItems.map((lineItem) => {
            return (
              <DraftLineItem
                key={lineItem.id}
                draftID={draft.id}
                isEditable={isEditable}
                lineItem={lineItem}
                partID={partID}
                recipeTableClasses={recipeTableClasses}
                totalWeight={totalWeight}
              />
            )
          })}

          {isEditable && <AddDraftRow draft={draft} partID={partID} />}

          <div
            className={`${recipeTableClasses} py-2 text-xs font-bold uppercase text-dark-grey`}
            data-testid="draft-recipe-totals-row"
          >
            <div>Totals</div>
            <div className="text-center">{round(totalWeight, 2)}</div>
            <div></div>
            <div className="space-y-1">
              <RecipeCostsGrid
                {...commonCostProps}
                costPerPound={chicagoCost}
                facilityNetwork="Chicago"
              />
              <RecipeCostsGrid
                {...commonCostProps}
                costPerPound={slcCost}
                facilityNetwork="SLC"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DraftLineItem = ({
  draftID,
  isEditable,
  lineItem,
  partID,
  recipeTableClasses,
  totalWeight,
}: {
  draftID: string
  isEditable: boolean
  lineItem: IDraftLineItem
  partID: string
  recipeTableClasses: string
  totalWeight: number
}): JSX.Element => {
  const { openToast } = useToast()

  const {
    data: lineItemCosts,
    error,
    isError,
    isLoading: isLoadingLineItemCosts,
  } = useLineItemCosts({ lineItem })

  const { chicago: chicagoCost, slc: slcCost } =
    lineItemCosts?.facilityNetworkCosts || {}

  const { mutateAsync: editLineItem } = useEditLineItem({ draftID, partID })

  const { isLoading: isDeletingLineItem, mutate: deleteLineItem } =
    useDeleteLineItem({
      draftID,
      lineItem,
      onError: (err) => {
        openToast(
          `Failed to delete line item. Error: ${getAPIErrorMessage(err)}`,
          'error'
        )
      },
      partID,
    })

  const percentOfTotalWeight = lineItem.quantityGrams / totalWeight

  const commonCostProps: Partial<RecipeCostsGridProps> = {
    isLoading: isLoadingLineItemCosts,
    loadError: isError ? error : null,
    smallVersion: lineItem.ingredientType === 'purchased_good',
  }

  return (
    <div
      className={`${recipeTableClasses} border-b border-light-grey py-2 text-sm`}
      data-testid={`draft-recipe-line-item-${lineItem.id}`}
    >
      <div>
        <LineItemName lineItem={lineItem} />
      </div>
      <InlineEditInput
        initialValue={`${lineItem.quantityGrams}`}
        isEditable={isEditable}
        name={`weight-${lineItem.id}`}
        onChange={(value) => {
          return editLineItem({
            ingredientID: lineItem.id,
            weight: Number(value),
          })
        }}
        type="number"
      >
        <div className="text-center">{`${lineItem.quantityGrams}`}</div>
      </InlineEditInput>
      <div className="text-center">{formatPercent(percentOfTotalWeight)}</div>
      <div>
        <RecipeCostsGrid
          {...commonCostProps}
          costPerPound={chicagoCost?.costPerPound}
          facilityNetwork="Chicago"
          versionNumber={getLineItemCostVersion(chicagoCost)}
        />
        <RecipeCostsGrid
          {...commonCostProps}
          costPerPound={slcCost?.costPerPound}
          facilityNetwork="SLC"
          versionNumber={getLineItemCostVersion(slcCost)}
        />
      </div>
      {isEditable && (
        <div className="flex items-center space-x-4">
          {isDeletingLineItem ? (
            <div className="h-4 w-4">
              <CircleLoader isColored={true} />
            </div>
          ) : (
            <div
              aria-label="Delete line item"
              className="h-4 w-4 cursor-pointer"
              onClick={() => {
                deleteLineItem()
              }}
            >
              <MinusCircleIcon />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const AddDraftRow = ({
  draft,
  partID,
}: {
  draft: Draft
  partID: string
}): JSX.Element => {
  const [isAdding, setIsAdding] = useState(false)

  if (isAdding) {
    return (
      <AddDraftLineItemForm
        draft={draft}
        onCancel={() => {
          setIsAdding(false)
        }}
        onLineItemAdded={() => {
          setIsAdding(false)
        }}
        partID={partID}
      />
    )
  }

  return (
    <div className="flex border-b border-light-grey py-2 text-sm text-dark-grey">
      <HoverHighlight
        onActivate={() => {
          setIsAdding(true)
        }}
      >
        <span className="pr-12">Click to add...</span>
      </HoverHighlight>
    </div>
  )
}

const AddDraftLineItemForm = ({
  draft,
  onCancel,
  onLineItemAdded,
  partID,
}: {
  draft: Draft
  onCancel(): void
  onLineItemAdded(): void
  partID: string
}): JSX.Element => {
  const weightRef = useRef<HTMLInputElement | null>(null)
  const weightFocusTimeout = useRef<number | undefined>()

  const {
    error: addLineItemError,
    isError: hasAddLineItemError,
    isLoading: isAddingLineItem,
    mutate: addLineItem,
  } = useCreateLineItem({
    draft,
    onSuccess: () => {
      onLineItemAdded()
    },
    partID,
  })

  useEffect(() => {
    return () => {
      window.clearTimeout(weightFocusTimeout.current)
    }
  }, [])

  return (
    <Formik<LineItemFormData>
      initialValues={{
        ingredientID: null,
        weight: '',
      }}
      onSubmit={(formData) => {
        const validatedFormData = formData as LineItemFormDataValidated

        return addLineItem({
          ...validatedFormData,
          ingredientID: validatedFormData.ingredientID.value,
        })
      }}
      validate={validateAddLineItemData}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form>
        <div className="grid grid-cols-draft-line-items-table gap-x-4 border-b border-light-grey py-2">
          <LineItemIngredientSelect
            addLineItemError={hasAddLineItemError ? addLineItemError : null}
            draft={draft}
            onPartCreated={() => {
              // The select will capture the focus after the options close, so
              // we set an arbitrary timeout to try to focus the weight input
              // instead.
              weightFocusTimeout.current = window.setTimeout(() => {
                weightRef.current?.focus()
              }, 30)
            }}
            partID={partID}
          />
          <div>
            <FormInput ref={weightRef} name="weight" type="number" />
          </div>
          <div className="col-span-2 flex space-x-2">
            <div className="w-20">
              <ButtonLoading isLoading={isAddingLineItem} type="submit">
                Add
              </ButtonLoading>
            </div>
            <div className="w-20">
              <Button
                buttonStyle="cancel"
                onClick={() => {
                  onCancel()
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

const LineItemIngredientSelect = ({
  addLineItemError,
  draft,
  onPartCreated,
  partID,
}: {
  addLineItemError: Error | null
  draft: Draft
  onPartCreated(): void
  partID: string
}): JSX.Element => {
  const ingredientRef = useRef<SelectRef<
    LineItemFormData['ingredientID']
  > | null>(null)

  const [ingredientNameQuery, setIngredientNameQuery] = useState('')
  const [isCreatePartModalOpen, setIsCreatePartModalOpen] = useState(false)
  const [partNameToCreate, setPartNameToCreate] = useState('')

  const [, , ingredientIDHelpers] =
    useField<LineItemFormData['ingredientID']>('ingredientID')

  const {
    data: getIngredientsResponse,
    error: loadIngredientsError,
    isError: hasLoadIngredientsError,
    isLoading: isLoadingIngredients,
  } = useSearchIngredients({ limit: 50, nameLike: ingredientNameQuery })
  const ingredients = getIngredientsResponse?.ingredients ?? []

  const ingredientOptions = getLineItemOptions({ draft, ingredients, partID })

  const orderedIngredientOptions = orderBy(ingredientOptions, ({ name }) => {
    return ingredientNameQuery
      ? name.toLowerCase().indexOf(ingredientNameQuery.toLowerCase())
      : name.toLowerCase()
  })

  return (
    <div>
      <FormSelect
        autoFocus={true}
        createMessage={`+ Create "${ingredientNameQuery}"`}
        formatOptionLabel={(option, { inputValue }) => {
          if (!option) {
            return ''
          }

          // The label may not be a string because of the "+ Create ..." option in which case
          // it's a ReactNode.
          if (inputValue && typeof option.label === 'string') {
            return highlightMatchingText({
              searchText: inputValue,
              textToHighlight: option.label,
            })
          }

          return option.label
        }}
        inputValue={ingredientNameQuery}
        isLoading={!!ingredientNameQuery && isLoadingIngredients}
        loadError={
          hasLoadIngredientsError ? loadIngredientsError.message : undefined
        }
        name="ingredientID"
        noOptionsMessage={() => {
          return ingredientNameQuery
            ? 'No line items'
            : 'Please type to search line items...'
        }}
        onClickCreate={(value) => {
          setPartNameToCreate(value)
          setIsCreatePartModalOpen(true)
        }}
        onInputChange={(value) => {
          setIngredientNameQuery(value)
        }}
        openMenuOnFocus={true}
        options={orderedIngredientOptions.map((ingredient) => {
          return {
            label: ingredient.name,
            value: ingredient.id,
          }
        })}
        selectRef={ingredientRef}
        showCreateOption={() => {
          return ingredientNameQuery.length > 0
        }}
      />

      {isCreatePartModalOpen && (
        <CreatePartModal
          createBtnText="Yes"
          initialName={partNameToCreate}
          onCloseModal={() => {
            setIngredientNameQuery(partNameToCreate)
            ingredientRef.current?.focus()

            setIsCreatePartModalOpen(false)
          }}
          onCreatePart={(part) => {
            ingredientIDHelpers.setValue({ label: part.name, value: part.id })

            onPartCreated()
            setIsCreatePartModalOpen(false)
          }}
          warning={
            <div className="flex items-start space-x-2 rounded bg-faded-gold p-2">
              <div className="mt-1 h-4 w-4 flex-shrink-0">
                <ExclamationIcon />
              </div>
              <p className="text-sm">
                Have you double checked to make sure this part has not already
                been created?
              </p>
            </div>
          }
        />
      )}

      {addLineItemError && (
        <FormFieldError error={getAPIErrorMessage(addLineItemError)} />
      )}
    </div>
  )
}

const DraftSidebarSection = ({
  children,
  title,
}: {
  children: ReactNode
  title: ReactNode
}) => {
  return (
    <div>
      <h2 className="mb-2 text-xl">{title}</h2>
      <div className="space-y-4 text-sm">{children}</div>
    </div>
  )
}

const DraftMetaSection = ({
  draft,
  partID,
}: {
  draft: Draft
  partID: string
}): JSX.Element => {
  const { mutateAsync: editDraft } = useEditDraft({ draft, partID })

  const { mutateAsync: editDraftStatus } = useEditDraftStatus({ draft, partID })

  let draftStatusStyle: BadgeStyle = 'warning'
  if (draft.status === 'tasting_approved') {
    draftStatusStyle = 'success'
  } else if (draft.status === 'tasting_rejected') {
    draftStatusStyle = 'danger'
  }

  const statusOptions = getDraftStatusOptions(draft.status)

  const statusDisplay = (
    <Badge badgeStyle={draftStatusStyle}>
      {getDraftStatusDisplay(draft.status)}
    </Badge>
  )

  return (
    <DraftSidebarSection title={`Draft ${draft.draftNumber}`}>
      <LabeledField title="Draft Status">
        <InlineEditSelect
          initialValue={
            DRAFT_STATUS_OPTIONS.find((option) => {
              return draft.status === option.value
            }) ?? null
          }
          // We intentionally always allow the draft status to be updated currently. Eventually,
          // there will probably be role restrictions around this.
          isEditable={statusOptions.length > 0}
          name="draft-status"
          onChange={(status) => {
            if (status) {
              return editDraftStatus({
                status: status.value,
                statusNotes: '',
              })
            }

            throw new Error('Cannot assign an empty draft status.')
          }}
          options={statusOptions}
        >
          {statusDisplay}
        </InlineEditSelect>
      </LabeledField>
      <EditableLabeledFieldInput
        initialValue={draft.statusNotes}
        isEditable={true}
        name="status-notes"
        onChange={(value) => {
          return editDraftStatus({ status: draft.status, statusNotes: value })
        }}
        title="Status Notes"
      >
        {draft.statusNotes}
      </EditableLabeledFieldInput>
      <DraftVersion draft={draft} partID={partID} />
      <EditableLabeledFieldInput
        initialValue={draft.authorName}
        isEditable={isDraftEditable(draft)}
        name="author-name"
        onChange={(value) => {
          return editDraft({ authorName: value })
        }}
        title="Author Name"
      >
        {draft.authorName}
      </EditableLabeledFieldInput>
    </DraftSidebarSection>
  )
}

const DraftVersion = ({
  draft,
  partID,
}: {
  draft: Draft
  partID: string
}): JSX.Element => {
  const navigate = useNavigate()
  const { openToast } = useToast()

  const { data: getVersionsResponse } = usePartVersions({ partID })
  const versions = getVersionsResponse?.versions ?? []

  const linkedVersion = versions.find((version) => {
    return version.draftID === draft.id
  })

  const { isLoading: isPromotingDraft, mutate: promoteDraft } =
    useCreateVersion({
      draft,
      onError: (err) => {
        openToast(
          `Failed to promote draft. Error: ${getAPIErrorMessage(err)}`,
          'error'
        )
      },
      onSuccess: (version) => {
        navigate(`/parts/${partID}/versions/${version.versionNumber}`)
      },
      partID,
    })

  return (
    <LabeledField title="Version #">
      {linkedVersion ? (
        <div className="flex items-center space-x-4">
          <span>{linkedVersion.versionNumber}</span>
          <Link
            className="text-xs uppercase text-blue"
            to={`../../versions/${linkedVersion.versionNumber}`}
          >
            View Versions
          </Link>
        </div>
      ) : canPromoteDraft(draft) ? (
        <div className="w-28">
          <ButtonLoading
            isLoading={isPromotingDraft}
            onClick={() => {
              promoteDraft()
            }}
          >
            Promote
          </ButtonLoading>
        </div>
      ) : null}
    </LabeledField>
  )
}

const DraftNotesSection = ({
  draft,
  partID,
}: {
  draft: Draft
  partID: string
}): JSX.Element => {
  const { mutateAsync: editDraft } = useEditDraft({ draft, partID })

  const isEditable = isDraftEditable(draft)

  return (
    <DraftSidebarSection title="Notes">
      <EditableLabeledFieldTextarea
        initialValue={draft.methodNotes}
        isEditable={isEditable}
        name="method-notes"
        onChange={(newValue) => {
          return editDraft({
            methodNotes: newValue,
          })
        }}
        rows={3}
        title="Method Notes"
      >
        {draft.methodNotes}
      </EditableLabeledFieldTextarea>
      <EditableLabeledFieldTextarea
        initialValue={draft.authorTasteNotes}
        isEditable={isEditable}
        name="author-taste-notes"
        onChange={(newValue) => {
          return editDraft({
            authorTasteNotes: newValue,
          })
        }}
        rows={3}
        title="Author Taste Notes"
      >
        {draft.authorTasteNotes}
      </EditableLabeledFieldTextarea>
    </DraftSidebarSection>
  )
}

const DraftPostCookingSection = ({
  draft,
  partID,
}: {
  draft: Draft
  partID: string
}): JSX.Element => {
  const { mutateAsync: editDraft } = useEditDraft({ draft, partID })

  const isEditable = isDraftEditable(draft)
  const { developmentYield, portionsPerRecipe } = getPostCookingStats(draft)

  return (
    <DraftSidebarSection title="Post Cooking">
      <EditableLabeledFieldInput
        initialValue={`${draft.postCookWeightGrams}`}
        isEditable={isEditable}
        name="post-cook-weight"
        onChange={(newValue) => {
          return editDraft({
            postCookWeightGrams: Number(newValue) ?? 0,
          })
        }}
        title="Post Cook Weight (G)"
        type="number"
      >
        {draft.postCookWeightGrams ? `${draft.postCookWeightGrams}` : ''}
      </EditableLabeledFieldInput>
      <LabeledField title="Development Yield">
        {developmentYield ? formatPercent(developmentYield) : ''}
      </LabeledField>
      <EditableLabeledFieldInput
        initialValue={`${draft.targetPortion}`}
        isEditable={isEditable}
        name="target-portion"
        onChange={(newValue) => {
          return editDraft({
            targetPortion: Number(newValue) ?? 0,
          })
        }}
        title="Target Portion (G)"
        type="number"
      >
        {draft.targetPortion ? `${draft.targetPortion}` : ''}
      </EditableLabeledFieldInput>
      <LabeledField title="Portions / Recipe">
        {portionsPerRecipe ? round(portionsPerRecipe, 2) : ''}
      </LabeledField>
    </DraftSidebarSection>
  )
}

function EditableLabeledFieldInput({
  children,
  title,
  ...rest
}: InlineEditInputProps & {
  children: string
  title: string
}): JSX.Element {
  return (
    <LabeledField title={title}>
      <InlineEditInput {...rest}>{children || 'N/A'}</InlineEditInput>
    </LabeledField>
  )
}

function EditableLabeledFieldTextarea({
  children,
  title,
  ...rest
}: InlineEditTextareaProps & {
  children: string
  title: string
}): JSX.Element {
  return (
    <LabeledField title={title}>
      <InlineEditTextarea {...rest}>{children || 'N/A'}</InlineEditTextarea>
    </LabeledField>
  )
}
