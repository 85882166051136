import { FacilityNetworkID } from 'types/domainModels/shared'
import { FacilitySKU } from 'types/domainModels/purchasedGoods'
import { patch } from './baseAPI'

export interface EditFacilitySKU {
  data: Partial<{
    costPerCaseCents: number
    costPerPoundCents: number
    isAvailable: boolean
    isPrimary: boolean
  }>
  facilityNetworkID: FacilityNetworkID
  purchasedGoodID: string
  vendorSKUID: string
}

export const ENDPOINTS = {
  EDIT_FACILITY_SKU: ({
    facilityNetworkID,
    purchasedGoodID,
    vendorSKUID,
  }: Pick<
    EditFacilitySKU,
    'facilityNetworkID' | 'purchasedGoodID' | 'vendorSKUID'
  >) => ({
    path: `/purchasedGoods/${purchasedGoodID}/vendorSKUs/${vendorSKUID}/facilityNetworks/${facilityNetworkID}/skus`,
    version: 'v1' as const,
  }),
}

export function editFacilitySKU({
  data,
  facilityNetworkID,
  purchasedGoodID,
  vendorSKUID,
}: EditFacilitySKU) {
  return patch<FacilitySKU>({
    data,
    ...ENDPOINTS.EDIT_FACILITY_SKU({
      facilityNetworkID,
      purchasedGoodID,
      vendorSKUID,
    }),
  })
}
