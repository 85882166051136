import Cookies from 'js-cookie'

import { getEnvVar } from './env'

export const COOKIE_NAMES = {
  JWT_TOKEN: getEnvVar('JWT_COOKIE_NAME'),
}

export function getCookie(cookieName: string) {
  return Cookies.get(cookieName)
}

export function removeCookie(cookieName: string) {
  Cookies.remove(cookieName)
}

export function setCookie({
  content,
  cookieName,
  expires,
}: {
  content: string
  cookieName: string
  expires: Date
}): void {
  Cookies.set(cookieName, content, { expires })
}
