import { some } from 'lodash-es'
import {
  useMutation,
  UseMutationOptions,
  useQueries,
  useQuery,
  UseQueryOptions,
} from 'react-query'

import {
  copyDraft,
  createDraft,
  createLineItem,
  deleteLineItem,
  EditDraft,
  editDraft,
  editDraftStatus,
  editLineItem,
  getDraft,
} from 'services/recipes/drafts'
import {
  Draft,
  DraftLineItem,
  DraftStatus,
  PurchasedGoodCosts,
  RecipeProcurability,
} from 'types/domainModels/recipes'
import { getPurchasedGoodCosts } from 'services/recipes/purchasedGoods'

import { getRecipeProcurabilityQueryObj } from './recipes'
import { useInvalidatePart } from './parts'

function getLineItemCostQueryObj({ lineItem }: { lineItem: DraftLineItem }) {
  const commonProps = { refetchOnWindowFocus: false, retry: false }

  if (lineItem.ingredientType === 'purchased_good') {
    return {
      ...commonProps,
      queryFn: () => {
        return getPurchasedGoodCosts({ procurementID: lineItem.procurementID })
      },
      queryKey: ['purchased-good-costs', lineItem.procurementID],
    }
  }

  return {
    ...commonProps,
    ...getRecipeProcurabilityQueryObj({ recipeID: lineItem.id }),
  }
}

export function useCreateDraft({
  partID,
  ...rest
}: {
  partID: string
} & UseMutationOptions<Draft, Error, { copyFromDraftID?: string }>) {
  const { invalidatePart } = useInvalidatePart()

  return useMutation(
    ({ copyFromDraftID }) => {
      if (copyFromDraftID) {
        return copyDraft({
          data: { draftID: copyFromDraftID },
          recipeID: partID,
        })
      }

      return createDraft({ recipeID: partID })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidatePart(partID, { newDraft: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useCreateLineItem({
  draft,
  partID,
  ...rest
}: {
  draft: Draft
  partID: string
} & UseMutationOptions<
  Draft,
  Error,
  { ingredientID: string; weight: number }
>) {
  const { invalidatePart } = useInvalidatePart()

  return useMutation(
    async ({ ingredientID, weight }) => {
      return createLineItem({
        data: { quantityGrams: weight },
        draftID: draft.id,
        ingredientID,
        recipeID: partID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidatePart(partID, { newDraft: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useDeleteLineItem({
  draftID,
  lineItem,
  partID,
  ...rest
}: {
  draftID: string
  lineItem: DraftLineItem
  partID: string
} & UseMutationOptions<Draft, Error>) {
  const { invalidatePart } = useInvalidatePart()

  return useMutation(
    async () => {
      return deleteLineItem({
        draftID,
        ingredientID: lineItem.id,
        recipeID: partID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidatePart(partID, { newDraft: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useDraft({
  draftID,
  recipeID,
  ...rest
}: {
  draftID: string
  recipeID: string
} & Omit<UseQueryOptions<Draft, Error>, 'queryFn' | 'queryKey'>) {
  return useQuery<Draft, Error>({
    ...rest,
    queryFn: () => {
      return getDraft({ draftID, recipeID })
    },
    queryKey: ['drafts', draftID, recipeID],
  })
}

export function useEditDraft({
  draft,
  partID,
  ...rest
}: {
  draft: Draft
  partID: string
} & UseMutationOptions<Draft, Error, EditDraft['data']>) {
  const { invalidatePart } = useInvalidatePart()

  return useMutation(
    async (data) => {
      return editDraft({
        data,
        draftID: draft.id,
        recipeID: partID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidatePart(partID, { newDraft: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useEditDraftStatus({
  draft,
  partID,
  ...rest
}: {
  draft: Draft
  partID: string
} & UseMutationOptions<
  Draft,
  Error,
  { status: DraftStatus; statusNotes: string }
>) {
  const { invalidatePart } = useInvalidatePart()

  return useMutation(
    async ({ status, statusNotes }) => {
      return editDraftStatus({
        data: { status, statusNotes },
        draftID: draft.id,
        recipeID: partID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidatePart(partID, { newDraft: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useEditLineItem({
  draftID,
  partID,
  ...rest
}: {
  draftID: string
  partID: string
} & UseMutationOptions<
  Draft,
  Error,
  { ingredientID: string; weight: number }
>) {
  const { invalidatePart } = useInvalidatePart()

  return useMutation(
    async ({ ingredientID, weight }) => {
      return editLineItem({
        data: { quantityGrams: weight },
        draftID,
        ingredientID,
        recipeID: partID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidatePart(partID, { newDraft: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useLineItemCosts({
  lineItem,
  ...rest
}: {
  lineItem: DraftLineItem
} & UseQueryOptions<PurchasedGoodCosts | RecipeProcurability, Error>) {
  return useQuery<PurchasedGoodCosts | RecipeProcurability, Error>({
    ...getLineItemCostQueryObj({ lineItem }),
    ...rest,
  })
}

export function useLineItemsCosts({
  lineItems,
}: {
  lineItems: DraftLineItem[]
}) {
  const queries = useQueries({
    queries: lineItems.map((lineItem) => {
      return getLineItemCostQueryObj({ lineItem })
    }),
  })

  return {
    costs: queries.map((query) => {
      return query.data
    }),
    isError: some(queries, ({ isError }) => isError),
    isLoading: some(queries, ({ isLoading }) => isLoading),
  }
}
