import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'

import {
  approveProcedure,
  createProcedureStep,
  deleteProcedureStep,
  editProcedureStep,
  getProcedure,
} from 'services/recipes/procedures'
import { Procedure } from 'types/domainModels/recipes'

export function useApproveProcedure({
  procedureID,
  ...rest
}: {
  procedureID: string
} & UseMutationOptions<Procedure, Error>) {
  const { invalidateProcedure } = useInvalidateProcedure()

  return useMutation(
    () => {
      return approveProcedure({ procedureID })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidateProcedure(procedureID, { newProcedure: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useCreateProcedureStep({
  procedureID,
  ...rest
}: {
  procedureID: string
} & UseMutationOptions<Procedure, Error, { instructions: string }>) {
  const { invalidateProcedure } = useInvalidateProcedure()

  return useMutation(
    ({ instructions }) => {
      return createProcedureStep({
        data: { instructions },
        procedureID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidateProcedure(procedureID, { newProcedure: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useDeleteProcedureStep({
  procedureID,
  ...rest
}: {
  procedureID: string
} & UseMutationOptions<void, Error, Procedure['steps'][number]>) {
  const { invalidateProcedure } = useInvalidateProcedure()

  return useMutation(
    (step) => {
      return deleteProcedureStep({
        procedureID,
        stepID: step.stepID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidateProcedure(procedureID)

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useEditProcedureStep({
  procedureID,
  ...rest
}: {
  procedureID: string
} & UseMutationOptions<
  Procedure,
  Error,
  { instructions: string; step: Procedure['steps'][number] }
>) {
  const { invalidateProcedure } = useInvalidateProcedure()

  return useMutation(
    ({ instructions, step }) => {
      return editProcedureStep({
        data: { instructions },
        procedureID,
        stepID: step.stepID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidateProcedure(procedureID, { newProcedure: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useInvalidateProcedure() {
  const queryClient = useQueryClient()

  return {
    invalidateProcedure: (
      procedureID: string,
      { newProcedure }: { newProcedure?: Procedure } = {}
    ) => {
      if (newProcedure) {
        queryClient.setQueryData<Procedure | undefined>(
          ['procedures', procedureID],
          () => {
            return newProcedure
          }
        )
      }

      queryClient.invalidateQueries(['procedures', procedureID])
    },
  }
}

export function useProcedure({
  procedureID,
  ...rest
}: {
  procedureID: string | undefined
} & Omit<UseQueryOptions<Procedure, Error>, 'enabled'>) {
  return useQuery<Procedure, Error>(
    ['procedures', procedureID],
    () => {
      if (!procedureID) {
        throw new Error('No ID was provided for loading procedure.')
      }

      return getProcedure({ procedureID })
    },
    { ...rest, enabled: !!procedureID }
  )
}
