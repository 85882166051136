import { useMutation, UseMutationOptions } from 'react-query'

import {
  EditFacilitySKU,
  editFacilitySKU,
} from 'services/purchasedGoods/facilitySKUs'
import { FacilityNetworkID } from 'types/domainModels/shared'
import { FacilitySKU } from 'types/domainModels/purchasedGoods'

import { useInvalidatePurchasedGood } from './purchasedGoods'

export function useEditFacilitySKU({
  facilityNetworkID,
  purchasedGoodID,
  vendorSKUID,
  ...rest
}: {
  facilityNetworkID: FacilityNetworkID
  purchasedGoodID: string
  vendorSKUID: string
} & UseMutationOptions<FacilitySKU, Error, EditFacilitySKU['data']>) {
  const { invalidatePurchasedGood } = useInvalidatePurchasedGood()

  return useMutation(
    async (data) => {
      return editFacilitySKU({
        data,
        facilityNetworkID,
        purchasedGoodID,
        vendorSKUID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        rest.onSuccess?.(...args)

        invalidatePurchasedGood(purchasedGoodID, {
          facilityNetworkID,
          newFacilitySKU: args[0],
          vendorSKUID,
        })
      },
    }
  )
}
