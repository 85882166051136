import { Form, Formik } from 'formik'
import { useMutation } from 'react-query'

import { login as apiLogin } from 'services/recipes/auth'

import { useAuth } from 'contexts/auth'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ButtonLoading from 'components/common/ButtonLoading'
import Card, { CardBody } from 'components/common/Card'
import FormInput from 'components/common/FormInput'

interface LoginFormData {
  email: string
  password: string
}

const AuthWrapper = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isLoggedIn, onJWTChanged } = useAuth()

  const {
    error: loginError,
    isError: hasLoginError,
    isLoading: isLoggingIn,
    mutate: login,
  } = useMutation<{ token: string }, Error, LoginFormData>(
    (formData) => {
      return apiLogin({
        data: {
          email: formData.email,
          password: formData.password,
        },
      })
    },
    {
      onSuccess: (data) => {
        onJWTChanged(data.token)
      },
    }
  )

  if (isLoggedIn) {
    return children
  }

  return (
    <div className="h-screen bg-light-grey pt-10">
      <div className="mx-auto w-[600px]">
        <Formik<LoginFormData>
          initialValues={{ email: '', password: '' }}
          onSubmit={(formData) => {
            return login(formData)
          }}
        >
          <Form>
            <Card>
              <CardBody>
                <LoginForm
                  isLoading={isLoggingIn}
                  loginError={hasLoginError ? loginError : null}
                />
              </CardBody>
            </Card>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default AuthWrapper

const LoginForm = ({
  isLoading,
  loginError,
}: {
  isLoading: boolean
  loginError: Error | null
}): JSX.Element => {
  return (
    <>
      <h1 className="mb-4 text-2xl">Login</h1>

      <div className="space-y-4">
        <FormInput
          id="email"
          label="Email"
          labelFor="email"
          name="email"
          type="email"
        />
        <FormInput
          id="password"
          label="Password"
          labelFor="password"
          name="password"
          type="password"
        />
      </div>

      <div className="mt-8 space-y-4">
        {loginError && <APIErrorDisplay error={loginError} />}

        <div className="flex justify-end">
          <div className="w-20">
            <ButtonLoading isLoading={isLoading} type="submit">
              Login
            </ButtonLoading>
          </div>
        </div>
      </div>
    </>
  )
}
