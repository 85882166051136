import { every } from 'lodash-es'

import { FacilityNetworkID } from 'types/domainModels/shared'
import { Version } from 'types/domainModels/recipes'
import { VersionApprovalStatus } from 'types/internal'

import { useApprovalStatus } from 'hooks/recipes/versions'
import Badge from 'components/common/Badge'
import CheckIcon from 'components/common/icons/CheckIcon'
import Label from 'components/common/Label'
import XIcon from 'components/common/icons/XIcon'

const VersionStatusTable = ({ version }: { version: Version }): JSX.Element => {
  const { approvalStatus } = useApprovalStatus({ version })

  return (
    <div className="space-y-2" role="grid">
      <div role="rowgroup">
        <div className="flex items-center" role="row">
          <div className="w-32" role="columnheader">
            <Label>Status</Label>
          </div>
          <div className="flex w-20 justify-center" role="columnheader">
            {approvalStatus ? (
              <VersionStatusBadge
                facilityNetworkApprovalStatus={approvalStatus.chicago}
                title="Chicago"
              />
            ) : (
              'Chicago'
            )}
          </div>
          <div className="flex w-20 justify-center" role="columnheader">
            {approvalStatus ? (
              <VersionStatusBadge
                facilityNetworkApprovalStatus={approvalStatus.slc}
                title="SLC"
              />
            ) : (
              'SLC'
            )}
          </div>
        </div>
      </div>
      <div className="space-y-2" role="rowgroup">
        <VersionStatusRow
          approvalStatus={approvalStatus}
          statusKey="culinary"
          title="Culinary"
        />
        <VersionStatusRow
          approvalStatus={approvalStatus}
          statusKey="procurement"
          title="Procurement"
        />
        <VersionStatusRow
          approvalStatus={approvalStatus}
          statusKey="operations"
          title="Operations"
        />
      </div>
    </div>
  )
}

export default VersionStatusTable

const VersionStatusBadge = ({
  facilityNetworkApprovalStatus,
  title,
}: {
  facilityNetworkApprovalStatus: VersionApprovalStatus['chicago' | 'slc']
  title: string
}): JSX.Element => {
  const isApproved = every(facilityNetworkApprovalStatus)
  return (
    <div aria-label={`${title} ${isApproved ? 'approved' : 'not approved'}`}>
      <Badge badgeStyle={isApproved ? 'success' : 'danger'}>{title}</Badge>
    </div>
  )
}

const VersionStatusRow = ({
  approvalStatus,
  statusKey,
  title,
}: {
  approvalStatus: VersionApprovalStatus | null
  statusKey: keyof VersionApprovalStatus[FacilityNetworkID]
  title: string
}) => {
  const chicagoID = `${title}-chicago`
  const slcID = `${title}-slc`

  let chicagoIcon = (
    <div className="h-4 w-4 text-green">
      <CheckIcon aria-label="Approved" id={chicagoID} />
    </div>
  )
  let slcIcon = (
    <div className="h-4 w-4 text-green">
      <CheckIcon aria-label="Approved" id={slcID} />
    </div>
  )

  if (approvalStatus) {
    if (!approvalStatus.chicago[statusKey]) {
      chicagoIcon = (
        <div className="h-4 w-4 text-red">
          <XIcon aria-label="Not approved" id={chicagoID} />
        </div>
      )
    }

    if (!approvalStatus.slc[statusKey]) {
      slcIcon = (
        <div className="h-4 w-4 text-red">
          <XIcon aria-label="Not approved" id={chicagoID} />
        </div>
      )
    }
  }

  return (
    <div className="flex" role="row">
      <div className="w-32 pl-2" role="cell">
        <Label>{title}</Label>
      </div>
      <div
        aria-labelledby={chicagoID}
        className="flex w-20 justify-center"
        role="cell"
      >
        {approvalStatus ? chicagoIcon : '---'}
      </div>
      <div
        aria-labelledby={slcID}
        className="flex w-20 justify-center"
        role="cell"
      >
        {approvalStatus ? slcIcon : '---'}
      </div>
    </div>
  )
}
