import { clsx } from 'clsx'
import { MutableRefObject } from 'react'
import ReactSelect, {
  ClearIndicatorProps,
  components,
  ContainerProps,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  LoadingIndicatorProps,
  MenuProps,
  NoticeProps,
  Props as ReactSelectProps,
  SelectInstance,
  StylesConfig,
} from 'react-select'
import ReactSelectCreatable, { CreatableProps } from 'react-select/creatable'

import ChevronDownIcon from 'components/common/icons/ChevronDownIcon'
import CircleLoader from './CircleLoader'
import InfiniteScrollTrigger from './InfiniteScrollTrigger'
import XIcon from 'components/common/icons/XIcon'

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean, // eslint-disable-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option> // eslint-disable-line @typescript-eslint/no-unused-vars
  > {
    loadError?: string
    onInfiniteLoadTriggered?: () => void
  }
}

function generateSelectStyles<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  hasError,
  selectHeight,
}: {
  hasError: boolean
  selectHeight: string
}): StylesConfig<Option, IsMulti, Group> {
  return {
    container: (provided) => {
      return {
        ...provided,
        borderRadius: '0.25rem',

        backgroundColor: '#fff',
      }
    },
    control: (_provided, state) => {
      let outlineStyle: CSSObjectWithLabel = {}
      if (state.isFocused) {
        outlineStyle = {
          outlineColor: '-webkit-focus-ring-color',
          outlineStyle: 'auto',
          outlineWidth: '1px',
        }
      }

      return {
        display: 'flex',
        position: 'relative',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',

        height: selectHeight,
        border: hasError ? '1px solid #c30303' : '1px solid #e4e4e4',
        borderRadius: '0.25rem',

        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        ...outlineStyle,
      }
    },
    menu: (provided) => {
      return {
        ...provided,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',

        zIndex: 20,
      }
    },
    multiValue: (provided) => {
      return {
        ...provided,
        margin: '0 2px 2px 0',
      }
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        height: '100%',
        padding: '0 0.5rem',
      }
    },
  }
}

export type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<ReactSelectProps<Option, IsMulti, Group>, 'name'> & {
  createMessage?: string
  hasError?: boolean
  isSaving?: boolean
  name: string
  onClickCreate?: ((inputValue: string) => void) | null
  selectHeight?: '2rem' | '3rem'
  selectRef?: MutableRefObject<SelectRef<Option, IsMulti, Group> | null> | null
  showCreateOption?: () => boolean
}

export type SelectRef<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = SelectInstance<Option, IsMulti, Group>

function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  createMessage = '',
  hasError = false,
  isSaving = false,
  loadError = undefined,
  onClickCreate = null,
  selectHeight = '2rem',
  selectRef = null,
  showCreateOption = () => true,
  ...rest
}: SelectProps<Option, IsMulti, Group>): JSX.Element {
  const commonProps: SelectProps<Option, IsMulti, Group> = {
    ...rest,
    components: {
      ClearIndicator: SelectClearIndicator,
      DropdownIndicator: SelectDropdownIndicator,
      IndicatorSeparator: null,
      // We only show the loading indicator during a save because displaying it during loading is annoying
      // if there are lots of selects on the page (and therefore lots of loading indicators).
      LoadingIndicator: isSaving ? SelectLoadingIndicator : undefined,
      LoadingMessage: SelectLoadingMessage,
      Menu: SelectMenu,
      NoOptionsMessage: SelectNoOptionsMessage,
      SelectContainer,
    },
    loadError,
    styles: generateSelectStyles({ hasError, selectHeight }),
    tabSelectsValue: false,
  }

  let SelectComponent = ReactSelect

  let createProps: CreatableProps<Option, IsMulti, Group> = {}

  if (onClickCreate) {
    SelectComponent = ReactSelectCreatable

    createProps = {
      formatCreateLabel: () => {
        return (
          <a className="text-xs uppercase text-blue">
            {createMessage || '+ Create'}
          </a>
        )
      },
      isValidNewOption: showCreateOption,
      onCreateOption: onClickCreate,
    }
  }

  return (
    <SelectComponent
      {...commonProps}
      {...createProps}
      ref={selectRef}
      isLoading={commonProps.isLoading || isSaving}
      placeholder=""
    />
  )
}

export default Select

function SelectClearIndicator<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  clearValue,
  innerProps: { ref, ...restInnerProps },
}: ClearIndicatorProps<Option, IsMulti, Group>): JSX.Element {
  return (
    <div ref={ref} className="pl-2" {...restInnerProps}>
      <div
        className="h-3 w-3 cursor-pointer text-grey"
        onClick={() => {
          clearValue()
        }}
      >
        <XIcon />
      </div>
    </div>
  )
}

function SelectContainer<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  selectProps,
  ...rest
}: ContainerProps<Option, IsMulti, Group>): JSX.Element {
  return (
    <div data-testid={`select-${selectProps.name}`}>
      <components.SelectContainer selectProps={selectProps} {...rest} />
    </div>
  )
}

function SelectDropdownIndicator<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ isDisabled }: DropdownIndicatorProps<Option, IsMulti, Group>): JSX.Element {
  return (
    <div className="px-2">
      <div
        className={clsx('h-3 w-3 text-grey', {
          'text-dark-grey': isDisabled,
        })}
      >
        <ChevronDownIcon />
      </div>
    </div>
  )
}

function SelectLoadingIndicator<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ innerProps }: LoadingIndicatorProps<Option, IsMulti, Group>) {
  return (
    <div {...innerProps}>
      <CircleLoader isColored={true} />
    </div>
  )
}

function SelectLoadingMessage<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ innerProps }: NoticeProps<Option, IsMulti, Group>) {
  return (
    <div
      {...innerProps}
      className="space-y-2 p-2"
      data-testid="select-loading-block"
    >
      <div className="h-4 w-3/4  animate-pulse bg-light-grey" />
      <div className="h-4 w-1/2  animate-pulse bg-light-grey" />
    </div>
  )
}

function SelectMenu<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ selectProps, ...rest }: MenuProps<Option, IsMulti, Group>): JSX.Element {
  return (
    <div data-testid={`select-${selectProps.name}-menu`}>
      <components.Menu selectProps={selectProps} {...rest} />

      {selectProps.onInfiniteLoadTriggered ? (
        <InfiniteScrollTrigger
          onTriggered={selectProps.onInfiniteLoadTriggered}
        />
      ) : null}
    </div>
  )
}

function SelectNoOptionsMessage<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ children, innerProps, selectProps }: NoticeProps<Option, IsMulti, Group>) {
  const { loadError } = selectProps

  if (loadError) {
    return (
      <p {...innerProps} className="p-2 text-sm text-red">
        {loadError}
      </p>
    )
  }

  return (
    <p {...innerProps} className="p-2 text-sm text-grey">
      {children}
    </p>
  )
}
