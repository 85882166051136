import { DeepPartial } from 'types/internal'
import { get, patch, post } from './baseAPI'
import { Vendor } from 'types/domainModels/purchasedGoods'

export interface CreateVendor {
  data: {
    contact: {
      address: {
        city: string
        line1: string
        line2: string
        state: string
        zip: string
      }
      email: string
      name: string
    }
    name: string
  }
}

export interface EditVendorContact {
  data: DeepPartial<CreateVendor['data']['contact']>
  vendorID: string
}

export interface GetVendor {
  vendorID: string
}

export interface GetVendors {
  cursor?: string
  limit: number
}

export interface GetVendorsResponse {
  nextCursor?: string | undefined
  vendors: Vendor[]
}

export const ENDPOINTS = {
  CREATE_VENDOR: () => ({
    path: '/vendors',
    version: 'v1' as const,
  }),
  EDIT_VENDOR_CONTACT: ({ vendorID }: Pick<EditVendorContact, 'vendorID'>) => ({
    path: `/vendors/${vendorID}/contact`,
    version: 'v1' as const,
  }),
  GET_VENDOR: ({ vendorID }: Pick<GetVendor, 'vendorID'>) => ({
    path: `/vendors/${vendorID}`,
    version: 'v1' as const,
  }),
  GET_VENDORS: () => ({
    path: '/vendors',
    version: 'v1' as const,
  }),
}

export function createVendor({ data }: CreateVendor) {
  return post<Vendor>({
    data,
    ...ENDPOINTS.CREATE_VENDOR(),
  })
}

export function editVendorContact({ data, vendorID }: EditVendorContact) {
  return patch<Vendor>({
    data,
    ...ENDPOINTS.EDIT_VENDOR_CONTACT({ vendorID }),
  })
}

export function getVendor(opts: GetVendor) {
  return get<Vendor>(ENDPOINTS.GET_VENDOR(opts))
}

export function getVendors({ cursor, limit }: GetVendors) {
  return get<GetVendorsResponse>({
    config: { params: { cursor, limit } },
    ...ENDPOINTS.GET_VENDORS(),
  })
}
