import { clsx } from 'clsx'
import {
  Link,
  Navigate,
  NavLink,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import { ReactNode, useEffect } from 'react'

import { analytics } from 'utils/analytics'

import { FiltersProvider } from 'contexts/filters'
import { useAuth } from 'contexts/auth'
import AuthWrapper from 'AuthWrapper'
import ListPartsPage from 'components/parts/ListPartsPage'
import ListPurchasedGoodsPage, {
  LIST_PURCHASED_GOODS_FILTER_KEYS,
} from 'components/purchasedGoods/ListPurchasedGoodsPage'
import ListVendorsPage from 'components/vendors/ListVendorsPage'
import PartPage from 'components/parts/PartPage'
import PartsPageLayout from 'components/parts/PartsPageLayout'
import PurchasedGoodPage from 'components/purchasedGoods/PurchasedGoodPage'
import PurchasedGoodsPageLayout from 'components/purchasedGoods/PurchasedGoodsPageLayout'
import VendorPage from 'components/vendors/VendorPage'
import VendorSKUPage from 'components/purchasedGoods/VendorSKUPage'
import VendorsPageLayout from 'components/vendors/VendorsPageLayout'

const App = () => {
  const location = useLocation()

  useEffect(() => {
    analytics.page()
  }, [location.pathname])

  return (
    <div>
      <div className="fixed inset-0 z-20 h-header">
        <Header />
      </div>
      <main className="mt-header">
        <AuthWrapper>
          <Routes>
            {/* Parts */}
            <Route element={<PartsPageLayout />} path="parts">
              <Route element={<PartPage />} path=":partID/*" />
              <Route element={<ListPartsPage />} index />
            </Route>

            {/* Purchased Goods */}
            <Route
              element={<PurchasedGoodsPageLayout />}
              path="purchased-goods"
            >
              <Route
                element={
                  <FiltersProvider
                    filterKeys={LIST_PURCHASED_GOODS_FILTER_KEYS}
                    filterPathname="/purchased-goods"
                  />
                }
              >
                <Route element={<PurchasedGoodPage />} path=":id" />
                <Route
                  element={<VendorSKUPage />}
                  path=":purchasedGoodID/vendor-skus/:vendorSKUID"
                />
                <Route element={<ListPurchasedGoodsPage />} index />
              </Route>
            </Route>

            {/* Vendors */}
            <Route element={<VendorsPageLayout />} path="vendors">
              <Route element={<VendorPage />} path=":id" />
              <Route element={<ListVendorsPage />} index />
            </Route>

            <Route element={<Navigate replace to="/parts" />} path="*" />
          </Routes>
        </AuthWrapper>
      </main>
    </div>
  )
}

export default App

const Header = (): JSX.Element => {
  const { isLoggedIn, onJWTChanged } = useAuth()

  return (
    <header className="h-full border-b border-light-grey bg-white">
      <div className="mx-auto flex h-full w-full max-w-content items-center justify-between px-4">
        <div className="flex h-full items-center space-x-20">
          <Link className="text-xl" to="/">
            Culinary Tools
          </Link>
          {isLoggedIn && <HeaderNav />}
        </div>
        {isLoggedIn && (
          <a
            className="cursor-pointer text-xs uppercase text-orange"
            onClick={() => {
              onJWTChanged(null)
            }}
          >
            Logout
          </a>
        )}
      </div>
    </header>
  )
}

const HeaderNav = (): JSX.Element => {
  return (
    <nav className="h-full">
      <ul className="flex h-full space-x-8">
        <HeaderNavLink path="/parts">Parts</HeaderNavLink>
      </ul>
    </nav>
  )
}

const HeaderNavLink = ({
  children,
  path,
}: {
  children: ReactNode
  path: string
}): JSX.Element => {
  return (
    <li className="h-full">
      <NavLink
        className={({ isActive }) => {
          return clsx('relative flex h-full items-center', {
            'before:absolute before:bottom-0 before:left-0 before:right-0 before:block before:h-[2px] before:bg-orange':
              isActive,
          })
        }}
        to={path}
      >
        {children}
      </NavLink>
    </li>
  )
}
