import { ReactNode } from 'react'

import Label from './Label'

const LabeledField = ({
  children,
  label,
}: {
  children: ReactNode
  label: string
}): JSX.Element => {
  return (
    <div className="text-sm">
      <Label>{label}</Label>
      <div>{children}</div>
    </div>
  )
}

export default LabeledField
