import { get } from './baseAPI'

export interface GetCategoriesResponse {
  categories: string[]
}

export interface GetStorageLocationsResponse {
  storageLocations: string[]
}

export interface GetTCSZonesResponse {
  warehouseZones: string[]
}

export const ENDPOINTS = {
  GET_CATEGORIES: () => ({
    path: '/purchasedGoods/tools/categories',
    version: 'v1' as const,
  }),
  GET_STORAGE_LOCATIONS: () => ({
    path: '/purchasedGoods/tools/storageLocations',
    version: 'v1' as const,
  }),
  GET_TCS_ZONES: () => ({
    path: '/purchasedGoods/tools/warehouseZones',
    version: 'v1' as const,
  }),
}

export async function getCategories() {
  const getCategoriesResult = await get<GetCategoriesResponse>(
    ENDPOINTS.GET_CATEGORIES()
  )

  return getCategoriesResult.categories
}

export async function getStorageLocations() {
  const getStorageLocationsResult = await get<GetStorageLocationsResponse>(
    ENDPOINTS.GET_STORAGE_LOCATIONS()
  )

  return getStorageLocationsResult.storageLocations
}

export async function getTCSZones() {
  const getTCSZonesResult = await get<GetTCSZonesResponse>(
    ENDPOINTS.GET_TCS_ZONES()
  )

  return getTCSZonesResult.warehouseZones
}
