import { apiDelete, get, patch, post, put } from './baseAPI'
import { Draft, DraftStatus } from 'types/domainModels/recipes'

export interface CopyDraft {
  data: { draftID: string }
  recipeID: string
}

export interface CreateDraft {
  recipeID: string
}

export interface CreateLineItem {
  data: { quantityGrams: number }
  draftID: string
  ingredientID: string
  recipeID: string
}

export interface DeleteLineItem {
  draftID: string
  ingredientID: string
  recipeID: string
}

export interface EditDraft {
  data: Partial<Pick<Draft, EditableDraftField>>
  draftID: string
  recipeID: string
}

export interface EditDraftStatus {
  data: { status: DraftStatus; statusNotes: string }
  draftID: string
  recipeID: string
}

export interface EditLineItem {
  data: { quantityGrams: number }
  draftID: string
  ingredientID: string
  recipeID: string
}

export type EditableDraftField =
  | 'authorName'
  | 'authorTasteNotes'
  | 'methodNotes'
  | 'postCookWeightGrams'
  | 'targetPortion'

export interface GetDraft {
  draftID: string
  recipeID: string
}

export const ENDPOINTS = {
  COPY_DRAFT: ({ recipeID }: Omit<CopyDraft, 'data'>) => ({
    path: `/recipes/${recipeID}/drafts/copy`,
    version: 'recipes/v1' as const,
  }),
  CREATE_DRAFT: ({ recipeID }: CreateDraft) => ({
    path: `/recipes/${recipeID}/drafts`,
    version: 'recipes/v1' as const,
  }),
  CREATE_LINE_ITEM: ({
    draftID,
    ingredientID,
    recipeID,
  }: Omit<CreateLineItem, 'data'>) => ({
    path: `/recipes/${recipeID}/drafts/${draftID}/lineItems/${ingredientID}`,
    version: 'recipes/v1' as const,
  }),
  DELETE_LINE_ITEM: ({ draftID, ingredientID, recipeID }: DeleteLineItem) => ({
    path: `/recipes/${recipeID}/drafts/${draftID}/lineItems/${ingredientID}`,
    version: 'recipes/v1' as const,
  }),
  EDIT_DRAFT: ({ draftID, recipeID }: Omit<EditDraft, 'data'>) => ({
    path: `/recipes/${recipeID}/drafts/${draftID}`,
    version: 'recipes/v1' as const,
  }),
  EDIT_DRAFT_STATUS: ({
    draftID,
    recipeID,
  }: Omit<EditDraftStatus, 'data'>) => ({
    path: `/recipes/${recipeID}/drafts/${draftID}/status`,
    version: 'recipes/v1' as const,
  }),
  EDIT_LINE_ITEM: ({
    draftID,
    ingredientID,
    recipeID,
  }: Omit<EditLineItem, 'data'>) => ({
    path: `/recipes/${recipeID}/drafts/${draftID}/lineItems/${ingredientID}`,
    version: 'recipes/v1' as const,
  }),
  GET_DRAFT: ({ draftID, recipeID }: GetDraft) => ({
    path: `/recipes/${recipeID}/drafts/${draftID}`,
    version: 'recipes/v1' as const,
  }),
}

export function copyDraft({ data, recipeID }: CopyDraft) {
  return post<Draft>({
    ...ENDPOINTS.COPY_DRAFT({ recipeID }),
    data,
  })
}

export function createDraft({ recipeID }: CreateDraft) {
  return post<Draft>({
    ...ENDPOINTS.CREATE_DRAFT({ recipeID }),
    data: {},
  })
}

export function createLineItem({
  data,
  draftID,
  ingredientID,
  recipeID,
}: CreateLineItem) {
  return post<Draft>({
    ...ENDPOINTS.CREATE_LINE_ITEM({ draftID, ingredientID, recipeID }),
    data,
  })
}

export function deleteLineItem(opts: DeleteLineItem) {
  return apiDelete<Draft>(ENDPOINTS.DELETE_LINE_ITEM(opts))
}

export function editDraft({ data, draftID, recipeID }: EditDraft) {
  return patch<Draft>({
    ...ENDPOINTS.EDIT_DRAFT({ draftID, recipeID }),
    data,
  })
}

export function editDraftStatus({ data, draftID, recipeID }: EditDraftStatus) {
  return put<Draft>({
    ...ENDPOINTS.EDIT_DRAFT_STATUS({ draftID, recipeID }),
    data,
  })
}

export function editLineItem({
  data,
  draftID,
  ingredientID,
  recipeID,
}: EditLineItem) {
  return patch<Draft>({
    ...ENDPOINTS.EDIT_LINE_ITEM({ draftID, ingredientID, recipeID }),
    data,
  })
}

export function getDraft(opts: GetDraft) {
  return get<Draft>(ENDPOINTS.GET_DRAFT(opts))
}
