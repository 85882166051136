import { flatMap } from 'lodash-es'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { Vendor } from 'types/domainModels/purchasedGoods'

import { useVendors } from 'hooks/purchasedGoods/vendors'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Button from 'components/common/Button'
import CreateVendorModal from './CreateVendorModal'
import InfiniteScrollTrigger from 'components/common/InfiniteScrollTrigger'
import TableHeader from 'components/common/TableHeader'

const ListVendorsPage = (): JSX.Element => {
  const {
    data: getVendorsResult,
    error: loadVendorsError,
    fetchNextPage,
    hasNextPage,
    isError: hasLoadVendorsError,
    isFetchingNextPage,
    isLoading: isLoadingVendors,
  } = useVendors({ limit: 50 })

  const vendors = flatMap(getVendorsResult?.pages, (page) => {
    return page.vendors
  })

  return (
    <>
      <div className="mb-4 flex justify-between">
        <h1 className="text-2xl" id="vendors-header">
          Vendors
        </h1>

        <div className="w-36">
          <CreateVendor />
        </div>
      </div>

      <VendorsTable
        hasLoadVendorsError={hasLoadVendorsError}
        hasNextPage={!!hasNextPage}
        isLoadingNextPage={isFetchingNextPage}
        isLoadingVendors={isLoadingVendors}
        loadVendorsError={loadVendorsError}
        onLoadMoreVendors={fetchNextPage}
        vendors={vendors}
      />
    </>
  )
}

export default ListVendorsPage

const CreateVendor = (): JSX.Element => {
  const navigate = useNavigate()

  const [isCreateVendorModalOpen, setIsCreateVendorModalOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => {
          setIsCreateVendorModalOpen(true)
        }}
      >
        + New Vendor
      </Button>

      {isCreateVendorModalOpen && (
        <CreateVendorModal
          onCloseModal={() => {
            setIsCreateVendorModalOpen(false)
          }}
          onCreateVendor={(vendor) => {
            navigate(vendor.id)
          }}
        />
      )}
    </>
  )
}

const VendorsTable = ({
  hasLoadVendorsError,
  hasNextPage,
  isLoadingNextPage,
  isLoadingVendors,
  loadVendorsError,
  onLoadMoreVendors,
  vendors,
}: {
  hasLoadVendorsError: boolean
  hasNextPage: boolean
  isLoadingNextPage: boolean
  isLoadingVendors: boolean
  loadVendorsError: Error | null
  onLoadMoreVendors(): void
  vendors: Vendor[]
}): JSX.Element => {
  const vendorsGridClasses = 'grid grid-cols-1 gap-4 items-center'
  const vendorsTableRowClasses = `${vendorsGridClasses} py-5 sm:text-sm border-b border-light-grey`

  return (
    <div aria-describedby="vendors-header" aria-label="Vendors" role="grid">
      <div className="sticky top-[45px] bg-white">
        <TableHeader>
          <div className={`${vendorsGridClasses} pt-4`} role="row">
            <span role="columnheader">Name</span>
          </div>
        </TableHeader>
      </div>
      <div role="rowgroup">
        {vendors.length === 0 && (
          <>
            {isLoadingVendors ? (
              <VendorsTableLoadingRows
                vendorsTableRowClasses={vendorsTableRowClasses}
              />
            ) : (
              <>
                {hasLoadVendorsError ? (
                  <div className="mt-2">
                    <APIErrorDisplay error={loadVendorsError} />
                  </div>
                ) : (
                  <p className="py-2 text-sm">No vendors</p>
                )}
              </>
            )}
          </>
        )}

        {vendors.map(({ id, name }) => {
          return (
            <div key={id} className={vendorsTableRowClasses} role="row">
              <div role="cell">
                <Link className="text-blue" to={`/vendors/${id}`}>
                  {name}
                </Link>
              </div>
            </div>
          )
        })}

        {isLoadingNextPage ? (
          <VendorsTableLoadingRows
            vendorsTableRowClasses={vendorsTableRowClasses}
          />
        ) : hasNextPage ? (
          <InfiniteScrollTrigger onTriggered={onLoadMoreVendors} />
        ) : null}
      </div>
    </div>
  )
}

const VendorsTableLoadingRows = ({
  vendorsTableRowClasses,
}: {
  vendorsTableRowClasses: string
}): JSX.Element => {
  return (
    <>
      {new Array(5).map((_unused, i) => {
        return (
          <div key={i} className={vendorsTableRowClasses}>
            <div className="h-4 w-1/2 animate-pulse justify-self-start bg-light-grey" />
          </div>
        )
      })}
    </>
  )
}
