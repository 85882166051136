import { useQuery, useQueryClient } from 'react-query'

import {
  getIngredients,
  GetIngredientsResponse,
  searchIngredients,
  SearchIngredientsResponse,
} from 'services/recipes/ingredients'

export function useIngredients({ limit }: { limit: number }) {
  return useQuery<GetIngredientsResponse, Error>(['ingredients'], () => {
    return getIngredients({ limit })
  })
}

export function useInvalidateIngredients() {
  const queryClient = useQueryClient()

  return {
    invalidateIngredients: () => {
      queryClient.invalidateQueries(['ingredients'])
      queryClient.invalidateQueries(['ingredients-search'])
    },
  }
}

export function useSearchIngredients({
  limit,
  nameLike,
}: {
  limit: number
  nameLike: string
}) {
  return useQuery<SearchIngredientsResponse, Error>(
    ['ingredients-search', limit, nameLike],
    () => {
      return searchIngredients({
        limit,
        nameLike,
      })
    },
    {
      enabled: !!nameLike,
    }
  )
}
