import { every, find } from 'lodash-es'

import { ActiveVersions, Version } from 'types/domainModels/recipes'
import { FacilityNetworkID } from 'types/domainModels/shared'
import { ReactSelectValue } from 'types/internal'

import {
  useApprovalStatus,
  useGetActiveVersions,
  useSetActiveVersions,
} from 'hooks/recipes/versions'
import Badge from 'components/common/Badge'
import ErrorDisplay from 'components/common/ErrorDisplay'
import InlineEditSelect from 'components/common/InlineEditSelect'
import LabeledField from './LabeledField'
import Tooltip from 'components/common/Tooltip'
import VersionStatusTable from './VersionStatusTable'

const PartActiveVersions = ({
  partID,
  versions,
}: {
  partID: string
  versions: Version[]
}): JSX.Element => {
  const facilityNetworkIDs: FacilityNetworkID[] = ['chicago', 'slc']

  const {
    data: activeVersions,
    isLoading,
    error,
    isError,
  } = useGetActiveVersions({ partID })

  if (isLoading) {
    return <ActiveVersionsLoading />
  }

  if (!activeVersions || isError) {
    return (
      <ErrorDisplay
        message={`Could not load active versions.${
          error && ` Error: ${error.message}`
        }`}
      />
    )
  }

  return (
    <div className="space-y-2">
      {facilityNetworkIDs.map((facilityNetworkID) => {
        return (
          <ActiveVersion
            key={facilityNetworkID}
            activeVersions={activeVersions}
            facilityNetworkID={facilityNetworkID}
            partID={partID}
            versions={versions}
          />
        )
      })}
    </div>
  )
}

export default PartActiveVersions

const ActiveVersionsLoading = (): JSX.Element => {
  return (
    <div className="space-y-2">
      <div className="grid grid-cols-2 space-x-2">
        <div className="h-4 animate-pulse bg-light-grey" />
        <div className="h-4 animate-pulse bg-light-grey" />
      </div>
      <div className="grid grid-cols-2 space-x-2">
        <div className="h-4 animate-pulse bg-light-grey" />
        <div className="h-4 animate-pulse bg-light-grey" />
      </div>
    </div>
  )
}

const ActiveVersion = ({
  activeVersions,
  facilityNetworkID,
  partID,
  versions,
}: {
  activeVersions: ActiveVersions
  facilityNetworkID: FacilityNetworkID
  partID: string
  versions: Version[]
}): JSX.Element => {
  const activeVersionNumber =
    activeVersions.facilityNetworkVersions[facilityNetworkID]
  const activeVersion = find(versions, { versionNumber: activeVersionNumber })

  const { mutateAsync: setActiveVersions } = useSetActiveVersions({ partID })

  const versionOptions: ReactSelectValue<number>[] = versions.map(
    (version) => ({
      label: version.versionNumber.toString(),
      value: version.versionNumber,
    })
  )

  return (
    <LabeledField title={`${facilityNetworkID} Version`}>
      <InlineEditSelect
        initialValue={
          versionOptions.find((option) => {
            return option.value === activeVersionNumber
          }) ?? null
        }
        isEditable={versionOptions.length > 0}
        name={`${facilityNetworkID}-active-version}`}
        onChange={(version) => {
          if (version) {
            return setActiveVersions({
              facilityNetworkID,
              versionNumber: version.value,
            })
          }

          throw new Error('Cannot assign an empty version number.')
        }}
        options={versionOptions}
      >
        <div className="flex items-center space-x-4">
          <span>{activeVersionNumber ?? 'N/A'}</span>
          {activeVersion && (
            <CheckVersionValidity
              facilityNetworkID={facilityNetworkID}
              version={activeVersion}
            />
          )}
        </div>
      </InlineEditSelect>
    </LabeledField>
  )
}

const CheckVersionValidity = ({
  facilityNetworkID,
  version,
}: {
  facilityNetworkID: FacilityNetworkID
  version: Version
}): JSX.Element | null => {
  const { approvalStatus } = useApprovalStatus({ version })

  if (!approvalStatus) {
    return null
  }

  return every(approvalStatus[facilityNetworkID]) ? (
    <div className="hidden">valid</div>
  ) : (
    <Tooltip
      trigger={
        <div>
          <Badge badgeStyle="danger">invalid</Badge>
        </div>
      }
    >
      <div className="p-4">
        <VersionStatusTable version={version} />
      </div>
    </Tooltip>
  )
}
