import { useMutation, UseMutationOptions } from 'react-query'

import {
  CreateVendorSKU,
  createVendorSKU,
  EditVendorSKU,
  editVendorSKU,
} from 'services/purchasedGoods/vendorSKUs'
import { VendorSKU } from 'types/domainModels/purchasedGoods'

import { useInvalidatePurchasedGood } from './purchasedGoods'

export function useCreateVendorSKU({
  purchasedGoodID,
  ...rest
}: { purchasedGoodID: string } & UseMutationOptions<
  VendorSKU,
  Error,
  CreateVendorSKU['data']
>) {
  const { invalidatePurchasedGood } = useInvalidatePurchasedGood()

  return useMutation(
    async (data) => {
      return createVendorSKU({ data, purchasedGoodID })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        rest.onSuccess?.(...args)

        invalidatePurchasedGood(purchasedGoodID, { newVendorSKU: args[0] })
      },
    }
  )
}

export function useEditVendorSKU({
  purchasedGoodID,
  vendorSKUID,
  ...rest
}: { purchasedGoodID: string; vendorSKUID: string } & UseMutationOptions<
  VendorSKU,
  Error,
  EditVendorSKU['data']
>) {
  const { invalidatePurchasedGood } = useInvalidatePurchasedGood()

  return useMutation(
    async (data) => {
      return editVendorSKU({ data, purchasedGoodID, vendorSKUID })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        rest.onSuccess?.(...args)

        invalidatePurchasedGood(purchasedGoodID, { newVendorSKU: args[0] })
      },
    }
  )
}
