import { clsx } from 'clsx'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'

import {
  FacilitySKU,
  PurchasedGood,
  VendorSKU,
} from 'types/domainModels/purchasedGoods'
import { formatDollars } from 'utils/currency'
import { getCategoryOptions } from 'utils/categories'
import { getStorageLocationOptions } from 'utils/storageLocations'
import { getTCSZoneOptions } from 'utils/tcsZones'

import {
  useCategories,
  useStorageLocations,
  useTCSZones,
} from 'hooks/purchasedGoods/tools'
import {
  useEditPurchasedGood,
  usePurchasedGood,
} from 'hooks/purchasedGoods/purchasedGoods'
import { useVendor } from 'hooks/purchasedGoods/vendors'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Breadcrumbs from 'components/common/Breadcrumbs'
import Button from 'components/common/Button'
import CheckIcon from 'components/common/icons/CheckIcon'
import CheckOrEmpty from './CheckOrEmpty'
import CreateVendorSKUModal from './CreateVendorSKUModal'
import InlineEditCheckbox from 'components/common/InlineEditCheckbox'
import InlineEditInput from 'components/common/InlineEditInput'
import InlineEditSelect from 'components/common/InlineEditSelect'
import LabeledField from 'components/common/LabeledField'
import TableHeader from 'components/common/TableHeader'
import XIcon from 'components/common/icons/XIcon'

const PurchasedGoodPage = (): JSX.Element => {
  const { id: purchasedGoodID } = useParams<{ id: string }>()

  const {
    data: purchasedGood,
    error: loadPurchasedGoodError,
    isError: hasLoadPurchasedGoodError,
    isLoading: isLoadingPurchasedGood,
  } = usePurchasedGood({ purchasedGoodID })

  if (isLoadingPurchasedGood) {
    return <PurchasedGoodLoadingSkeleton />
  }

  if (hasLoadPurchasedGoodError || !purchasedGood) {
    return (
      <div className="space-y-4">
        <APIErrorDisplay
          error={
            hasLoadPurchasedGoodError
              ? loadPurchasedGoodError
              : new Error('Unknown error loading purchased good')
          }
        />
        <p>
          <Link className="text-sm text-blue" to="/purchased-goods">
            Back to purchased goods
          </Link>
        </p>
      </div>
    )
  }

  return <PurchasedGoodLoaded purchasedGood={purchasedGood} />
}

export default PurchasedGoodPage

const PurchasedGoodLoadingSkeleton = (): JSX.Element => {
  return (
    <>
      {/* Represents the title and breadcrumbs. */}
      <div className="space-y-2">
        <div className="flex items-center space-x-4">
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
        </div>
        <div className="h-8 w-1/4 animate-pulse bg-light-grey" />
      </div>
      {/* Represents the top-level labeled fields. */}
      <div className="mt-8 flex space-x-8">
        {new Array(6).map((_unused, i) => {
          return (
            <div key={i} className="space-y-2">
              <div className="h-4 w-14 animate-pulse bg-light-grey" />
              <div className="h-4 w-28 animate-pulse bg-light-grey" />
            </div>
          )
        })}
      </div>
      {/* Represents the vendor SKUs table. */}
      <div className="mt-8 space-y-4">
        <div className="h-6 w-32 animate-pulse bg-light-grey" />

        <div className="space-y-8">
          {new Array(6).map((_unused, i) => {
            return (
              <div key={i} className="flex space-x-8">
                {new Array(8).map((_unused, i) => {
                  return (
                    <div
                      key={i}
                      className="h-4 w-28 animate-pulse bg-light-grey"
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

const PurchasedGoodLoaded = ({
  purchasedGood,
}: {
  purchasedGood: PurchasedGood
}): JSX.Element => {
  return (
    <div>
      <div>
        <Breadcrumbs
          breadcrumbs={[
            { link: '..', text: 'Purchased Goods' },
            {
              link: `../${purchasedGood.id}`,
              text: purchasedGood.ingredientName,
            },
          ]}
        />
        <h1 className="text-2xl">{purchasedGood.ingredientName}</h1>
      </div>
      <div className="mt-4">
        <PurchasedGoodTopLevelFields purchasedGood={purchasedGood} />
      </div>
      <div className="mt-4">
        <div className="flex items-center justify-between">
          <h2 className="text-xl" id="vendor-skus-header">
            Vendor SKUs
          </h2>
          <div className="w-48">
            <CreateVendorSKU purchasedGood={purchasedGood} />
          </div>
        </div>
        <div className="mt-4">
          <VendorSKUsTable purchasedGood={purchasedGood} />
        </div>
      </div>
    </div>
  )
}

const PurchasedGoodTopLevelFields = ({
  purchasedGood,
}: {
  purchasedGood: PurchasedGood
}): JSX.Element => {
  const { mutateAsync: editPurchasedGood } = useEditPurchasedGood({
    purchasedGoodID: purchasedGood.id,
  })

  const {
    data: categories = [],
    error: loadCategoriesError,
    isError: hasLoadCategoriesError,
    isLoading: isLoadingCategories,
  } = useCategories()

  const categoryOptions = getCategoryOptions(categories)
  const selectedCategory =
    categoryOptions.find(({ value }) => {
      return value === purchasedGood.category
    }) ?? null

  const {
    data: storageLocations = [],
    error: loadStorageLocationsError,
    isError: hasLoadStorageLocationsError,
    isLoading: isLoadingStorageLocations,
  } = useStorageLocations()

  const storageLocationOptions = getStorageLocationOptions(storageLocations)
  const selectedStorageLocation =
    storageLocationOptions.find(({ value }) => {
      return value === purchasedGood.storageLocation
    }) ?? null

  const {
    data: tcsZones = [],
    error: loadTCSZonesError,
    isError: hasLoadTCSZonesError,
    isLoading: isLoadingTCSZones,
  } = useTCSZones()

  const tcsZoneOptions = getTCSZoneOptions(tcsZones)
  const selectedTCSZone =
    tcsZoneOptions.find(({ value }) => {
      return value === purchasedGood.warehouseZone
    }) ?? null

  return (
    <div aria-label="Top level information" className="flex space-x-8">
      <LabeledField label="Base Sku">{purchasedGood.baseSKU}</LabeledField>

      <LabeledField label="name">
        <InlineEditInput
          initialValue={purchasedGood.ingredientName}
          isEditable
          name="purchased-good-name"
          onChange={(ingredientName) => {
            return editPurchasedGood({ ingredientName })
          }}
        >
          {purchasedGood.ingredientName}
        </InlineEditInput>
      </LabeledField>

      <LabeledField label="Category">
        <InlineEditSelect
          initialValue={selectedCategory}
          isEditable
          isLoading={isLoadingCategories}
          loadError={
            hasLoadCategoriesError ? loadCategoriesError.message : undefined
          }
          name="purchased-good-category"
          onChange={(category) => {
            return editPurchasedGood({ category: category?.value ?? '' })
          }}
          options={categoryOptions}
        >
          {purchasedGood.category}
        </InlineEditSelect>
      </LabeledField>

      <LabeledField label="TCS Zone">
        <InlineEditSelect
          initialValue={selectedTCSZone}
          isEditable
          isLoading={isLoadingTCSZones}
          loadError={
            hasLoadTCSZonesError ? loadTCSZonesError.message : undefined
          }
          name="purchased-good-tcs-zone"
          onChange={(tcsZone) => {
            return editPurchasedGood({ warehouseZone: tcsZone?.value ?? '' })
          }}
          options={tcsZoneOptions}
        >
          {purchasedGood.warehouseZone}
        </InlineEditSelect>
      </LabeledField>

      <LabeledField label="Storage Location">
        <InlineEditSelect
          initialValue={selectedStorageLocation}
          isEditable
          isLoading={isLoadingStorageLocations}
          loadError={
            hasLoadStorageLocationsError
              ? loadStorageLocationsError.message
              : undefined
          }
          name="purchased-good-storage-location"
          onChange={(storageLocation) => {
            return editPurchasedGood({
              storageLocation: storageLocation?.value ?? '',
            })
          }}
          options={storageLocationOptions}
        >
          {purchasedGood.storageLocation}
        </InlineEditSelect>
      </LabeledField>

      <LabeledField label="Is Active?">
        <InlineEditCheckbox
          initialValue={purchasedGood.isActive}
          isEditable
          label="Is Active?"
          name="purchased-good-is-active"
          onChange={(isActive) => {
            return editPurchasedGood({ isActive })
          }}
        >
          <div
            className={clsx('h-4 w-4', {
              'text-green': purchasedGood.isActive,
              'text-red': !purchasedGood.isActive,
            })}
          >
            {purchasedGood.isActive ? (
              <CheckIcon aria-label="active" />
            ) : (
              <XIcon aria-label="inactive" />
            )}
          </div>
        </InlineEditCheckbox>
      </LabeledField>
    </div>
  )
}

const CreateVendorSKU = ({
  purchasedGood,
}: {
  purchasedGood: PurchasedGood
}): JSX.Element => {
  const navigate = useNavigate()

  const [isCreateVendorSKUModalOpen, setIsCreateVendorSKUModalOpen] =
    useState(false)

  return (
    <>
      <Button
        onClick={() => {
          setIsCreateVendorSKUModalOpen(true)
        }}
      >
        + New Vendor SKU
      </Button>

      {isCreateVendorSKUModalOpen && (
        <CreateVendorSKUModal
          onCloseModal={() => {
            setIsCreateVendorSKUModalOpen(false)
          }}
          onCreateVendorSKU={(vendorSKU) => {
            navigate(`vendor-skus/${vendorSKU.id}`)
          }}
          purchasedGoodID={purchasedGood.id}
        />
      )}
    </>
  )
}

const VendorSKUsTable = ({
  purchasedGood,
}: {
  purchasedGood: PurchasedGood
}): JSX.Element => {
  const gridClasses =
    'grid grid-cols-vendor-skus-table justify-items-center gap-4 text-center'

  return (
    <div
      aria-describedby="vendor-skus-header"
      aria-label="Vendor SKUs"
      className="text-sm"
      role="grid"
    >
      <TableHeader>
        <div className={`${gridClasses} items-end`} role="row">
          <span className="justify-self-start" role="columnheader">
            SKU
          </span>
          <span role="columnheader">Vendor</span>
          <span role="columnheader">Case Weight (LBS)</span>
          <span role="columnheader">Pack Weight (LBS)</span>
          <span role="columnheader">
            Chicago
            <br /> Primary
          </span>
          <span role="columnheader">
            SLC
            <br /> Primary
          </span>
          <div className="w-full space-y-2">
            <div className="text-center">Facility Networks</div>
            <div className="grid grid-cols-4 justify-items-center gap-4">
              <span className="justify-self-start" role="columnheader">
                Name
              </span>
              <span role="columnheader">Cost/LB</span>
              <span role="columnheader">Cost/Case</span>
              <span role="columnheader">Available</span>
            </div>
          </div>
        </div>
      </TableHeader>

      <div role="rowgroup">
        {purchasedGood.vendorSKUs.length === 0 && (
          <p className="mt-4">No vendor SKUs</p>
        )}
        {purchasedGood.vendorSKUs.map((vendorSKU) => {
          return (
            <VendorSKUTableRow
              key={vendorSKU.id}
              gridClasses={gridClasses}
              vendorSKU={vendorSKU}
            />
          )
        })}
      </div>
    </div>
  )
}

const VendorSKUTableRow = ({
  gridClasses,
  vendorSKU,
}: {
  gridClasses: string
  vendorSKU: VendorSKU
}): JSX.Element => {
  const { data: vendor } = useVendor({
    refetchOnWindowFocus: false,
    vendorID: vendorSKU.vendorID,
  })

  const chicagoSKU = vendorSKU.facilitySKUs.chicago
  const slcSKU = vendorSKU.facilitySKUs.slc

  return (
    <div
      className={`${gridClasses} border-b border-light-grey py-2`}
      role="row"
    >
      <div className="justify-self-start" role="cell">
        <Link className="text-blue" to={`vendor-skus/${vendorSKU.id}`}>
          {vendorSKU.sku}
        </Link>
      </div>
      <span role="cell">{vendor?.name ?? ''}</span>
      <span role="cell">{vendorSKU.caseWeightLbs}</span>
      <span role="cell">{vendorSKU.packWeightLbs}</span>
      <div role="cell">
        <CheckOrEmpty
          label="Chicago primary"
          predicate={chicagoSKU.isPrimary}
        />
      </div>
      <div role="cell">
        <CheckOrEmpty label="SLC primary" predicate={slcSKU.isPrimary} />
      </div>
      <div className="w-full">
        <FacilitySKUGrid facilitySKU={chicagoSKU} title="Chicago" />
        <FacilitySKUGrid facilitySKU={slcSKU} title="SLC" />
      </div>
    </div>
  )
}

const FacilitySKUGrid = ({
  facilitySKU,
  title,
}: {
  facilitySKU: FacilitySKU
  title: string
}): JSX.Element => {
  return (
    <div className="grid grid-cols-4 items-center justify-items-center gap-4">
      <span className="justify-self-start" role="cell">
        {title}
      </span>
      <span role="cell">
        {facilitySKU.costPerPoundCents
          ? formatDollars(facilitySKU.costPerPoundCents, { valueUnit: 'cent' })
          : '---'}
      </span>
      <span role="cell">
        {facilitySKU.costPerCaseCents
          ? formatDollars(facilitySKU.costPerCaseCents, { valueUnit: 'cent' })
          : '---'}
      </span>
      <div role="cell">
        <CheckOrEmpty
          label={`${title} available`}
          predicate={facilitySKU.isAvailable}
        />
      </div>
    </div>
  )
}
