const PartPageLoading = (): JSX.Element => {
  return (
    <>
      <div className="space-y-2">
        <div className="flex items-center space-x-4">
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
        </div>
        <div className="h-8 w-1/4 animate-pulse bg-light-grey" />
      </div>
      <div className="mt-8">
        <PartBodyLoading />
      </div>
    </>
  )
}

export default PartPageLoading

export const PartBodyLoading = () => {
  return (
    <div className="flex space-x-8">
      <div className="w-2/3 space-y-6">
        <div className="mb-2 h-6 w-20 animate-pulse bg-light-grey" />
        <div className="space-y-6">
          <div className="h-4 animate-pulse bg-light-grey" />
          <div className="h-4 animate-pulse bg-light-grey" />
          <div className="h-4 animate-pulse bg-light-grey" />
          <div className="h-4 animate-pulse bg-light-grey" />
        </div>
      </div>
      <div className="w-1/3 space-y-8">
        <div className="space-y-6">
          <div className="mb-2 h-6 w-20 animate-pulse bg-light-grey" />
          <div className="space-y-2">
            <div className="h-4 animate-pulse bg-light-grey" />
            <div className="h-4 animate-pulse bg-light-grey" />
          </div>
        </div>
        <div className="space-y-6">
          <div className="mb-2 h-6 w-20 animate-pulse bg-light-grey" />
          <div className="space-y-2">
            <div className="h-4 animate-pulse bg-light-grey" />
            <div className="h-4 animate-pulse bg-light-grey" />
          </div>
        </div>
      </div>
    </div>
  )
}
