import { Procedure, Version } from 'types/domainModels/recipes'

import {
  useApproveProcedure,
  useCreateProcedureStep,
  useDeleteProcedureStep,
  useEditProcedureStep,
  useProcedure,
} from 'hooks/recipes/procedures'
import { useToast } from 'contexts/toast'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import ButtonLoading from 'components/common/ButtonLoading'
import CheckIcon from 'components/common/icons/CheckIcon'
import CircleLoader from 'components/common/CircleLoader'
import InlineEditInput from 'components/common/InlineEditInput'
import MinusCircleIcon from 'components/common/icons/MinusCircleIcon'

const VersionOperationsTab = ({
  version,
}: {
  version: Version
}): JSX.Element => {
  const procedureID = version.procedureID

  const {
    data: procedure,
    error: loadProcedureError,
    isError: hasLoadProcedureError,
    isLoading: isLoadingProcedure,
  } = useProcedure({ procedureID })

  const { mutateAsync: createProcedureStep } = useCreateProcedureStep({
    procedureID,
  })

  const procedureSteps = procedure?.steps ?? []

  return (
    <div className="text-sm">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl">Procedure</h2>
        {procedure && <ApproveProcedureButton procedure={procedure} />}
      </div>

      {hasLoadProcedureError && <APIErrorDisplay error={loadProcedureError} />}

      {isLoadingProcedure && (
        <div className="space-y-4">
          <div className="h-4 w-1/3 animate-pulse bg-light-grey" />
          <div className="h-4 w-1/3 animate-pulse bg-light-grey" />
        </div>
      )}

      {!isLoadingProcedure && !hasLoadProcedureError && procedure && (
        <div className="space-y-4">
          {procedureSteps.map((step, i) => {
            return (
              <ProcedureStep
                key={step.stepID}
                procedure={procedure}
                step={step}
                stepNumber={i + 1}
              />
            )
          })}

          {!procedure.approved && (
            // This left margin is to match the space that existing steps have for
            // the delete icon.
            <div className="ml-6 flex items-start space-x-2">
              <div>{procedureSteps.length + 1}).</div>
              <InlineEditInput
                initialValue=""
                isEditable={true}
                name="add-procedure-step"
                onChange={(instructions) => {
                  return createProcedureStep({ instructions })
                }}
                shouldResetOnSuccess={true}
              >
                <span className="pr-8 text-dark-grey">Click to add...</span>
              </InlineEditInput>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default VersionOperationsTab

const ApproveProcedureButton = ({
  procedure,
}: {
  procedure: Procedure
}): JSX.Element | null => {
  const { openToast } = useToast()

  const { isLoading: isApprovingProcedure, mutate: approveProcedure } =
    useApproveProcedure({
      onError: (err) => {
        openToast(`Error approving procedure: ${err.message}`, 'error')
      },
      procedureID: procedure.id,
    })

  if (procedure.steps.length <= 0) {
    return null
  }

  if (procedure.approved) {
    return (
      <div className="flex items-center space-x-2 text-sm text-green">
        <div className="h-4 w-4">
          <CheckIcon />
        </div>
        <span>Procedure approved</span>
      </div>
    )
  }

  return (
    <div className="w-24">
      <ButtonLoading
        isLoading={isApprovingProcedure}
        onClick={() => {
          approveProcedure()
        }}
      >
        Approve
      </ButtonLoading>
    </div>
  )
}

const ProcedureStep = ({
  procedure,
  step,
  stepNumber,
}: {
  procedure: Procedure
  step: Procedure['steps'][number]
  stepNumber: number
}): JSX.Element => {
  const { openToast } = useToast()

  const procedureID = procedure.id

  const { isLoading: isDeleting, mutate: deleteProcedureStep } =
    useDeleteProcedureStep({
      onError: (err) => {
        openToast(`Error deleting procedure step: ${err.message}`, 'error')
      },
      procedureID,
    })

  const { mutateAsync: editProcedureStep } = useEditProcedureStep({
    procedureID,
  })

  return (
    <div key={step.stepID} className="flex items-start space-x-2">
      {!procedure.approved && (
        <>
          {isDeleting ? (
            <div className="mt-0.5 h-4 w-4">
              <CircleLoader isColored />
            </div>
          ) : (
            <div
              className="mt-0.5 h-4 w-4 cursor-pointer"
              onClick={() => {
                deleteProcedureStep(step)
              }}
            >
              <MinusCircleIcon aria-label="Delete procedure step" />
            </div>
          )}
        </>
      )}

      <div>{stepNumber}).</div>
      <div className="grow">
        <InlineEditInput
          initialValue={step.instructions}
          isEditable={!procedure.approved}
          name={`${step.stepID}-instructions`}
          onChange={(instructions) => {
            return editProcedureStep({ instructions, step })
          }}
        >
          {step.instructions}
        </InlineEditInput>
      </div>
    </div>
  )
}
