import { forwardRef, ReactNode } from 'react'
import { useField } from 'formik'

import Checkbox, { Props as CheckboxProps } from './Checkbox'
import FormGroup from './FormGroup'

const FormCheckbox = forwardRef<
  HTMLInputElement,
  Omit<CheckboxProps, 'hasError'> & {
    checkboxLabel: ReactNode
    label?: ReactNode
    labelFor?: string
  }
>(function FormCheckbox({ checkboxLabel, label, labelFor, ...props }, ref) {
  const [field, meta] = useField({ name: props.name, type: 'checkbox' })
  const hasError = !!(meta.touched && meta.error)

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      labelFor={labelFor}
    >
      <Checkbox
        ref={ref}
        {...field}
        {...props}
        checked={field.checked}
        label={checkboxLabel}
      />
    </FormGroup>
  )
})

export default FormCheckbox
