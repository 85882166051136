import axios from 'axios'

import { isAxiosResponseError } from 'utils/api'

import { dispatchUnauthorizedEvent } from 'contexts/auth'
import { removeQueryParamsEmptyKeys } from 'utils/general'

axios.interceptors.request.use((config) => {
  // If we have request parameters, we want to strip out any empty ones.
  if (config.params) {
    config.params = removeQueryParamsEmptyKeys(config.params)
  }

  return config
})

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // If we ever get back an Unauthorized API response code, we should log the user out.
    if (isAxiosResponseError(error) && error.response?.status === 401) {
      dispatchUnauthorizedEvent()
    }

    return Promise.reject(error)
  }
)
