import { clsx } from 'clsx'
import { Dialog } from '@headlessui/react'
import { ReactNode } from 'react'

import APIErrorDisplay from './APIErrorDisplay'
import Button from './Button'
import ButtonLoading, { Props as ButtonLoadingProps } from './ButtonLoading'
import ErrorDisplay from './ErrorDisplay'
import XIcon from 'components/common/icons/XIcon'

const Modal = ({
  children,
  isCentered = false,
  onCloseModal,
}: {
  children: ReactNode
  isCentered?: boolean
  onCloseModal: () => void
}): JSX.Element => {
  const marginY = isCentered ? 'my-auto' : 'my-modal-y'

  return (
    <Dialog
      onClose={() => {
        onCloseModal()
      }}
      open={true}
    >
      <div
        className="fixed inset-0 z-30 h-full w-full bg-dark-grey opacity-80"
        onClick={onCloseModal}
      />
      <div className="fixed inset-0 z-30 mx-4 flex h-full items-start justify-center overflow-auto">
        <Dialog.Panel
          className={`max-w-full ${marginY} mx-auto rounded bg-white shadow-lg`}
        >
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Modal

export const ModalBody = ({
  children,
  isFullScreen = false,
}: {
  children: ReactNode
  isFullScreen?: boolean
}): JSX.Element => {
  return (
    <div
      className={clsx('flex flex-col p-4', {
        'h-full-screen-modal': isFullScreen,
      })}
      role="dialog"
    >
      {children}
    </div>
  )
}

export const ModalButtons = ({
  apiError = null,
  cancelBtnText = 'Cancel',
  mainActionBtnStyle = 'primary',
  mainActionBtnText,
  hasValidationErrors = false,
  isLoading = false,
  onCancel,
  onMainAction,
}: {
  apiError?: Error | null
  cancelBtnText?: string
  mainActionBtnStyle?: ButtonLoadingProps['buttonStyle']
  mainActionBtnText: string
  hasValidationErrors?: boolean
  isLoading?: boolean
  onCancel(): void
  onMainAction?: () => void
}): JSX.Element => {
  return (
    <div>
      {apiError && <APIErrorDisplay error={apiError} />}
      <div className="mt-4 flex items-center justify-between">
        {hasValidationErrors ? (
          <ErrorDisplay message="Please fix the above errors." />
        ) : null}
        <div className="flex flex-grow items-center justify-end space-x-2">
          <div className="w-24">
            <Button buttonStyle="cancel" onClick={onCancel}>
              {cancelBtnText}
            </Button>
          </div>
          <div className="w-24">
            <ButtonLoading
              buttonStyle={mainActionBtnStyle}
              isLoading={isLoading}
              onClick={onMainAction}
            >
              {mainActionBtnText}
            </ButtonLoading>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ModalHeader = ({
  children,
  onClickClose,
}: {
  children: ReactNode
  onClickClose: () => void
}): JSX.Element => {
  return (
    <div className="flex items-center justify-between">
      <Dialog.Title className="text-xl">{children}</Dialog.Title>
      <div
        className="h-5 w-5 cursor-pointer text-dark-grey"
        data-testid="modal-header-close"
        onClick={onClickClose}
      >
        <XIcon />
      </div>
    </div>
  )
}
