import { ReactNode, useState } from 'react'

import { useInlineEdit } from 'hooks/forms'
import { useTouchOutside } from 'hooks/dom'
import Checkbox, { Props as CheckboxProps } from 'components/common/Checkbox'
import CircleLoader from './CircleLoader'
import InlineEditDisplay from './InlineEditDisplay'

export type Props = Omit<
  CheckboxProps,
  'autoFocus' | 'checked' | 'disabled' | 'onChange'
> & {
  children: ReactNode
  editRender?: (input: ReactNode) => JSX.Element
  initialValue: boolean
  isEditable: boolean
  name: string
  onChange(newValue: boolean): Promise<unknown>
  shouldResetOnSuccess?: boolean
}

function InlineEditCheckbox({
  children,
  editRender = undefined,
  initialValue,
  isEditable,
  name,
  onChange,
  shouldResetOnSuccess = false,
  ...rest
}: Props): JSX.Element {
  const {
    currentValue,
    editError,
    isChanging,
    isEditing,
    onStartEdit,
    onSubmit,
    onUpdateValue,
  } = useInlineEdit({ initialValue, name, onChange, shouldResetOnSuccess })

  let editContent: ReactNode | null = null
  if (isEditing) {
    const checkbox = (
      <EditingCheckbox
        {...rest}
        currentValue={currentValue}
        isChanging={isChanging}
        name={name}
        onSubmit={onSubmit}
        onUpdateValue={onUpdateValue}
      />
    )

    editContent = editRender ? editRender(checkbox) : checkbox
  }

  return (
    <InlineEditDisplay
      editContent={editContent}
      editError={editError}
      isEditable={isEditable}
      isEditing={isEditing}
      minWidthClass="min-w-[12rem]"
      onStartEdit={onStartEdit}
    >
      {children}
    </InlineEditDisplay>
  )
}

export default InlineEditCheckbox

const EditingCheckbox = ({
  currentValue,
  isChanging,
  name,
  onSubmit,
  onUpdateValue,
  ...rest
}: {
  currentValue: boolean
  isChanging: boolean
  name: string
  onSubmit(): void
  onUpdateValue(newValue: boolean): void
} & Omit<
  CheckboxProps,
  'autoFocus' | 'checked' | 'disabled' | 'onChange'
>): JSX.Element => {
  const [checkboxContainer, setCheckboxContainer] =
    useState<HTMLDivElement | null>(null)

  useTouchOutside({
    allowTargetNotInDom: true,
    el: checkboxContainer,
    enabled: true,
    onTouchOutside: () => {
      onSubmit()
    },
  })

  return (
    <div
      ref={(ref) => {
        setCheckboxContainer(ref)
      }}
      className="relative"
    >
      <Checkbox
        {...rest}
        checked={currentValue}
        disabled={isChanging}
        name={name}
        onChange={(event) => {
          onUpdateValue(event.target.checked)
        }}
      />

      {isChanging && (
        <div className="absolute top-0 left-0 bottom-0 bg-white">
          <CircleLoader isColored />
        </div>
      )}
    </div>
  )
}
