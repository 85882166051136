import { assign, createMachine } from 'xstate'
import { isEqual } from 'lodash-es'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'
import { useMachine } from '@xstate/react'

import { getAPIErrorMessage } from 'utils/api'

interface Context<ValueType> {
  currentValue: ValueType
  editError: string
  initialValue: ValueType
  shouldResetOnSuccess: boolean
}

type Events<ValueType> =
  | {
      type: 'EDIT'
    }
  | {
      type: 'INITIAL_VALUE_UPDATED'
      value: ValueType
    }
  | {
      type: 'SUBMIT'
    }
  | {
      submitOnUpdate: boolean
      type: 'UPDATE_VALUE'
      value: ValueType
    }

type Services = {
  saveValue: {
    data: unknown
  }
}

export function createInlineEditMachine<ValueType>({
  initialValue,
  name,
  shouldResetOnSuccess,
}: {
  initialValue: ValueType
  name: string
  shouldResetOnSuccess: boolean
}) {
  return createMachine<Context<ValueType>, Events<ValueType>>(
    {
      id: `inlineEdit-${name}`,
      initial: 'idle',
      context: {
        currentValue: initialValue,
        editError: '',
        initialValue,
        shouldResetOnSuccess,
      },
      predictableActionArguments: true,
      schema: {
        context: {} as Context<ValueType>,
        events: {} as Events<ValueType>,
        services: {} as Services,
      },
      states: {
        idle: {
          on: {
            EDIT: {
              target: 'editing',
            },
            INITIAL_VALUE_UPDATED: {
              actions: ['updateCurrentValue', 'updateInitialValue'],
            },
          },
        },
        editing: {
          on: {
            SUBMIT: {
              target: 'checkingIfSaveNeeded',
            },
            UPDATE_VALUE: [
              {
                actions: ['updateCurrentValue'],
                cond: 'shouldSubmitWithUpdate',
                target: 'checkingIfSaveNeeded',
              },
              { actions: ['updateCurrentValue'] },
            ],
          },
        },
        checkingIfSaveNeeded: {
          always: [
            {
              cond: 'isValueSameAsInitial',
              target: 'idle',
            },
            {
              target: 'saving',
            },
          ],
        },
        saving: {
          entry: ['clearEditError'],
          invoke: {
            src: 'saveValue',
            onDone: [
              {
                actions: ['resetCurrentValue'],
                cond: 'shouldResetOnSuccess',
                target: 'idle',
              },
              {
                target: 'idle',
              },
            ],
            onError: {
              actions: ['updateEditError'],
              target: 'idle',
            },
          },
          on: {
            INITIAL_VALUE_UPDATED: {
              actions: ['updateInitialValue'],
            },
          },
        },
      },
    },
    {
      actions: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        clearEditError: assign((_context) => {
          return {
            editError: '',
          }
        }),
        resetCurrentValue: assign((context) => {
          return {
            currentValue: context.initialValue,
          }
        }),
        updateCurrentValue: assign((context, event) => {
          if (
            event.type !== 'INITIAL_VALUE_UPDATED' &&
            event.type !== 'UPDATE_VALUE'
          ) {
            return context
          }

          return {
            currentValue: event.value,
          }
        }),
        updateEditError: assign((_context, event) => {
          return {
            // Typing service done events is not great in XState right now. Waiting for v5 to re-evaluate.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            editError: getAPIErrorMessage(event.data),
          }
        }),
        updateInitialValue: assign((context, event) => {
          if (event.type !== 'INITIAL_VALUE_UPDATED') {
            return context
          }

          return {
            initialValue: event.value,
          }
        }),
      },
      guards: {
        isValueSameAsInitial: (context) => {
          return isEqual(context.currentValue, context.initialValue)
        },
        shouldResetOnSuccess: (context) => {
          return context.shouldResetOnSuccess
        },
        shouldSubmitWithUpdate: (_context, event) => {
          return 'submitOnUpdate' in event && event.submitOnUpdate
        },
      },
    }
  )
}

export function useInlineEdit<ValueType>({
  initialValue,
  name,
  onChange,
  shouldResetOnSuccess = false,
}: {
  initialValue: ValueType
  name: string
  onChange(newValue: ValueType): Promise<unknown>
  shouldResetOnSuccess?: boolean
}) {
  const [state, send] = useMachine(
    () => createInlineEditMachine({ initialValue, name, shouldResetOnSuccess }),
    {
      services: {
        saveValue: (context) => {
          return onChange(context.currentValue)
        },
      },
    }
  )
  const { currentValue, editError } = state.context

  useDeepCompareEffectNoCheck(() => {
    send({ type: 'INITIAL_VALUE_UPDATED', value: initialValue })
  }, [initialValue])

  return {
    currentValue,
    editError,
    isChanging: state.matches('saving'),
    isEditing:
      state.matches('editing') ||
      state.matches('checkingIfSaveNeeded') ||
      state.matches('saving'),
    onStartEdit: () => {
      send({ type: 'EDIT' })
    },
    onSubmit: () => {
      send({ type: 'SUBMIT' })
    },
    onUpdateValue: (
      value: ValueType,
      { submitOnUpdate = false }: { submitOnUpdate?: boolean } = {}
    ) => {
      send({
        submitOnUpdate,
        type: 'UPDATE_VALUE',
        value,
      })
    },
  }
}
