import { get } from './baseAPI'
import { PurchasedGoodCosts } from 'types/domainModels/recipes'

export interface GetPurchasedGoodCosts {
  procurementID: string
}

export const ENDPOINTS = {
  GET_PURCHASED_GOOD_COSTS: ({ procurementID }: GetPurchasedGoodCosts) => ({
    path: `/purchasedGoods/${procurementID}/costs`,
    version: 'procurement/v1' as const,
  }),
}

export function getPurchasedGoodCosts(opts: GetPurchasedGoodCosts) {
  return get<PurchasedGoodCosts>(ENDPOINTS.GET_PURCHASED_GOOD_COSTS(opts))
}
