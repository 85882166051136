import { Link, Navigate, Route, Routes } from 'react-router-dom'
import { orderBy, round } from 'lodash-es'
import { useState } from 'react'

import {
  Draft,
  DraftLineItem as IDraftLineItem,
  Version,
} from 'types/domainModels/recipes'
import { formatPercent, gramsToLbs } from 'utils/general'
import {
  getLineItemCostVersion,
  getTotalPostCookingYield,
  getTotalWeight,
} from 'utils/drafts'
import { getVersionCostForFacilityNetworks } from 'utils/versions'
import { PartWithVersions } from 'types/internal'

import { useEditVersion, useHasNutritionDiverged } from 'hooks/recipes/versions'
import { useLineItemCosts, useLineItemsCosts } from 'hooks/recipes/drafts'
import Button from 'components/common/Button'
import InlineEditInput from 'components/common/InlineEditInput'
import InlineEditTextarea from 'components/common/InlineEditTextarea'
import LabeledField from './LabeledField'
import LineItemName from './LineItemName'
import RecipeCostsGrid from './RecipeCostsGrid'
import ScaleRecipeModal from './ScaleRecipeModal'
import TableHeader from 'components/common/TableHeader'
import Tabs, { Tab } from 'components/common/Tabs'
import Tooltip from 'components/common/Tooltip'
import VersionOperationsTab from './VersionOperationsTab'
import VersionStatusTable from './VersionStatusTable'
import VersionDefaultTagsTab from './VersionDefaultTagsTab'

const TABS = [
  {
    end: true,
    label: 'Procurement',
    path: '',
  },
  {
    label: 'Operations',
    path: 'operations',
  },
  {
    label: 'Default Tags',
    path: 'defaultTags',
  },
]

const PartVersion = ({
  part,
  version,
}: {
  part: PartWithVersions
  version: Version
}): JSX.Element => {
  const activeDraft = part.recipe.drafts.find(({ id }) => {
    return id === version.draftID
  })

  return (
    <div className="flex justify-between divide-x divide-light-grey lg:flex-wrap lg:space-y-4 lg:divide-x-0">
      <div className="w-2/3 pr-4 lg:w-full lg:pr-0">
        <div>
          <div className="relative border-b border-light-grey pl-4 text-sm">
            <Tabs>
              {TABS.map(({ end = false, label, path }) => {
                return (
                  <Tab key={label} end={end} to={path}>
                    {label}
                  </Tab>
                )
              })}
            </Tabs>
          </div>
          <div className="mt-4">
            <Routes>
              <Route
                element={
                  <Recipe
                    draft={activeDraft}
                    partID={part.id}
                    version={version}
                  />
                }
                index
              />
              <Route
                element={<VersionOperationsTab version={version} />}
                path="operations"
              />
              <Route
                element={<VersionDefaultTagsTab version={version} />}
                path="defaultTags"
              />
              <Route element={<Navigate replace to="" />} path="*" />
            </Routes>
          </div>
        </div>
      </div>
      <div className="w-1/3 pl-4 lg:w-1/2 lg:pl-0 md:w-full">
        <div className="space-y-4">
          <VersionSidebar
            draft={activeDraft}
            partID={part.id}
            version={version}
          />
        </div>
      </div>
    </div>
  )
}

export default PartVersion

const Recipe = ({
  draft,
  partID,
  version,
}: {
  draft: Draft | undefined
  partID: string
  version: Version
}): JSX.Element => {
  const recipeHeader = <h2 className="text-xl">Recipe</h2>
  const recipeTableClasses = 'grid grid-cols-version-line-items-table gap-4'

  const { mutateAsync: editVersion } = useEditVersion({
    partID,
    versionNumber: version.versionNumber,
  })

  const {
    costs: lineItemCosts,
    isError: hasLoadLineItemsConstsError,
    isLoading: isLoadingLineItemsCosts,
  } = useLineItemsCosts({
    lineItems: draft?.lineItems ?? [],
  })

  if (!draft) {
    return (
      <>
        {recipeHeader}
        <p className="mt-2">No recipe found for this version.</p>
      </>
    )
  }

  const totalPostCookYield = getTotalPostCookingYield({
    draft,
    postCookingYield: version.postCookingYield,
  })
  const totalWeight = getTotalWeight(draft)

  const orderedLineItems = orderBy(draft.lineItems, ({ name }) => {
    return name.toLowerCase()
  })

  const { chicagoCost, slcCost } = getVersionCostForFacilityNetworks({
    draft,
    lineItemCosts,
    version,
  })

  const commonCostProps = {
    isLoading: isLoadingLineItemsCosts,
    loadError: hasLoadLineItemsConstsError
      ? new Error('Failed to load cost information')
      : null,
    showVersion: false,
  }

  return (
    <>
      <div className="mb-4 flex justify-between">
        {recipeHeader}

        <ScaleRecipe
          lineItems={orderedLineItems}
          postCookingYield={version.postCookingYield}
          totalWeight={totalWeight}
        />
      </div>
      <div className="w-80 text-sm">
        <LabeledField title="Post Cooking Yield">
          <InlineEditInput
            editRender={(input) => {
              return (
                <div className="flex w-24 items-center space-x-2">
                  {input}
                  <span>%</span>
                </div>
              )
            }}
            initialValue={
              version.postCookingYield
                ? `${version.postCookingYield * 100}`
                : ''
            }
            isEditable
            name="post-cooking-yield"
            onChange={(postCookingYield) => {
              return editVersion({
                postCookingYield: Number(postCookingYield) / 100,
              })
            }}
            type="number"
          >
            {version.postCookingYield
              ? formatPercent(version.postCookingYield)
              : 'N/A'}
          </InlineEditInput>
        </LabeledField>
        {!version.postCookingYield && (
          <p className="mt-1 text-xs text-red">
            Post cooking yield required for procurement approal
          </p>
        )}
      </div>
      <div className="-mt-2 text-sm">
        <TableHeader>
          <div className={`${recipeTableClasses} items-end `}>
            <div>Line Item</div>
            <div className="text-center">Weight (G)</div>
            <div className="text-center">Percent (%)</div>
            <div className="text-center">Post Cook WGT (LBS)</div>
            <div className="text-center">
              <div className="mb-1">Facility Networks</div>
              <div className="grid grid-cols-3 items-end">
                <div className="text-left">Name</div>
                <div>Active Version</div>
                <div>Cost / lb</div>
              </div>
            </div>
          </div>
        </TableHeader>
        <div>
          {orderedLineItems.map((lineItem) => {
            return (
              <RecipeLineItem
                key={lineItem.id}
                lineItem={lineItem}
                postCookingYield={version.postCookingYield}
                recipeTableClasses={recipeTableClasses}
                totalWeight={totalWeight}
              />
            )
          })}

          <div
            className={`${recipeTableClasses} py-2 text-xs font-bold uppercase text-dark-grey`}
            data-testid="version-recipe-totals-row"
          >
            <div>Totals</div>
            <div className="text-center">{round(totalWeight, 2)}</div>
            <div></div>
            <div className="text-center">
              {totalPostCookYield ? totalPostCookYield.toPrecision(2) : '---'}
            </div>
            <div className="space-y-1">
              <RecipeCostsGrid
                {...commonCostProps}
                costPerPound={chicagoCost}
                facilityNetwork="Chicago"
              />
              <RecipeCostsGrid
                {...commonCostProps}
                costPerPound={slcCost}
                facilityNetwork="SLC"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ScaleRecipe = ({
  lineItems,
  postCookingYield,
  totalWeight,
}: {
  lineItems: IDraftLineItem[]
  postCookingYield: number
  totalWeight: number
}): JSX.Element => {
  const [isScaleModalOpen, setIsScaleModalOpen] = useState(false)

  return (
    <>
      <Tooltip
        enabled={postCookingYield === 0}
        trigger={
          <div className="w-32">
            <Button
              disabled={postCookingYield === 0}
              onClick={() => {
                setIsScaleModalOpen(true)
              }}
            >
              Scale Recipe
            </Button>
          </div>
        }
      >
        <p className="p-2 text-sm">
          Post Cooking Yield must be set before a recipe can be scaled.
        </p>
      </Tooltip>

      {isScaleModalOpen && (
        <ScaleRecipeModal
          lineItems={lineItems}
          onCloseModal={() => {
            setIsScaleModalOpen(false)
          }}
          postCookingYield={postCookingYield}
          totalWeight={totalWeight}
        />
      )}
    </>
  )
}

const RecipeLineItem = ({
  lineItem,
  postCookingYield,
  recipeTableClasses,
  totalWeight,
}: {
  lineItem: IDraftLineItem
  postCookingYield: number
  recipeTableClasses: string
  totalWeight: number
}): JSX.Element => {
  const {
    data: lineItemCosts,
    error,
    isError,
    isLoading: isLoadingLineItemCosts,
  } = useLineItemCosts({ lineItem })

  const { chicago: chicagoCost, slc: slcCost } =
    lineItemCosts?.facilityNetworkCosts || {}

  const commonCostProps = {
    isLoading: isLoadingLineItemCosts,
    loadError: isError ? error : null,
    smallVersion: lineItem.ingredientType === 'purchased_good',
  }

  const percentOfTotalWeight = lineItem.quantityGrams / totalWeight
  const postCookWgt = gramsToLbs(lineItem.quantityGrams * postCookingYield)

  return (
    <div
      className={`${recipeTableClasses} border-b border-light-grey py-2`}
      data-testid={`version-recipe-line-item-${lineItem.id}`}
    >
      <div>
        <LineItemName lineItem={lineItem} />
      </div>
      <div className="text-center">{lineItem.quantityGrams}</div>
      <div className="text-center">{formatPercent(percentOfTotalWeight)}</div>
      <div className="text-center">
        {postCookWgt ? postCookWgt.toPrecision(2) : '---'}
      </div>
      <div>
        <RecipeCostsGrid
          {...commonCostProps}
          costPerPound={chicagoCost?.costPerPound}
          facilityNetwork="Chicago"
          versionNumber={getLineItemCostVersion(chicagoCost)}
        />
        <RecipeCostsGrid
          {...commonCostProps}
          costPerPound={slcCost?.costPerPound}
          facilityNetwork="SLC"
          versionNumber={getLineItemCostVersion(slcCost)}
        />
      </div>
    </div>
  )
}

const VersionSidebar = ({
  draft,
  partID,
  version,
}: {
  draft: Draft | undefined
  partID: string
  version: Version
}) => {
  const { mutateAsync: editVersion } = useEditVersion({
    partID,
    versionNumber: version.versionNumber,
  })

  return (
    <div>
      <h2 className="mb-2 text-xl">Version Information</h2>
      <div className="space-y-4 text-sm">
        <VersionStatus version={version} />
        <LabeledField title="Draft #">
          <div className="flex items-center space-x-4">
            <span>{draft?.draftNumber ?? 'N/A'}</span>
            <Link
              className="text-xs uppercase text-blue"
              to={`/parts/${partID}/drafts${draft ? `/${draft.id}` : ''}`}
            >
              View Drafts
            </Link>
          </div>
        </LabeledField>
        <LabeledField title="Author">{draft?.authorName ?? 'N/A'}</LabeledField>
        <LabeledField title="Description">
          <InlineEditTextarea
            initialValue={version.description}
            isEditable
            name="description"
            onChange={(newValue) => {
              return editVersion({
                description: newValue,
              })
            }}
            rows={3}
          >
            {version.description || 'N/A'}
          </InlineEditTextarea>
        </LabeledField>
      </div>
    </div>
  )
}

const VersionStatus = ({ version }: { version: Version }): JSX.Element => {
  const hasNutritionDiverged = useHasNutritionDiverged({ version })

  return (
    <>
      <VersionStatusTable version={version} />
      {hasNutritionDiverged && (
        <p className="ml-2 text-sm">
          <span className="font-bold text-red">Warning:</span> May have
          different nutrition between facility networks
        </p>
      )}
    </>
  )
}
