import { get, patch, post } from './baseAPI'
import { PurchasedGood } from 'types/domainModels/purchasedGoods'

export interface CreatePurchasedGood {
  data: {
    category: string
    ingredientName: string
    isActive: boolean
    storageLocation: string
    warehouseZone: string
  }
}

export interface EditPurchasedGood {
  data: Partial<{
    category: string
    ingredientName: string
    isActive: boolean
    storageLocation: string
    warehouseZone: string
  }>
  purchasedGoodID: string
}

export interface GetPurchasedGood {
  purchasedGoodID: string
}

export interface GetPurchasedGoods {
  category?: string | undefined
  cursor: string | undefined
  limit: number
  nameLike?: string | undefined
}

export type GetPurchasedGoodsFilters = 'category' | 'nameLike'

export interface GetPurchasedGoodsResponse {
  nextCursor?: string
  purchasedGoods: PurchasedGood[]
}

export const ENDPOINTS = {
  CREATE_PURCHASED_GOOD: () => ({
    path: '/purchasedGoods',
    version: 'v1' as const,
  }),
  EDIT_PURCHASED_GOOD: ({
    purchasedGoodID,
  }: Pick<EditPurchasedGood, 'purchasedGoodID'>) => ({
    path: `/purchasedGoods/${purchasedGoodID}`,
    version: 'v1' as const,
  }),
  GET_PURCHASED_GOOD: ({
    purchasedGoodID,
  }: Pick<GetPurchasedGood, 'purchasedGoodID'>) => ({
    path: `/purchasedGoods/${purchasedGoodID}`,
    version: 'v1' as const,
  }),
  GET_PURCHASED_GOODS: () => ({
    path: '/purchasedGoods',
    version: 'v1' as const,
  }),
}

export function createPurchasedGood({ data }: CreatePurchasedGood) {
  return post<PurchasedGood>({
    data,
    ...ENDPOINTS.CREATE_PURCHASED_GOOD(),
  })
}

export function editPurchasedGood({
  data,
  purchasedGoodID,
}: EditPurchasedGood) {
  return patch<PurchasedGood>({
    data,
    ...ENDPOINTS.EDIT_PURCHASED_GOOD({ purchasedGoodID }),
  })
}

export function getPurchasedGood(opts: GetPurchasedGood) {
  return get<PurchasedGood>(ENDPOINTS.GET_PURCHASED_GOOD(opts))
}

export function getPurchasedGoods({
  category,
  cursor,
  limit,
  nameLike,
}: GetPurchasedGoods) {
  return get<GetPurchasedGoodsResponse>({
    config: { params: { category, cursor, limit, nameLike } },
    ...ENDPOINTS.GET_PURCHASED_GOODS(),
  })
}
