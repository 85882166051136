import { useLocation } from 'react-router-dom'

export function useQueryParams<QueryParamKeys extends readonly string[]>(
  queryParamKeys: QueryParamKeys
): Record<QueryParamKeys[number], string> {
  const { search: locationSearch } = useLocation()
  const urlParams = new URLSearchParams(locationSearch)

  const queryParams: Record<string, string> = {}

  queryParamKeys.forEach((key) => {
    queryParams[key] = urlParams.get(key) ?? ''
  })

  return queryParams
}
