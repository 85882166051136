import { ReactNode } from 'react'

import FormFieldError from 'components/common/FormFieldError'
import FormFieldLabel from 'components/common/FormFieldLabel'

const FormGroup = ({
  children,
  error,
  label,
  labelFor,
}: {
  children: ReactNode
  error?: string
  label?: ReactNode
  labelFor?: string
}): JSX.Element => {
  return (
    <div>
      {label && (
        <div className="mb-1">
          <FormFieldLabel
            hasError={!!error}
            label={label}
            labelFor={labelFor}
          />
        </div>
      )}

      {children}

      {error ? <FormFieldError error={error} /> : null}
    </div>
  )
}

export default FormGroup
