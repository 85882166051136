import { ChangeEvent, Fragment, ReactNode } from 'react'
import { clsx } from 'clsx'
import { Combobox as HeadlessUICombobox } from '@headlessui/react'

import APIErrorDisplay from 'components/common/APIErrorDisplay'
import SearchIcon from 'components/common/icons/SearchIcon'

function SearchField<OptionType extends { id: number | string }>({
  OptionComponent,
  hasQuery,
  isSearching,
  name,
  onChangeQuery,
  onSelectOption,
  options,
  resultsHeader = undefined,
  searchError,
}: {
  OptionComponent(props: { option: OptionType }): JSX.Element
  hasQuery: boolean
  isSearching: boolean
  name: string
  onChangeQuery(event: ChangeEvent<HTMLInputElement>): void
  onSelectOption(newOption: OptionType | null): void
  options: OptionType[]
  resultsHeader?: ReactNode
  searchError: Error | null
}): JSX.Element {
  const resultsContainerClasses =
    'absolute bg-white w-96 md:w-full border border-t-0 divide-y drop-shadow max-h-96 overflow-y-auto'

  return (
    <HeadlessUICombobox onChange={onSelectOption} value={null}>
      <div className="relative z-10 w-full">
        <div className="relative flex items-center drop-shadow">
          <div className="absolute ml-2 h-4 w-4">
            <SearchIcon />
          </div>
          <HeadlessUICombobox.Label className="hidden">
            Search
          </HeadlessUICombobox.Label>
          <HeadlessUICombobox.Input
            autoComplete="off"
            className="w-96 border p-2 pl-8 md:w-full"
            name={`search-${name}`}
            onChange={onChangeQuery}
            placeholder="Search..."
          />
        </div>

        <HeadlessUICombobox.Options>
          {options.length > 0 && (
            <div className={resultsContainerClasses}>
              {resultsHeader ? (
                <div className="px-4">{resultsHeader}</div>
              ) : null}

              {options.map((option) => {
                return (
                  <HeadlessUICombobox.Option
                    key={option.id}
                    as={Fragment}
                    data-testid="search-result"
                    value={option}
                  >
                    {({ active }) => {
                      return (
                        <li
                          className={clsx('p-4 hover:cursor-pointer', {
                            'bg-lightest-grey': active,
                          })}
                        >
                          <OptionComponent option={option} />
                        </li>
                      )
                    }}
                  </HeadlessUICombobox.Option>
                )
              })}
            </div>
          )}

          {options.length === 0 && (
            <div className={resultsContainerClasses}>
              <div className="p-4 text-sm text-dark-grey">
                {hasQuery ? 'No results' : 'Please type to search...'}
              </div>
            </div>
          )}

          {searchError && (
            <div className={resultsContainerClasses}>
              <APIErrorDisplay error={searchError} />
            </div>
          )}

          {isSearching && (
            <SearchLoadingRows
              resultsContainerClasses={resultsContainerClasses}
            />
          )}
        </HeadlessUICombobox.Options>
      </div>
    </HeadlessUICombobox>
  )
}

export default SearchField

const SearchLoadingRows = ({
  resultsContainerClasses,
}: {
  resultsContainerClasses: string
}): JSX.Element => {
  return (
    <div className={resultsContainerClasses}>
      {new Array(2).map((_unused, i) => {
        return (
          <div key={i} className="px-2 py-4">
            <div className="h-4 w-1/2 animate-pulse justify-self-start bg-light-grey" />
          </div>
        )
      })}
    </div>
  )
}
