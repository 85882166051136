import { ReactNode } from 'react'

import CheckIcon from './icons/CheckIcon'

const SuccessDisplay = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <div className="flex items-start space-x-2 bg-faded-green p-4 text-sm">
      <div className="mt-0.5 h-4 w-4 flex-shrink-0 text-green">
        <CheckIcon />
      </div>
      <div>{children}</div>
    </div>
  )
}

export default SuccessDisplay
