import { SVGAttributes } from 'react'

const CheckIcon = (
  props: Omit<
    SVGAttributes<SVGElement>,
    'fill' | 'stroke' | 'viewBox' | 'xmlns'
  >
): JSX.Element => {
  return (
    <svg
      {...props}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 13l4 4L19 7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  )
}

export default CheckIcon
