import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'

import {
  CreateRecipe,
  createRecipe,
  editRecipe,
  EditRecipe,
  getRecipeProcurability,
} from 'services/recipes/recipes'
import { Recipe, RecipeProcurability } from 'types/domainModels/recipes'

import { useInvalidateIngredients } from './ingredients'
import { useInvalidatePart } from './parts'

export function getRecipeProcurabilityQueryObj({
  recipeID,
}: {
  recipeID: string
}) {
  return {
    queryFn: () => {
      return getRecipeProcurability({ recipeID })
    },
    queryKey: ['recipe-procurability', recipeID],
  }
}

export function useCreateRecipe(
  opts: UseMutationOptions<Recipe, Error, CreateRecipe['data']>
) {
  const { invalidateIngredients } = useInvalidateIngredients()

  return useMutation(
    async (data) => {
      return createRecipe({ data })
    },
    {
      ...opts,
      onSuccess: (...args) => {
        opts.onSuccess?.(...args)

        invalidateIngredients()
      },
    }
  )
}

export function useEditRecipe({
  recipeID,
  ...rest
}: {
  recipeID: string
} & UseMutationOptions<Recipe, Error, EditRecipe['data']>) {
  const { invalidateIngredients } = useInvalidateIngredients()
  const { invalidatePart } = useInvalidatePart()

  return useMutation(
    async (data) => {
      return editRecipe({ data, recipeID })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        rest.onSuccess?.(...args)

        invalidateIngredients()
        invalidatePart(recipeID, { newRecipe: args[0] })
      },
    }
  )
}

export function useRecipeProcurability({
  recipeID,
  ...rest
}: { recipeID: string } & Omit<
  UseQueryOptions<RecipeProcurability, Error>,
  'queryFn' | 'queryKey'
>) {
  return useQuery<RecipeProcurability, Error>({
    ...rest,
    ...getRecipeProcurabilityQueryObj({ recipeID }),
  })
}
