import { ReactNode } from 'react'

import { ReactSelectValue } from 'types/internal'

import { useInlineEdit } from 'hooks/forms'
import InlineEditDisplay from './InlineEditDisplay'
import Select, { SelectProps } from 'components/common/Select'

export type Props<OptionType> = Omit<
  SelectProps<OptionType>,
  | 'autoFocus'
  | 'blurInputOnSelect'
  | 'isDisabled'
  | 'isSaving'
  | 'onBlur'
  | 'onChange'
  | 'onKeyDown'
  | 'openMenuOnFocus'
  | 'value'
> & {
  children: ReactNode
  editRender?: (input: ReactNode) => JSX.Element
  initialValue: OptionType
  isEditable: boolean
  onChange(newValue: OptionType | null): Promise<unknown>
  shouldResetOnSuccess?: boolean
}

function InlineEditSelect<OptionType extends ReactSelectValue<unknown> | null>({
  children,
  editRender = undefined,
  initialValue,
  isEditable,
  name,
  onChange,
  shouldResetOnSuccess = false,
  ...rest
}: Props<OptionType>): JSX.Element {
  const {
    currentValue,
    editError,
    isChanging,
    isEditing,
    onStartEdit,
    onSubmit,
    onUpdateValue,
  } = useInlineEdit({
    initialValue,
    name,
    onChange,
    shouldResetOnSuccess,
  })

  let editContent: ReactNode | null = null
  if (isEditing) {
    const select = (
      <div className="w-48 max-w-full">
        <Select
          {...rest}
          autoFocus={true}
          blurInputOnSelect
          isDisabled={isChanging}
          isSaving={isChanging}
          name={name}
          onBlur={() => {
            onSubmit()
          }}
          onChange={(value) => {
            onUpdateValue(value, { submitOnUpdate: true })
          }}
          openMenuOnFocus
          value={currentValue}
        />
      </div>
    )

    editContent = editRender ? editRender(select) : select
  }

  return (
    <InlineEditDisplay
      editContent={editContent}
      editError={editError}
      isEditable={isEditable}
      isEditing={isEditing}
      minWidthClass="min-w-[12rem]"
      onStartEdit={onStartEdit}
    >
      {children}
    </InlineEditDisplay>
  )
}

export default InlineEditSelect
