import { clsx } from 'clsx'
import { ReactNode } from 'react'

import { formatDollars } from 'utils/currency'

import ExclamationCircleIcon from 'components/common/icons/ExclamationCircleIcon'

export interface Props {
  costPerPound?: number | null
  facilityNetwork: 'Chicago' | 'SLC'
  isLoading?: boolean
  loadError?: Error | null
  showVersion?: boolean
  smallVersion?: boolean
  versionNumber?: string
}

const RecipeCostsGrid = ({
  costPerPound,
  facilityNetwork,
  isLoading = false,
  loadError = null,
  showVersion = true,
  smallVersion = false,
  versionNumber,
}: Props) => {
  const gridClasses = 'grid grid-cols-3 text-center'
  let versionContent: ReactNode | null = (
    <div>
      {versionNumber ? (
        <span className={clsx({ 'text-xs': smallVersion })}>
          {versionNumber}
        </span>
      ) : (
        '---'
      )}
    </div>
  )
  let costContent = (
    <div>
      {costPerPound === null || costPerPound === undefined
        ? '---'
        : formatDollars(costPerPound)}
    </div>
  )

  if (isLoading) {
    versionContent = <div />
    costContent = (
      <div className="flex justify-center">
        <div
          aria-label="Loading cost"
          className="h-4 w-12 animate-pulse bg-light-grey"
        />
      </div>
    )
  }

  if (loadError) {
    versionContent = <div />
    costContent = (
      <div className="flex justify-center">
        <div className="h-4 w-4 text-red">
          <ExclamationCircleIcon
            aria-label="Cost load failed"
            tooltip={<p className="p-2 text-sm">Error: {loadError.message}</p>}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={gridClasses} data-testid={`${facilityNetwork}-costs-grid`}>
      <div className="text-left">{facilityNetwork}</div>
      {showVersion ? versionContent : <div />}
      {costContent}
    </div>
  )
}

export default RecipeCostsGrid
