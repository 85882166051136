import { orderBy } from 'lodash-es'

import { ReactSelectValue } from 'types/internal'

export function getStorageLocationOptions(
  storageLocations: string[]
): ReactSelectValue<string>[] {
  const orderedStorageLocations = orderBy(storageLocations)

  return orderedStorageLocations.map((storageLocation) => {
    return { label: storageLocation, value: storageLocation }
  })
}
