import { ReactNode } from 'react'

import { useInlineEdit } from 'hooks/forms'
import InputAsync, {
  Props as InputAsyncProps,
} from 'components/common/InputAsync'
import InlineEditDisplay from './InlineEditDisplay'

export type Props = Omit<
  InputAsyncProps,
  'autoFocus' | 'isLoading' | 'onBlur' | 'onChange' | 'onKeyDown' | 'value'
> & {
  children: ReactNode
  editRender?: (input: ReactNode) => JSX.Element
  initialValue: string
  isEditable: boolean
  name: string
  onChange(newValue: string): Promise<unknown>
  shouldResetOnSuccess?: boolean
}

function InlineEditInput({
  children,
  editRender = undefined,
  initialValue,
  isEditable,
  name,
  onChange,
  shouldResetOnSuccess = false,
  ...rest
}: Props): JSX.Element {
  const {
    currentValue,
    editError,
    isChanging,
    isEditing,
    onStartEdit,
    onSubmit,
    onUpdateValue,
  } = useInlineEdit({ initialValue, name, onChange, shouldResetOnSuccess })

  let editContent: JSX.Element | null = null
  if (isEditing) {
    const input = (
      <InputAsync
        {...rest}
        autoFocus={true}
        isLoading={isChanging}
        name={name}
        onBlur={() => {
          onSubmit()
        }}
        onChange={(event) => {
          onUpdateValue(event.target.value)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSubmit()
          }
        }}
        value={currentValue}
      />
    )

    editContent = editRender ? editRender(input) : input
  }

  return (
    <InlineEditDisplay
      editContent={editContent}
      editError={editError}
      isEditable={isEditable}
      isEditing={isEditing}
      minWidthClass="min-w-[6rem]"
      onStartEdit={onStartEdit}
    >
      {children}
    </InlineEditDisplay>
  )
}

export default InlineEditInput
