import Analytics from 'analytics'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Can't find types for this plugin right now.
import segmentPlugin from '@analytics/segment'
import * as Sentry from '@sentry/react'

import { getEnvVar } from './env'
import { JWTPayload } from 'types/domainModels/recipes'

export const analytics = Analytics({
  app: 'culinary-tools',
  plugins:
    getEnvVar('APP_ENV') === 'test'
      ? []
      : [
          segmentPlugin({
            writeKey: getEnvVar('SEGMENT_WRITE_KEY'),
          }),
        ],
})

export function identifyUser({ decodedJWT }: { decodedJWT: JWTPayload }): void {
  analytics.identify(`${decodedJWT.userId}`, {
    system: 'culinary-tools',
    version: getEnvVar('VERCEL_GIT_COMMIT_SHA'),
  })

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: `${decodedJWT.userId}`,
      version: getEnvVar('VERCEL_GIT_COMMIT_SHA'),
    })
  })
}
