import { Link, useParams } from 'react-router-dom'
import { ReactNode } from 'react'

import { STATE_OPTIONS } from 'utils/states'
import { Vendor } from 'types/domainModels/purchasedGoods'

import { useEditVendorContact, useVendor } from 'hooks/purchasedGoods/vendors'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Breadcrumbs from 'components/common/Breadcrumbs'
import InlineEditInput from 'components/common/InlineEditInput'
import InlineEditSelect from 'components/common/InlineEditSelect'
import LabeledField from 'components/common/LabeledField'

const VendorPage = (): JSX.Element => {
  const { id: vendorID } = useParams<{ id: string }>()

  const {
    data: vendor,
    error: loadVendorError,
    isError: hasLoadVendorError,
    isLoading: isLoadingVendor,
  } = useVendor({ vendorID })

  if (isLoadingVendor) {
    return <VendorLoadingSkeleton />
  }

  if (hasLoadVendorError || !vendor) {
    return (
      <div className="space-y-4">
        <APIErrorDisplay
          error={
            hasLoadVendorError
              ? loadVendorError
              : new Error('Unknown error loading vendor')
          }
        />
        <p>
          <Link className="text-sm text-blue" to="/vendors">
            Back to vendors
          </Link>
        </p>
      </div>
    )
  }

  return <VendorLoaded vendor={vendor} />
}

export default VendorPage

const VendorLoadingSkeleton = (): JSX.Element => {
  return (
    <>
      {/* Represents the title and breadcrumbs. */}
      <div className="space-y-2">
        <div className="flex items-center space-x-4">
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
        </div>
        <div className="h-8 w-1/4 animate-pulse bg-light-grey" />
      </div>
      {/* Represents the address labeled fields. */}
      <div className="mt-8 space-y-4">
        <div className="h-6 w-36 animate-pulse bg-light-grey" />
        <div className="flex space-x-8">
          {new Array(5).map((_unused, i) => {
            return (
              <div key={i} className="space-y-2">
                <div className="h-4 w-14 animate-pulse bg-light-grey" />
                <div className="h-4 w-28 animate-pulse bg-light-grey" />
              </div>
            )
          })}
        </div>
      </div>
      {/* Represents the contact information labeled fields. */}
      <div className="mt-8 space-y-4">
        <div className="h-6 w-36 animate-pulse bg-light-grey" />
        <div className="flex space-x-8">
          {new Array(2).map((_unused, i) => {
            return (
              <div key={i} className="space-y-2">
                <div className="h-4 w-14 animate-pulse bg-light-grey" />
                <div className="h-4 w-28 animate-pulse bg-light-grey" />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

const VendorLoaded = ({ vendor }: { vendor: Vendor }): JSX.Element => {
  return (
    <div>
      <div>
        <Breadcrumbs
          breadcrumbs={[
            { link: '..', text: 'Vendors' },
            {
              link: `../${vendor.id}`,
              text: vendor.name,
            },
          ]}
        />
        <h1 className="text-2xl">{vendor.name}</h1>
      </div>
      <div className="mt-4">
        <VendorAddressFields vendor={vendor} />
      </div>
      <div className="mt-4">
        <VendorContactFields vendor={vendor} />
      </div>
    </div>
  )
}

const LabeledFieldSection = ({
  children,
  id,
  title,
}: {
  children: ReactNode
  id: string
  title: string
}): JSX.Element => {
  const resolvedID = `${id}-labeled-field-section`

  return (
    <div>
      <h2 className="text-xl" id={resolvedID}>
        {title}
      </h2>
      <div aria-labelledby={resolvedID} className="mt-2">
        {children}
      </div>
    </div>
  )
}

const VendorAddressFields = ({ vendor }: { vendor: Vendor }): JSX.Element => {
  const { isLoading, mutateAsync: editVendorContact } = useEditVendorContact({
    vendorID: vendor.id,
  })

  const selectedState =
    STATE_OPTIONS.find(({ value }) => {
      return value === vendor.contact.address.state
    }) ?? null

  return (
    <LabeledFieldSection id="address" title="Address">
      <div className="flex space-x-8">
        <LabeledField label="Line 1">
          <InlineEditInput
            initialValue={`${vendor.contact.address.line1}`}
            isEditable={!isLoading}
            name="line1"
            onChange={(line1) => {
              return editVendorContact({ address: { line1 } })
            }}
          >
            {vendor.contact.address.line1 || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Line 2">
          <InlineEditInput
            initialValue={`${vendor.contact.address.line2}`}
            isEditable
            name="line2"
            onChange={(line2) => {
              return editVendorContact({ address: { line2 } })
            }}
          >
            {vendor.contact.address.line2 || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="City">
          <InlineEditInput
            initialValue={`${vendor.contact.address.city}`}
            isEditable
            name="city"
            onChange={(city) => {
              return editVendorContact({ address: { city } })
            }}
          >
            {vendor.contact.address.city || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="State">
          <InlineEditSelect
            initialValue={selectedState}
            isClearable
            isEditable
            name="state"
            onChange={(state) => {
              return editVendorContact({
                address: { state: state?.value ?? '' },
              })
            }}
            options={STATE_OPTIONS}
          >
            {selectedState?.label || '---'}
          </InlineEditSelect>
        </LabeledField>

        <LabeledField label="Zip">
          <InlineEditInput
            initialValue={`${vendor.contact.address.zip}`}
            isEditable
            name="zip"
            onChange={(zip) => {
              return editVendorContact({ address: { zip } })
            }}
          >
            {vendor.contact.address.zip || '---'}
          </InlineEditInput>
        </LabeledField>
      </div>
    </LabeledFieldSection>
  )
}

const VendorContactFields = ({ vendor }: { vendor: Vendor }): JSX.Element => {
  const { mutateAsync: editVendorContact } = useEditVendorContact({
    vendorID: vendor.id,
  })

  return (
    <LabeledFieldSection id="contact" title="Contact Information">
      <div className="flex space-x-8">
        <LabeledField label="Name">
          <InlineEditInput
            initialValue={`${vendor.contact.name}`}
            isEditable
            name="contact-name"
            onChange={(name) => {
              return editVendorContact({ name })
            }}
          >
            {vendor.contact.name || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Email">
          <InlineEditInput
            initialValue={`${vendor.contact.email}`}
            isEditable
            name="contact-email"
            onChange={(email) => {
              return editVendorContact({ email })
            }}
            type="email"
          >
            {vendor.contact.email || '---'}
          </InlineEditInput>
        </LabeledField>
      </div>
    </LabeledFieldSection>
  )
}
