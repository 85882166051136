import { clsx } from 'clsx'
import { ReactNode } from 'react'

import HoverHighlight from './HoverHighlight'

const InlineEditDisplay = ({
  children,
  editContent,
  editError,
  isEditable,
  isEditing,
  minWidthClass,
  onStartEdit,
}: {
  children: ReactNode
  editContent: ReactNode
  editError: string
  isEditable: boolean
  isEditing: boolean
  minWidthClass: string
  onStartEdit(): void
}): JSX.Element => {
  let childrenWrapper = children
  if (editError) {
    childrenWrapper = (
      <div>
        <div>{children}</div>
        <div className="text-xs text-red">Error: {editError}</div>
      </div>
    )
  }

  if (!isEditable) {
    return <div>{childrenWrapper}</div>
  }

  return (
    <div className="relative">
      {isEditing && (
        <div
          className={`absolute left-0 right-0 top-0 z-10 w-full bg-white p-1 shadow-lg ${minWidthClass}`}
        >
          {editContent}
        </div>
      )}

      <div
        className={clsx({
          // When we're editing, we just change the visibility of the underlying contents so the rest of the
          // UI doesn't shift. If we did a "display: none" here, the UI would shift.
          'invisible opacity-0': isEditing,
        })}
      >
        <HoverHighlight
          onActivate={() => {
            onStartEdit()
          }}
        >
          <div>{childrenWrapper}</div>
        </HoverHighlight>
      </div>
    </div>
  )
}

export default InlineEditDisplay
