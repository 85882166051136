import { clsx } from 'clsx'
import { NavLink } from 'react-router-dom'
import { ReactNode } from 'react'

function getTabClasses(isActive: boolean) {
  return clsx(
    'relative cursor-pointer rounded-t-md border border-b-0 py-1 px-4 no-underline',
    {
      'border-white': !isActive,
      'border-light-grey before:absolute before:left-0 before:right-0 before:-bottom-px before:block before:h-px before:bg-white':
        isActive,
    }
  )
}

const Tabs = ({
  children,
  title,
}: {
  children: ReactNode
  title?: string
}): JSX.Element => {
  const tabsContent = (
    <div className="flex items-center" role="tablist">
      {children}
    </div>
  )

  if (title) {
    return (
      <div className="flex items-center space-x-4">
        <span className="text-xs font-bold uppercase text-dark-grey">
          {title}
        </span>
        {tabsContent}
      </div>
    )
  }

  return tabsContent
}

export default Tabs

export const Tab = (
  props: { children: ReactNode } & (
    | { end?: boolean; to: string }
    | { isActive: boolean; onClick(): void }
  )
): JSX.Element => {
  const { children } = props

  if ('to' in props) {
    return (
      <NavLink
        className={({ isActive }) => {
          return getTabClasses(isActive)
        }}
        end={props.end}
        role="tab"
        to={props.to}
      >
        {children}
      </NavLink>
    )
  }

  return (
    <a
      className={getTabClasses(props.isActive)}
      onClick={props.onClick}
      role="tab"
    >
      {children}
    </a>
  )
}
