import { round } from 'lodash-es'

export function formatDollars(
  value: number,
  { valueUnit = 'dollar' }: { valueUnit?: 'cent' | 'dollar' } = {}
): string {
  const valueInDollars = valueUnit === 'cent' ? value / 100 : value

  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
  }).format(round(valueInDollars, 2))
}
