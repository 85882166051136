import { cloneDeep, findIndex } from 'lodash-es'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'

import {
  CreatePurchasedGood,
  createPurchasedGood,
  EditPurchasedGood,
  editPurchasedGood,
  GetPurchasedGoodsFilters,
  GetPurchasedGoodsResponse,
  getPurchasedGood,
  getPurchasedGoods,
} from 'services/purchasedGoods/purchasedGoods'
import { FacilityNetworkID } from 'types/domainModels/shared'
import {
  FacilitySKU,
  PurchasedGood,
  VendorSKU,
} from 'types/domainModels/purchasedGoods'

export function useCreatePurchasedGood(
  opts: UseMutationOptions<PurchasedGood, Error, CreatePurchasedGood['data']>
) {
  const { invalidatePurchasedGoods } = useInvalidatePurchasedGoods()

  return useMutation(
    async (data) => {
      return createPurchasedGood({ data })
    },
    {
      ...opts,
      onSuccess: (...args) => {
        opts.onSuccess?.(...args)

        invalidatePurchasedGoods()
      },
    }
  )
}

export function useEditPurchasedGood({
  purchasedGoodID,
  ...rest
}: { purchasedGoodID: string } & UseMutationOptions<
  PurchasedGood,
  Error,
  EditPurchasedGood['data']
>) {
  const { invalidatePurchasedGood } = useInvalidatePurchasedGood()

  return useMutation(
    async (data) => {
      return editPurchasedGood({ data, purchasedGoodID })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        rest.onSuccess?.(...args)

        invalidatePurchasedGood(purchasedGoodID, { newPurchasedGood: args[0] })
      },
    }
  )
}

export function useInvalidatePurchasedGood() {
  const queryClient = useQueryClient()

  return {
    invalidatePurchasedGood: (
      purchasedGoodID: string,
      {
        facilityNetworkID,
        newFacilitySKU,
        newPurchasedGood,
        newVendorSKU,
        vendorSKUID,
      }: {
        facilityNetworkID?: FacilityNetworkID
        newFacilitySKU?: FacilitySKU
        newPurchasedGood?: PurchasedGood
        newVendorSKU?: VendorSKU
        vendorSKUID?: string
      }
    ) => {
      if (newPurchasedGood) {
        queryClient.setQueryData<PurchasedGood | undefined>(
          ['purchased-goods', purchasedGoodID],
          () => {
            return newPurchasedGood
          }
        )
      }

      if (newVendorSKU) {
        queryClient.setQueryData<PurchasedGood | undefined>(
          ['purchased-goods', purchasedGoodID],
          (oldPurchasedGood) => {
            const newPurchasedGood = cloneDeep(oldPurchasedGood)

            if (!newPurchasedGood) {
              return undefined
            }

            const vendorSKUs = newPurchasedGood.vendorSKUs
            const vendorSKUIndex = findIndex(vendorSKUs, ({ id }) => {
              return id === newVendorSKU.id
            })

            if (vendorSKUIndex === -1) {
              vendorSKUs.push(newVendorSKU)
            } else {
              vendorSKUs[vendorSKUIndex] = newVendorSKU
            }

            return newPurchasedGood
          }
        )
      }

      if (newFacilitySKU) {
        queryClient.setQueryData<PurchasedGood | undefined>(
          ['purchased-goods', purchasedGoodID],
          (oldPurchasedGood) => {
            const newPurchasedGood = cloneDeep(oldPurchasedGood)

            if (!newPurchasedGood || !vendorSKUID || !facilityNetworkID) {
              return undefined
            }

            const vendorSKU = newPurchasedGood.vendorSKUs.find(
              ({ id }) => id === vendorSKUID
            )
            if (vendorSKU) {
              vendorSKU.facilitySKUs[facilityNetworkID] = {
                ...vendorSKU.facilitySKUs[facilityNetworkID],
                ...newFacilitySKU,
              }
            }

            return newPurchasedGood
          }
        )
      }

      queryClient.invalidateQueries(['purchased-goods', purchasedGoodID])
    },
  }
}

export function useInvalidatePurchasedGoods() {
  const queryClient = useQueryClient()

  return {
    invalidatePurchasedGoods: () => {
      queryClient.invalidateQueries(['purchased-goods'])
      queryClient.invalidateQueries(['purchased-goods-search'])
    },
  }
}

export function usePurchasedGood({
  purchasedGoodID,
  ...rest
}: {
  purchasedGoodID: string | undefined
} & Omit<
  UseQueryOptions<PurchasedGood, Error>,
  'enabled' | 'queryFn' | 'queryKey'
>) {
  return useQuery<PurchasedGood, Error>({
    ...rest,
    enabled: !!purchasedGoodID,
    queryFn: () => {
      if (!purchasedGoodID) {
        throw new Error('Could not fetch purchased good without an ID.')
      }

      return getPurchasedGood({ purchasedGoodID })
    },
    queryKey: ['purchased-goods', purchasedGoodID],
  })
}

export function usePurchasedGoods({
  filters,
  limit,
  ...rest
}: {
  filters: Partial<Record<GetPurchasedGoodsFilters, string>>
  limit: number
} & Omit<
  UseInfiniteQueryOptions<GetPurchasedGoodsResponse, Error>,
  'getNextPageParam' | 'queryFn' | 'queryKey'
>) {
  return useInfiniteQuery<GetPurchasedGoodsResponse, Error>({
    ...rest,
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor
    },
    queryFn: ({ pageParam: nextCursor }) => {
      return getPurchasedGoods({
        category: filters.category,
        cursor: nextCursor,
        limit,
        nameLike: filters.nameLike,
      })
    },
    queryKey: ['purchased-goods', limit, filters],
  })
}

export function useSearchPurchasedGoods({
  limit,
  query,
  ...rest
}: {
  limit: number
  query: string
} & Omit<
  UseInfiniteQueryOptions<GetPurchasedGoodsResponse, Error>,
  'enabled' | 'getNextPageParam' | 'queryFn' | 'queryKey'
>) {
  return useInfiniteQuery<GetPurchasedGoodsResponse, Error>({
    ...rest,
    enabled: !!query,
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor
    },
    queryFn: ({ pageParam: nextCursor }) => {
      return getPurchasedGoods({
        cursor: nextCursor,
        limit,
        nameLike: query,
      })
    },
    queryKey: ['purchased-goods-search', limit, query],
  })
}
