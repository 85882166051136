import { SVGAttributes } from 'react'

const MinusCircleIcon = ({
  strokeWidth = 2,
  ...rest
}: {
  strokeWidth?: number
} & Omit<
  SVGAttributes<SVGElement>,
  'fill' | 'stroke' | 'viewBox' | 'xmlns'
>): JSX.Element => {
  return (
    <svg
      {...rest}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export default MinusCircleIcon
