import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

const Breadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: {
    link: string
    text: string
  }[]
}): JSX.Element => {
  return (
    <nav aria-label="Breadcrumb">
      <ul className="space-x-2 text-xs uppercase text-grey">
        {breadcrumbs.map(({ link, text }, i) => {
          return (
            <Fragment key={i}>
              {i > 0 && <span>{'>'}</span>}

              <NavLink
                className="text-grey"
                // The last breadcrumb should be the active link. We apply end={true} to previous
                // breadcrumbs so we don't match them as active due to nesting.
                end={i !== breadcrumbs.length - 1}
                to={link}
              >
                {text}
              </NavLink>
            </Fragment>
          )
        })}
      </ul>
    </nav>
  )
}

export default Breadcrumbs
