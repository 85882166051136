import { orderBy } from 'lodash-es'

import { ReactSelectValue } from 'types/internal'

export function getTCSZoneOptions(
  tcsZones: string[]
): ReactSelectValue<string>[] {
  const orderedTCSZones = orderBy(tcsZones)

  return orderedTCSZones.map((tcsZone) => {
    return { label: tcsZone, value: tcsZone }
  })
}
