import { clsx } from 'clsx'
import { orderBy } from 'lodash-es'
import { ReactNode } from 'react'

import { Draft } from 'types/domainModels/recipes'
import { getLineItemsFromDrafts, getOrderedDrafts } from 'utils/drafts'

import Button from 'components/common/Button'
import Modal, { ModalBody, ModalHeader } from 'components/common/Modal'

const DraftEvolutionModal = ({
  drafts,
  onCloseModal,
}: {
  drafts: Draft[]
  onCloseModal(): void
}): JSX.Element => {
  const orderedDrafts = getOrderedDrafts(drafts)

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="max-w-full">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>
              Draft Evolution
            </ModalHeader>
          </div>
          <div className="space-y-4">
            <RecipeComparison drafts={orderedDrafts} />
            <NotesComparison drafts={orderedDrafts} />
          </div>
          <div className="mt-8 flex justify-end">
            <div className="w-24">
              <Button onClick={onCloseModal}>Done</Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DraftEvolutionModal

const RecipeComparison = ({ drafts }: { drafts: Draft[] }): JSX.Element => {
  const lineItems = getLineItemsFromDrafts(drafts)
  const orderedLineItems = orderBy(lineItems, 'name', 'asc')

  return (
    <div>
      <h2 className="mb-2 text-lg">Recipe</h2>
      <div className="overflow-auto text-sm">
        <div className="flex">
          <FirstColumnCell isHeader>
            <HeaderStyle>Line Item</HeaderStyle>
          </FirstColumnCell>
          {drafts.map(({ draftNumber }) => {
            return (
              <LaterColumnCell key={draftNumber} isHeader>
                <HeaderStyle>Draft {draftNumber} Weight (G)</HeaderStyle>
              </LaterColumnCell>
            )
          })}
        </div>
        {orderedLineItems.map(({ id, name }) => {
          return (
            <div key={id} className="flex">
              <FirstColumnCell>{name}</FirstColumnCell>
              {drafts.map(({ lineItems }, i) => {
                const matchingLineItem = lineItems.find(
                  (lineItem) => lineItem.id === id
                )

                return (
                  <LaterColumnCell key={i}>
                    {matchingLineItem?.quantityGrams ?? '---'}
                  </LaterColumnCell>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const NotesComparison = ({ drafts }: { drafts: Draft[] }): JSX.Element => {
  const methodNotes: string[] = []
  const authorTasteNotes: string[] = []

  drafts.forEach((draft) => {
    methodNotes.push(draft.methodNotes)
    authorTasteNotes.push(draft.authorTasteNotes)
  })

  return (
    <div>
      <h2 className="mb-2 text-lg">Notes</h2>
      <div className="overflow-auto text-sm">
        <div className="flex">
          <FirstColumnCell isHeader />
          {drafts.map(({ draftNumber }) => {
            return (
              <LaterColumnCell key={draftNumber} isHeader>
                <HeaderStyle>Draft {draftNumber}</HeaderStyle>
              </LaterColumnCell>
            )
          })}
        </div>
        <div className="flex">
          <FirstColumnCell>
            <HeaderStyle>Method Notes</HeaderStyle>
          </FirstColumnCell>
          {methodNotes.map((notes, i) => {
            return <LaterColumnCell key={i}>{notes}</LaterColumnCell>
          })}
        </div>
        <div className="flex">
          <FirstColumnCell>
            <HeaderStyle>Author Taste Notes</HeaderStyle>
          </FirstColumnCell>
          {authorTasteNotes.map((notes, i) => {
            return <LaterColumnCell key={i}>{notes}</LaterColumnCell>
          })}
        </div>
      </div>
    </div>
  )
}

const HeaderStyle = ({ children }: { children: ReactNode }): JSX.Element => {
  return <span className="text-xs uppercase text-dark-grey">{children}</span>
}

const FirstColumnCell = ({
  children,
  isHeader = false,
}: {
  children?: ReactNode
  isHeader?: boolean
}): JSX.Element => {
  return (
    <div
      className={clsx('w-48 flex-shrink-0 border-b border-light-grey', {
        'pb-2': isHeader,
        'py-4': !isHeader,
      })}
    >
      {children}
    </div>
  )
}

const LaterColumnCell = ({
  children,
  isHeader = false,
}: {
  children?: ReactNode
  isHeader?: boolean
}): JSX.Element => {
  return (
    <div
      className={clsx(
        'w-36 flex-shrink-0 border-b border-light-grey text-center',
        {
          'pb-2': isHeader,
          'py-4': !isHeader,
        }
      )}
    >
      {children}
    </div>
  )
}
