import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useState } from 'react'

import { Draft, IngredientRecipe } from 'types/domainModels/recipes'
import { getOrderedDrafts } from 'utils/drafts'

import { usePartVersions } from 'hooks/recipes/parts'
import Breadcrumbs from 'components/common/Breadcrumbs'
import Button from 'components/common/Button'
import DraftEvolutionModal from './DraftEvolutionModal'
import EditablePartName from './EditablePartName'
import ErrorDisplay from 'components/common/ErrorDisplay'
import Label from 'components/common/Label'
import NewDraftModal from './NewDraftModal'
import PartDraft from './PartDraft'
import StarIcon from 'components/common/icons/StarIcon'
import Tabs, { Tab } from 'components/common/Tabs'
import Tooltip from 'components/common/Tooltip'

const PartDrafts = ({ part }: { part: IngredientRecipe }): JSX.Element => {
  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            link: '/parts',
            text: 'Parts',
          },
          { link: `/parts/${part.id}/versions`, text: part.name },
          { link: `/parts/${part.id}/drafts`, text: 'Drafts' },
        ]}
      />

      <div className="mb-4 mt-1 space-y-2">
        <div className="max-w-xs">
          <EditablePartName part={part} />
        </div>
        <div className="flex items-center space-x-4 text-sm">
          <Label>Part ID</Label>
          <div>{part.id}</div>
        </div>
      </div>

      <Routes>
        <Route element={<PartDraftSelected part={part} />} path=":draftID" />
        <Route element={<PartDraftsAutoSelect part={part} />} index />
      </Routes>
    </>
  )
}

export default PartDrafts

const PartDraftsAutoSelect = ({
  part,
}: {
  part: IngredientRecipe
}): JSX.Element => {
  const orderedDrafts = getOrderedDrafts(part.recipe.drafts ?? [])
  const firstDraftID = orderedDrafts[0]?.id

  if (firstDraftID) {
    return <Navigate replace to={firstDraftID} />
  }

  return (
    <ErrorDisplay
      message={`${part.name} does not have any drafts. Please contact support.`}
    />
  )
}

const PartDraftSelected = ({
  part,
}: {
  part: IngredientRecipe
}): JSX.Element => {
  const { draftID } = useParams()

  const drafts = part.recipe.drafts ?? []
  const orderedDrafts = getOrderedDrafts(drafts)

  const activeDraft = drafts.find(({ id }) => {
    return id === draftID
  })

  return (
    <>
      <PartDraftTabs drafts={orderedDrafts} part={part} />

      <div className="mt-4">
        {activeDraft ? (
          <PartDraft
            key={activeDraft.id}
            draft={activeDraft}
            partID={part.id}
          />
        ) : (
          <ErrorDisplay
            message={`Could not find a draft with ID: ${draftID}`}
          />
        )}
      </div>
    </>
  )
}

const PartDraftTabs = ({
  drafts,
  part,
}: {
  drafts: Draft[]
  part: IngredientRecipe
}): JSX.Element => {
  const navigate = useNavigate()

  const [isCreateNewDraftModalOpen, setIsCreateNewDraftModalOpen] =
    useState(false)

  const { data: getVersionsResponse } = usePartVersions({ partID: part.id })
  const versions = getVersionsResponse?.versions ?? []

  return (
    // The right padding here (pr-36) is to make room for the "+ New Draft" button.
    <div className="relative max-w-full border-b border-light-grey pr-36 text-sm">
      <Tabs title="Drafts">
        {drafts.map((draft) => {
          const linkedVersion = versions.find((version) => {
            return version.draftID === draft.id
          })

          return (
            <Tab key={draft.id} to={`../${draft.id}`}>
              <div className="flex items-center space-x-2">
                <span>{draft.draftNumber}</span>
                {linkedVersion && (
                  <Tooltip
                    trigger={
                      <div
                        aria-label="Draft promoted"
                        className="h-4 w-4 text-orange"
                      >
                        <StarIcon />
                      </div>
                    }
                  >
                    <div className="p-2 text-sm">
                      Promoted to version #{linkedVersion.versionNumber}
                    </div>
                  </Tooltip>
                )}
              </div>
            </Tab>
          )
        })}
        <div className="ml-4">
          <ViewDraftEvolution drafts={drafts} />
        </div>
      </Tabs>
      <div className="absolute bottom-2 right-0 w-32">
        <Button
          onClick={() => {
            setIsCreateNewDraftModalOpen(true)
          }}
        >
          + New Draft
        </Button>

        {isCreateNewDraftModalOpen && (
          <NewDraftModal
            existingDrafts={drafts}
            onCloseModal={() => {
              setIsCreateNewDraftModalOpen(false)
            }}
            onNewDraftCreated={(newDraft) => {
              setIsCreateNewDraftModalOpen(false)

              navigate(`../${newDraft.id}`)
            }}
            part={part}
          />
        )}
      </div>
    </div>
  )
}

const ViewDraftEvolution = ({
  drafts,
}: {
  drafts: Draft[]
}): JSX.Element | null => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (drafts.length <= 1) {
    return null
  }

  return (
    <>
      <a
        className="cursor-pointer whitespace-nowrap text-xs uppercase text-blue"
        onClick={() => {
          setIsModalOpen(true)
        }}
        role="link"
      >
        View Draft Evolution
      </a>

      {isModalOpen && (
        <DraftEvolutionModal
          drafts={drafts}
          onCloseModal={() => {
            setIsModalOpen(false)
          }}
        />
      )}
    </>
  )
}
