import { IngredientRecipe, Recipe } from 'types/domainModels/recipes'

export function convertRecipeToIngredient(recipe: Recipe): IngredientRecipe {
  return {
    id: recipe.id,
    name: recipe.name,
    purchasedGood: null,
    recipe: { drafts: recipe.drafts },
  }
}
