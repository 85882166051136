import { Link, useParams } from 'react-router-dom'
import { ReactNode } from 'react'
import { round } from 'lodash-es'

import { formatDollars } from 'utils/currency'
import { PurchasedGood, VendorSKU } from 'types/domainModels/purchasedGoods'
import { RECEIVING_UNIT_OPTIONS } from 'utils/vendorSKUs'

import { useEditFacilitySKU } from 'hooks/purchasedGoods/facilitySKUs'
import { useEditVendorSKU } from 'hooks/purchasedGoods/vendorSKUs'
import { usePurchasedGood } from 'hooks/purchasedGoods/purchasedGoods'
import { useVendor } from 'hooks/purchasedGoods/vendors'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Breadcrumbs from 'components/common/Breadcrumbs'
import CheckOrEmpty from './CheckOrEmpty'
import InlineEditCheckbox from 'components/common/InlineEditCheckbox'
import InlineEditInput from 'components/common/InlineEditInput'
import InlineEditSelect from 'components/common/InlineEditSelect'
import InlineEditTextarea from 'components/common/InlineEditTextarea'
import Label from 'components/common/Label'
import LabeledField from 'components/common/LabeledField'

const VendorSKUPage = (): JSX.Element => {
  const { purchasedGoodID, vendorSKUID } = useParams<{
    purchasedGoodID: string
    vendorSKUID: string
  }>()

  const {
    data: purchasedGood,
    error: loadPurchasedGoodError,
    isError: hasLoadPurchasedGoodError,
    isLoading: isLoadingPurchasedGood,
  } = usePurchasedGood({ purchasedGoodID })

  const vendorSKU = purchasedGood?.vendorSKUs.find(({ id }) => {
    return id === vendorSKUID
  })

  if (isLoadingPurchasedGood) {
    return <PurchasedGoodLoadingSkeleton />
  }

  if (hasLoadPurchasedGoodError || !purchasedGood || !vendorSKU) {
    return (
      <div className="space-y-4">
        <APIErrorDisplay
          error={
            hasLoadPurchasedGoodError
              ? loadPurchasedGoodError
              : new Error('Unknown error loading vendor SKU')
          }
        />
        <p>
          <Link className="text-sm text-blue" to="/purchased-goods">
            Back to purchased goods
          </Link>
        </p>
      </div>
    )
  }

  return <VendorSKULoaded purchasedGood={purchasedGood} vendorSKU={vendorSKU} />
}

export default VendorSKUPage

const PurchasedGoodLoadingSkeleton = (): JSX.Element => {
  return (
    <>
      {/* Represents the title and breadcrumbs. */}
      <div className="space-y-2">
        <div className="flex items-center space-x-4">
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
          <div className="h-4 w-14 animate-pulse bg-light-grey" />
        </div>
        <div className="h-8 w-1/4 animate-pulse bg-light-grey" />
      </div>
      {/* Represents the "Packaging" fields. */}
      <div className="mt-8 space-y-4">
        <div className="h-6 w-48 animate-pulse bg-light-grey" />
        <div className="flex space-x-8">
          {new Array(7).map((_unused, i) => {
            return (
              <div key={i} className="space-y-2">
                <div className="h-4 w-14 animate-pulse bg-light-grey" />
                <div className="h-4 w-28 animate-pulse bg-light-grey" />
              </div>
            )
          })}
        </div>
      </div>
      {/* Represents the "Facility Networks" fields. */}
      <div className="mt-8 w-96 space-y-4">
        <div className="h-6 w-48 animate-pulse bg-light-grey" />
        <div className="grid grid-cols-3 gap-4">
          <div />
          {new Array(14).map((_unused, i) => {
            return (
              <div key={i} className="h-4 w-14 animate-pulse bg-light-grey" />
            )
          })}
        </div>
      </div>
      {/* Represents the "Miscellaneous" fields. */}
      <div className="mt-8 space-y-4">
        <div className="h-6 w-48 animate-pulse bg-light-grey" />
        <div className="flex space-x-8">
          {new Array(2).map((_unused, i) => {
            return (
              <div key={i} className="space-y-2">
                <div className="h-4 w-14 animate-pulse bg-light-grey" />
                <div className="h-4 w-28 animate-pulse bg-light-grey" />
              </div>
            )
          })}
        </div>
      </div>
      {/* Represents the "Notes" fields. */}
      <div className="mt-8 space-y-4">
        <div className="h-6 w-48 animate-pulse bg-light-grey" />
        <div className="flex space-x-8">
          {new Array(2).map((_unused, i) => {
            return (
              <div key={i} className="space-y-2">
                <div className="h-4 w-14 animate-pulse bg-light-grey" />
                <div className="h-4 w-28 animate-pulse bg-light-grey" />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

const VendorSKULoaded = ({
  purchasedGood,
  vendorSKU,
}: {
  purchasedGood: PurchasedGood
  vendorSKU: VendorSKU
}): JSX.Element => {
  const { data: vendor } = useVendor({ vendorID: vendorSKU.vendorID })

  const title = vendor ? `${vendorSKU.sku} - ${vendor.name}` : vendorSKU.sku

  return (
    <div>
      <div>
        <Breadcrumbs
          breadcrumbs={[
            { link: '..', text: 'Purchased Goods' },
            {
              link: `../${purchasedGood.id}`,
              text: purchasedGood.ingredientName,
            },
            {
              link: `../${vendorSKU.id}`,
              text: title,
            },
          ]}
        />
        <h1 className="text-2xl">{title}</h1>
      </div>
      <div className="mt-4 space-y-8">
        <PackagingFields purchasedGood={purchasedGood} vendorSKU={vendorSKU} />
        <FacilityNetworksFields
          purchasedGood={purchasedGood}
          vendorSKU={vendorSKU}
        />
        <MiscellaneousFields
          purchasedGood={purchasedGood}
          vendorSKU={vendorSKU}
        />
        <NotesFields purchasedGood={purchasedGood} vendorSKU={vendorSKU} />
      </div>
    </div>
  )
}

const LabeledFieldSection = ({
  children,
  id,
  title,
}: {
  children: ReactNode
  id: string
  title: string
}): JSX.Element => {
  const resolvedID = `${id}-labeled-field-section`

  return (
    <div>
      <h2 className="text-xl" id={resolvedID}>
        {title}
      </h2>
      <div aria-labelledby={resolvedID} className="mt-2">
        {children}
      </div>
    </div>
  )
}

const PackagingFields = ({
  purchasedGood,
  vendorSKU,
}: {
  purchasedGood: PurchasedGood
  vendorSKU: VendorSKU
}): JSX.Element => {
  const { mutateAsync: editVendorSKU } = useEditVendorSKU({
    purchasedGoodID: purchasedGood.id,
    vendorSKUID: vendorSKU.id,
  })

  return (
    <LabeledFieldSection id="packaging" title="Packaging">
      <div className="flex space-x-8">
        <LabeledField label="Packs / Case">
          <InlineEditInput
            initialValue={`${vendorSKU.packsPerCase}`}
            isEditable
            name="packs-per-case"
            onChange={(packsPerCase) => {
              return editVendorSKU({ packsPerCase: Number(packsPerCase) })
            }}
            type="number"
          >
            {vendorSKU.packsPerCase || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Pack Size">
          <InlineEditInput
            initialValue={`${vendorSKU.packSize}`}
            isEditable
            name="pack-size"
            onChange={(packSize) => {
              return editVendorSKU({ packSize: Number(packSize) })
            }}
            type="number"
          >
            {vendorSKU.packSize || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Pack UoM">
          <InlineEditInput
            initialValue={`${vendorSKU.packUOM}`}
            isEditable
            name="pack-uom"
            onChange={(packUoM) => {
              return editVendorSKU({ packUOM: packUoM })
            }}
          >
            {vendorSKU.packUOM || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Pack Weight (LBS)">
          <InlineEditInput
            initialValue={`${vendorSKU.packWeightLbs}`}
            isEditable
            name="packs-weight-lbs"
            onChange={(packWeightLbs) => {
              return editVendorSKU({ packWeightLbs: Number(packWeightLbs) })
            }}
            type="number"
          >
            {vendorSKU.packWeightLbs || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Case Weight (LBS)">
          <InlineEditInput
            initialValue={`${vendorSKU.caseWeightLbs}`}
            isEditable
            name="case-weight-lbs"
            onChange={(caseWeightLbs) => {
              return editVendorSKU({ caseWeightLbs: Number(caseWeightLbs) })
            }}
            type="number"
          >
            {vendorSKU.caseWeightLbs || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Pallet Configuration">
          <InlineEditInput
            initialValue={`${vendorSKU.palletConfiguration}`}
            isEditable
            name="pallet-configuration"
            onChange={(palletConfiguration) => {
              return editVendorSKU({ palletConfiguration })
            }}
          >
            {vendorSKU.palletConfiguration || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Receiving Unit">
          <InlineEditSelect
            initialValue={
              RECEIVING_UNIT_OPTIONS.find(({ value }) => {
                return value === vendorSKU.receivingUnit
              }) ?? null
            }
            isEditable
            name="receiving-unit"
            onChange={(receivingUnit) => {
              if (!receivingUnit) {
                throw new Error('Receiving unit is required')
              }

              return editVendorSKU({ receivingUnit: receivingUnit.value })
            }}
            options={RECEIVING_UNIT_OPTIONS}
          >
            {vendorSKU.receivingUnit ?? '---'}
          </InlineEditSelect>
        </LabeledField>
      </div>
    </LabeledFieldSection>
  )
}

const FacilityNetworksFields = ({
  purchasedGood,
  vendorSKU,
}: {
  purchasedGood: PurchasedGood
  vendorSKU: VendorSKU
}): JSX.Element => {
  const { mutateAsync: editChicagoFacilitySKU } = useEditFacilitySKU({
    facilityNetworkID: 'chicago',
    purchasedGoodID: purchasedGood.id,
    vendorSKUID: vendorSKU.id,
  })

  const { mutateAsync: editSLCFacilitySKU } = useEditFacilitySKU({
    facilityNetworkID: 'slc',
    purchasedGoodID: purchasedGood.id,
    vendorSKUID: vendorSKU.id,
  })

  const chicagoSKU = vendorSKU.facilitySKUs.chicago
  const slcSKU = vendorSKU.facilitySKUs.slc

  return (
    <LabeledFieldSection id="facility-networks" title="Facility Networks">
      <div className="grid w-80 grid-cols-3 justify-items-center gap-2">
        <div />
        <div>
          <Label>Chicago</Label>
        </div>
        <div>
          <Label>SLC</Label>
        </div>

        <div className="justify-self-start">
          <Label>Cost / LB</Label>
        </div>
        <div>
          <InlineEditInput
            initialValue={`${chicagoSKU.costPerPoundCents / 100}`}
            isEditable
            name="chicago-cost-per-lb"
            onChange={(costPerPound) => {
              return editChicagoFacilitySKU({
                costPerPoundCents: round(Number(costPerPound) * 100),
              })
            }}
            type="number"
          >
            {chicagoSKU.costPerPoundCents
              ? formatDollars(chicagoSKU.costPerPoundCents, {
                  valueUnit: 'cent',
                })
              : '---'}
          </InlineEditInput>
        </div>
        <div>
          <InlineEditInput
            initialValue={`${slcSKU.costPerPoundCents / 100}`}
            isEditable
            name="slc-cost-per-lb"
            onChange={(costPerPound) => {
              return editSLCFacilitySKU({
                costPerPoundCents: round(Number(costPerPound) * 100),
              })
            }}
            type="number"
          >
            {slcSKU.costPerPoundCents
              ? formatDollars(slcSKU.costPerPoundCents, { valueUnit: 'cent' })
              : '---'}
          </InlineEditInput>
        </div>

        <div className="justify-self-start">
          <Label>Cost / Case</Label>
        </div>
        <div>
          <InlineEditInput
            initialValue={`${chicagoSKU.costPerCaseCents / 100}`}
            isEditable
            name="chicago-cost-per-case"
            onChange={(costPerCase) => {
              return editChicagoFacilitySKU({
                costPerCaseCents: round(Number(costPerCase) * 100),
              })
            }}
            type="number"
          >
            {chicagoSKU.costPerCaseCents
              ? formatDollars(chicagoSKU.costPerCaseCents, {
                  valueUnit: 'cent',
                })
              : '---'}
          </InlineEditInput>
        </div>
        <div>
          <InlineEditInput
            initialValue={`${slcSKU.costPerCaseCents / 100}`}
            isEditable
            name="slc-cost-per-case"
            onChange={(costPerCase) => {
              return editSLCFacilitySKU({
                costPerCaseCents: round(Number(costPerCase) * 100),
              })
            }}
            type="number"
          >
            {slcSKU.costPerCaseCents
              ? formatDollars(slcSKU.costPerCaseCents, { valueUnit: 'cent' })
              : '---'}
          </InlineEditInput>
        </div>

        <div className="justify-self-start">
          <Label>Available</Label>
        </div>
        <div>
          <InlineEditCheckbox
            initialValue={chicagoSKU.isAvailable}
            isEditable
            label="Is available?"
            name="chicage-available"
            onChange={(isAvailable) => {
              return editChicagoFacilitySKU({ isAvailable })
            }}
          >
            <CheckOrEmpty
              falsyValue="---"
              label="Chicago available"
              predicate={chicagoSKU.isAvailable}
            />
          </InlineEditCheckbox>
        </div>
        <div>
          <InlineEditCheckbox
            initialValue={slcSKU.isAvailable}
            isEditable
            label="Is available?"
            name="slc-available"
            onChange={(isAvailable) => {
              return editSLCFacilitySKU({ isAvailable })
            }}
          >
            <CheckOrEmpty
              falsyValue="---"
              label="SLC available"
              predicate={slcSKU.isAvailable}
            />
          </InlineEditCheckbox>
        </div>

        <div className="justify-self-start">
          <Label>Primary</Label>
        </div>
        <div>
          <InlineEditCheckbox
            initialValue={chicagoSKU.isPrimary}
            isEditable
            label="Is primary?"
            name="chicage-primary"
            onChange={(isPrimary) => {
              return editChicagoFacilitySKU({ isPrimary })
            }}
          >
            <CheckOrEmpty
              falsyValue="---"
              label="Chicago primary"
              predicate={chicagoSKU.isPrimary}
            />
          </InlineEditCheckbox>
        </div>
        <div>
          <InlineEditCheckbox
            initialValue={slcSKU.isPrimary}
            isEditable
            label="Is primary?"
            name="slc-primary"
            onChange={(isPrimary) => {
              return editSLCFacilitySKU({ isPrimary })
            }}
          >
            <CheckOrEmpty
              falsyValue="---"
              label="SLC primary"
              predicate={slcSKU.isPrimary}
            />
          </InlineEditCheckbox>
        </div>
      </div>
    </LabeledFieldSection>
  )
}

const MiscellaneousFields = ({
  purchasedGood,
  vendorSKU,
}: {
  purchasedGood: PurchasedGood
  vendorSKU: VendorSKU
}): JSX.Element => {
  const { mutateAsync: editVendorSKU } = useEditVendorSKU({
    purchasedGoodID: purchasedGood.id,
    vendorSKUID: vendorSKU.id,
  })

  return (
    <LabeledFieldSection id="miscellaneous" title="Miscellaneous">
      <div className="flex space-x-8">
        <LabeledField label="Lead Time (Days)">
          <InlineEditInput
            initialValue={`${vendorSKU.leadTime}`}
            isEditable
            name="lead-time"
            onChange={(leadTime) => {
              return editVendorSKU({ leadTime: Number(leadTime) })
            }}
            type="number"
          >
            {vendorSKU.leadTime || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Min Order Qty (LBS)">
          <InlineEditInput
            initialValue={`${vendorSKU.minimumOrderQty}`}
            isEditable
            name="min-order-qty-lbs"
            onChange={(minimumOrderQty) => {
              return editVendorSKU({ minimumOrderQty: Number(minimumOrderQty) })
            }}
            type="number"
          >
            {vendorSKU.minimumOrderQty || '---'}
          </InlineEditInput>
        </LabeledField>

        <LabeledField label="Shelf Life (Days)">
          <InlineEditInput
            initialValue={`${vendorSKU.shelfLifeDays}`}
            isEditable
            name="shelf-life-days"
            onChange={(shelfLifeDays) => {
              return editVendorSKU({ shelfLifeDays: Number(shelfLifeDays) })
            }}
            type="number"
          >
            {vendorSKU.shelfLifeDays || '---'}
          </InlineEditInput>
        </LabeledField>
      </div>
    </LabeledFieldSection>
  )
}

const NotesFields = ({
  purchasedGood,
  vendorSKU,
}: {
  purchasedGood: PurchasedGood
  vendorSKU: VendorSKU
}): JSX.Element => {
  const { mutateAsync: editVendorSKU } = useEditVendorSKU({
    purchasedGoodID: purchasedGood.id,
    vendorSKUID: vendorSKU.id,
  })

  return (
    <LabeledFieldSection id="notes" title="Notes">
      <div className="flex space-x-8">
        <div className="max-w-[300px]">
          <LabeledField label="Vendor Description (For P.O.'s)">
            <InlineEditTextarea
              initialValue={`${vendorSKU.skuDescription}`}
              isEditable
              name="vendor-description"
              onChange={(skuDescription) => {
                return editVendorSKU({ skuDescription })
              }}
            >
              {vendorSKU.skuDescription || '---'}
            </InlineEditTextarea>
          </LabeledField>
        </div>
        <div className="max-w-[300px]">
          <LabeledField label="SKU Notes">
            <InlineEditTextarea
              initialValue={`${vendorSKU.notes}`}
              isEditable
              name="notes"
              onChange={(notes) => {
                return editVendorSKU({ notes })
              }}
            >
              {vendorSKU.notes || '---'}
            </InlineEditTextarea>
          </LabeledField>
        </div>
      </div>
    </LabeledFieldSection>
  )
}
