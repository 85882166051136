import { flatMap, isEmpty } from 'lodash-es'
import { Formik, FormikErrors, Form, useFormikContext } from 'formik'

import { getVendorOptions } from 'utils/vendors'
import { ReactSelectValue } from 'types/internal'
import { VendorSKU } from 'types/domainModels/purchasedGoods'

import { useCreateVendorSKU } from 'hooks/purchasedGoods/vendorSKUs'
import { useVendors } from 'hooks/purchasedGoods/vendors'
import FormInput from 'components/common/FormInput'
import FormSelect from 'components/common/FormSelect'
import Modal, {
  ModalBody,
  ModalButtons,
  ModalHeader,
} from 'components/common/Modal'

interface FormData {
  sku: string
  vendor: ReactSelectValue<string> | null
}

interface ValidatedData extends FormData {
  vendor: ReactSelectValue<string>
}

export interface Props {
  onCloseModal(): void
  onCreateVendorSKU(vendorSKU: VendorSKU): void
  purchasedGoodID: string
}

const validateFormData = ({
  formData,
}: {
  formData: FormData
}): FormikErrors<FormData> => {
  const errors: FormikErrors<FormData> = {}

  if (!formData.vendor) {
    errors.vendor = 'Please select a vendor.'
  }

  if (!formData.sku) {
    errors.sku = 'Please enter a sku.'
  }

  return errors
}

const CreateVendorSKUModal = ({
  onCloseModal,
  onCreateVendorSKU,
  purchasedGoodID,
}: Props): JSX.Element => {
  const {
    error: createVendorSKUError,
    isError: hasCreateVendorSKUError,
    isLoading: isCreatingVendorSKU,
    mutate: createVendorSKU,
  } = useCreateVendorSKU({
    onSuccess: (vendorSKU) => {
      onCreateVendorSKU(vendorSKU)
    },
    purchasedGoodID,
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="w-create-vendor-sku-modal">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>
              Create Vendor SKU
            </ModalHeader>
          </div>

          <Formik<FormData>
            initialValues={{
              sku: '',
              vendor: null,
            }}
            onSubmit={(values) => {
              const validatedData = values as ValidatedData

              return createVendorSKU({
                sku: validatedData.sku,
                vendorID: validatedData.vendor.value,
              })
            }}
            validate={(formData) => {
              return validateFormData({ formData })
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
          >
            <Form>
              <CreateVendorSKUForm
                createError={
                  hasCreateVendorSKUError ? createVendorSKUError : null
                }
                isCreating={isCreatingVendorSKU}
                onCancel={onCloseModal}
              />
            </Form>
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CreateVendorSKUModal

const CreateVendorSKUForm = ({
  createError,
  isCreating,
  onCancel,
}: {
  createError: Error | null
  isCreating: boolean
  onCancel(): void
}): JSX.Element => {
  const { errors } = useFormikContext<FormData>()

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <VendorSelect />
        <FormInput id="sku" label="SKU" labelFor="sku" name="sku" />
      </div>

      <div className="mt-8">
        <ModalButtons
          apiError={createError}
          hasValidationErrors={!isEmpty(errors)}
          isLoading={isCreating}
          mainActionBtnText="Create"
          onCancel={onCancel}
        />
      </div>
    </>
  )
}

const VendorSelect = (): JSX.Element => {
  const {
    data: getVendorsResult,
    error: loadVendorsError,
    fetchNextPage,
    hasNextPage,
    isError: hasLoadVendorsError,
    isLoading: isLoadingVendors,
  } = useVendors({ limit: 100, refetchOnWindowFocus: false })

  const vendors = flatMap(getVendorsResult?.pages, (page) => {
    return page.vendors
  })
  const vendorOptions = getVendorOptions(vendors)

  return (
    <FormSelect
      inputId="vendor"
      isLoading={isLoadingVendors}
      label="Vendor"
      labelFor="vendor"
      loadError={hasLoadVendorsError ? loadVendorsError.message : undefined}
      name="vendor"
      onInfiniteLoadTriggered={
        hasNextPage
          ? () => {
              fetchNextPage()
            }
          : undefined
      }
      options={vendorOptions}
    />
  )
}
