import { compact, every, some } from 'lodash-es'
import {
  useMutation,
  UseMutationOptions,
  useQueries,
  useQuery,
  useQueryClient,
} from 'react-query'

import { ActiveVersions, Draft, Version } from 'types/domainModels/recipes'
import {
  createVersion,
  getActiveVersions,
  editVersion,
  EditVersion,
  setActiveVersions,
} from 'services/recipes/versions'
import { FacilityNetworkID } from 'types/domainModels/shared'
import { getLineItemCostSums } from 'utils/drafts'
import { VersionApprovalStatus } from 'types/internal'

import { getRecipeProcurabilityQueryObj } from './recipes'
import { useDraft, useLineItemsCosts } from './drafts'
import { useInvalidatePart, useInvalidatePartVersions } from './parts'
import { useProcedure } from './procedures'

export function useApprovalStatus({ version }: { version: Version }): {
  approvalStatus: VersionApprovalStatus | null
  isLoading: boolean
} {
  const { draftID, recipeID } = version

  const { data: draft, isLoading: isLoadingDraft } = useDraft({
    draftID,
    recipeID,
  })
  const { costs, isLoading: isLoadingCosts } = useLineItemsCosts({
    lineItems: draft?.lineItems ?? [],
  })
  const { data: procedure, isLoading: isLoadingProcedure } = useProcedure({
    procedureID: version.procedureID,
  })

  const haveAllCostsLoaded = every(costs, (cost) => {
    return cost !== undefined
  })

  let approvalStatus: VersionApprovalStatus | null = null

  if (draft && procedure && haveAllCostsLoaded) {
    const commonStatuses = {
      // Currently, culinary approval is always true since if you have a version, then culinary
      // has approved a draft and promoted it into a version.
      culinary: true,
      operations: procedure.approved,
    }
    const costSums = getLineItemCostSums({ costs: compact(costs), draft })

    approvalStatus = {
      chicago: {
        ...commonStatuses,
        procurement: !!version.postCookingYield && costSums.chicago !== null,
      },
      slc: {
        ...commonStatuses,
        procurement: !!version.postCookingYield && costSums.slc !== null,
      },
    }
  }

  return {
    approvalStatus,
    isLoading: isLoadingCosts || isLoadingDraft || isLoadingProcedure,
  }
}

export function useCreateVersion({
  draft,
  partID,
  ...rest
}: {
  draft: Draft
  partID: string
} & UseMutationOptions<Version, Error>) {
  const { invalidatePart } = useInvalidatePart()
  const { invalidatePartVersions } = useInvalidatePartVersions()

  return useMutation(
    async () => {
      return createVersion({
        draftID: draft.id,
        recipeID: partID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidatePart(partID)
        invalidatePartVersions(partID, { newVersion: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useGetActiveVersions({ partID }: { partID: string }) {
  return useQuery<ActiveVersions, Error>(['active-versions', partID], () => {
    return getActiveVersions({ recipeID: partID })
  })
}

export function useHasNutritionDiverged({ version }: { version: Version }) {
  const { data: draft } = useDraft({
    draftID: version.draftID,
    recipeID: version.recipeID,
  })

  const lineItems = draft?.lineItems ?? []
  const recipes = lineItems.filter((lineItem) => {
    return lineItem.ingredientType === 'recipe'
  })

  const queries = useQueries({
    queries: recipes.map((recipe) => {
      return getRecipeProcurabilityQueryObj({ recipeID: recipe.id })
    }),
  })
  const haveAllQueriesResolved = every(queries, (query) => {
    return query.data
  })

  return (
    haveAllQueriesResolved &&
    some(queries, ({ data }) => {
      return !data?.sameVersionsForAllNetworks
    })
  )
}

export function useInvalidateActiveVersions() {
  const queryClient = useQueryClient()

  return {
    invalidateActiveVersions: (
      partID: string,
      { newActiveVersions }: { newActiveVersions?: ActiveVersions } = {}
    ) => {
      if (newActiveVersions) {
        queryClient.setQueryData<ActiveVersions>(
          ['active-versions', partID],
          newActiveVersions
        )
      }

      queryClient.invalidateQueries(['active-versions', partID])
    },
  }
}

export function useSetActiveVersions({
  partID,
  ...rest
}: {
  partID: string
} & UseMutationOptions<
  ActiveVersions,
  Error,
  { facilityNetworkID: FacilityNetworkID; versionNumber: number }
>) {
  const { invalidateActiveVersions } = useInvalidateActiveVersions()

  return useMutation(
    async ({ facilityNetworkID, versionNumber }) => {
      return setActiveVersions({
        data: {
          facilityNetworkID,
          versionNumber,
        },
        recipeID: partID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        invalidateActiveVersions(partID, { newActiveVersions: args[0] })

        rest.onSuccess?.(...args)
      },
    }
  )
}

export function useEditVersion({
  partID,
  versionNumber,
  ...rest
}: {
  partID: string
  versionNumber: number
} & UseMutationOptions<Version, Error, EditVersion['data']>) {
  const { invalidatePartVersions } = useInvalidatePartVersions()

  return useMutation(
    async (data) => {
      return editVersion({ data, partID, versionNumber })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        rest.onSuccess?.(...args)

        invalidatePartVersions(partID, { newVersion: args[0] })
      },
    }
  )
}
