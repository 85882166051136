import { compact } from 'lodash-es'

import {
  Draft,
  PurchasedGoodCosts,
  RecipeProcurability,
  Version,
} from 'types/domainModels/recipes'
import { getLineItemCostSums } from './drafts'

export function getVersionCostForFacilityNetworks({
  draft,
  lineItemCosts,
  version,
}: {
  draft: Draft
  lineItemCosts: (PurchasedGoodCosts | RecipeProcurability | undefined)[]
  version: Version
}): { chicagoCost: number | null; slcCost: number | null } {
  if (!version.postCookingYield) {
    return { chicagoCost: null, slcCost: null }
  }

  const { chicago: chicagoLineItemsCost, slc: slcLineItemsCost } =
    getLineItemCostSums({
      costs: compact(lineItemCosts),
      draft,
    })

  return {
    chicagoCost:
      chicagoLineItemsCost === null
        ? null
        : chicagoLineItemsCost / version.postCookingYield,
    slcCost:
      slcLineItemsCost === null
        ? null
        : slcLineItemsCost / version.postCookingYield,
  }
}
