import { ActiveVersions, Version } from 'types/domainModels/recipes'
import { FacilityNetworkID } from 'types/domainModels/shared'
import { get, patch, post } from './baseAPI'

export interface CreateVersion {
  draftID: string
  recipeID: string
}

export interface EditVersion {
  data: Partial<Pick<Version, EditableVersionField>>
  partID: string
  versionNumber: number
}

export type EditableVersionField = 'description' | 'postCookingYield'

export interface GetActiveVersions {
  recipeID: string
}

export interface GetVersions {
  partID: string
}

export interface GetVersionsResponse {
  versions: Version[]
}

export interface SetActiveVersions {
  data: {
    facilityNetworkID: FacilityNetworkID
    versionNumber: number
  }
  recipeID: string
}

export const ENDPOINTS = {
  CREATE_VERSION: ({ recipeID }: Pick<CreateVersion, 'recipeID'>) => ({
    path: `/recipes/${recipeID}/versions`,
    version: 'procurement/v1' as const,
  }),
  EDIT_VERSION: ({ partID, versionNumber }: Omit<EditVersion, 'data'>) => ({
    path: `/recipes/${partID}/versions/${versionNumber}`,
    version: 'procurement/v1' as const,
  }),
  GET_ACTIVE_VERSIONS: ({ recipeID }: GetActiveVersions) => ({
    path: `/recipes/${recipeID}/activeVersions`,
    version: `procurement/v1` as const,
  }),
  GET_VERSIONS: ({ partID }: GetVersions) => ({
    path: `/recipes/${partID}/versions`,
    version: 'procurement/v1' as const,
  }),
  SET_ACTIVE_VERSIONS: ({ recipeID }: Pick<SetActiveVersions, 'recipeID'>) => ({
    path: `/recipes/${recipeID}/activeVersions`,
    version: 'procurement/v1' as const,
  }),
}

export function createVersion({ draftID, recipeID }: CreateVersion) {
  return post<Version>({
    ...ENDPOINTS.CREATE_VERSION({ recipeID }),
    data: { draftID, recipeID },
  })
}

export async function editVersion({
  data,
  partID,
  versionNumber,
}: EditVersion): Promise<Version> {
  return patch({
    data,
    ...ENDPOINTS.EDIT_VERSION({ partID, versionNumber }),
  })
}

export async function getActiveVersions({
  recipeID,
}: GetActiveVersions): Promise<ActiveVersions> {
  return get(ENDPOINTS.GET_ACTIVE_VERSIONS({ recipeID }))
}

export async function getVersions({
  partID,
}: GetVersions): Promise<GetVersionsResponse> {
  return get(ENDPOINTS.GET_VERSIONS({ partID }))
}

export function setActiveVersions({
  data,
  recipeID,
}: SetActiveVersions): Promise<ActiveVersions> {
  return post({
    data,
    ...ENDPOINTS.SET_ACTIVE_VERSIONS({ recipeID }),
  })
}
