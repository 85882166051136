import { ReactNode } from 'react'

import CheckIcon from 'components/common/icons/CheckIcon'

const CheckOrEmpty = ({
  falsyValue = null,
  label,
  predicate,
}: {
  falsyValue?: ReactNode | null
  label: string
  predicate: boolean | undefined
}): JSX.Element => {
  if (predicate) {
    return (
      <div aria-label={`${label} active`} className="h-4 w-4 text-green">
        <CheckIcon />
      </div>
    )
  }

  return <div aria-label={`${label} inactive`}>{falsyValue}</div>
}

export default CheckOrEmpty
