import { ReactNode } from 'react'
import { Formik, FormikErrors, Form } from 'formik'

import { Recipe } from 'types/domainModels/recipes'

import { useCreateRecipe } from 'hooks/recipes/recipes'
import FormInput from 'components/common/FormInput'
import Modal, {
  ModalBody,
  ModalButtons,
  ModalHeader,
} from 'components/common/Modal'

export interface CreatePartFormData {
  name: string
}

const CreatePartModal = ({
  createBtnText = 'Create',
  initialName = '',
  onCloseModal,
  onCreatePart,
  warning,
}: {
  createBtnText?: string
  initialName?: string
  onCloseModal(): void
  onCreatePart(part: Recipe): void
  warning?: ReactNode
}): JSX.Element => {
  const {
    error: createPartError,
    isError: hasCreatePartError,
    isLoading: isCreatingPart,
    mutate: createPart,
  } = useCreateRecipe({
    onSuccess: (recipe) => {
      onCreatePart(recipe)
    },
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="w-96">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>Create Part</ModalHeader>
          </div>

          <Formik<CreatePartFormData>
            initialValues={{
              name: initialName,
            }}
            onSubmit={(values) => {
              return createPart(values)
            }}
            validate={(formData) => {
              return validateFormData({ formData })
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
          >
            <Form>
              <FormInput
                autoFocus={true}
                id="name"
                label="Part Name"
                labelFor="name"
                name="name"
              />

              {warning && <div className="mt-4">{warning}</div>}

              <div className="mt-8">
                <ModalButtons
                  apiError={hasCreatePartError ? createPartError : null}
                  isLoading={isCreatingPart}
                  mainActionBtnText={createBtnText}
                  onCancel={onCloseModal}
                />
              </div>
            </Form>
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CreatePartModal

const validateFormData = ({
  formData,
}: {
  formData: CreatePartFormData
}): FormikErrors<CreatePartFormData> => {
  const errors: FormikErrors<CreatePartFormData> = {}

  if (!formData.name) {
    errors.name = 'Please enter a part name.'
  }

  return errors
}
