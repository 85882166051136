import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'

import {
  editProductionTags,
  getProductionTags,
} from 'services/recipes/productionTags'
import { ProductionTags } from 'types/domainModels/recipes'

export function useEditProductionTagsStep({
  procedureID,
  ...rest
}: {
  procedureID: string
} & UseMutationOptions<
  ProductionTags,
  Error,
  { productionTags: ProductionTags }
>) {
  const { invalidateTags } = useInvalidateProductionTag()
  return useMutation(
    ({ productionTags }) => {
      return editProductionTags({
        data: { productionTags },
        procedureID,
      })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        rest.onSuccess?.(...args)
        invalidateTags(procedureID, { productionTags: args[0] })
      },
    }
  )
}

export function useProductionTags({
  procedureID,
  ...rest
}: {
  procedureID: string | undefined
} & Omit<UseQueryOptions<ProductionTags, Error>, 'enabled'>) {
  return useQuery<ProductionTags, Error>(
    ['productionTags', procedureID],
    () => {
      if (!procedureID) {
        throw new Error('No ID was provided for loading productionTags.')
      }

      return getProductionTags({ procedureID })
    },
    { ...rest, enabled: !!procedureID }
  )
}

export function useInvalidateProductionTag() {
  const queryClient = useQueryClient()

  return {
    invalidateTags: (
      procedureID: string,
      { productionTags }: { productionTags?: ProductionTags } = {}
    ) => {
      const queryKey = ['productionTags', procedureID]

      if (productionTags) {
        queryClient.setQueryData<ProductionTags>(queryKey, (currentListing) => {
          if (!currentListing) {
            return currentListing
          }

          return productionTags
        })
      } else {
        queryClient.invalidateQueries(queryKey)
      }
    },
    invalidateListings: () => {
      queryClient.invalidateQueries(['listings'])
    },
  }
}
