const CircleLoader = ({
  isColored = false,
}: {
  isColored?: boolean
}): JSX.Element => {
  // The following can be simplified to use clsx once we get rid of Bootstrap
  // and "important" declarations on Tailwind classes.
  let borderColorStyles = {
    borderTopColor: '#fff',
    borderRightColor: '#fff',
    borderLeftColor: '#fff',
  }

  if (isColored) {
    borderColorStyles = {
      borderTopColor: '#ff5600',
      borderRightColor: '#ff5600',
      borderLeftColor: '#ff5600',
    }
  }

  return (
    <div
      className="flex h-4 w-4 animate-spin-pulse justify-center rounded-full border-2"
      data-testid="circle-loader"
      style={{ borderBottomColor: 'transparent', ...borderColorStyles }}
    />
  )
}

export default CircleLoader
