import { clsx } from 'clsx'
import { ReactNode } from 'react'

export type BadgeStyle = 'danger' | 'primary' | 'success' | 'warning'

export type Props = {
  badgeStyle?: BadgeStyle
  children: ReactNode
}

const Badge = ({ badgeStyle = 'primary', children }: Props): JSX.Element => {
  return (
    <div className="flex">
      <div
        className={clsx('rounded-md px-2 py-1 text-xs font-bold uppercase', {
          'bg-red text-white': badgeStyle === 'danger',
          'bg-orange text-white': badgeStyle === 'primary',
          'bg-green text-white': badgeStyle === 'success',
          'bg-gold text-white': badgeStyle === 'warning',
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Badge
