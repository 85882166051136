import CircleLoader from './CircleLoader'
import Textarea, { Props as TextareaProps } from 'components/common/Textarea'

export type Props = TextareaProps & { isLoading?: boolean }

const TextareaAsync = ({
  disabled,
  isLoading = false,
  ...rest
}: Props): JSX.Element => {
  const isDisabled = disabled || isLoading

  return (
    <Textarea
      {...rest}
      disabled={isDisabled}
      rightIcon={isLoading ? <CircleLoader isColored={true} /> : null}
    />
  )
}

export default TextareaAsync
