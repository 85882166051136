import { AxiosError } from 'axios'

import { APIErrorResponse } from 'types/domainModels/recipes'

export function getAPIErrorMessage(error: Error | null) {
  if (isAxiosResponseError(error)) {
    return error.response?.data.message ?? 'Unknown error'
  }

  return error ? error.message : 'Unknown error'
}

export function isAxiosResponseError(
  error: Error | null | undefined
): error is AxiosError<APIErrorResponse> {
  return (error as AxiosError)?.response !== undefined
}
