import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { forEach, isEmpty, mapValues, omitBy } from 'lodash-es'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useQueryParams } from 'hooks/routes'

interface Filters {
  filters: Record<string, string>
  onChangeFilter(filterName: string, filterValue: string): void
  onChangeFilters(filters: Record<string, string>): void
  onClearFilters(): void
}

const FiltersContext = createContext<Filters | undefined>(undefined)

function FiltersProvider<FilterKeys extends readonly string[]>({
  filterKeys,
  filterPathname,
}: {
  filterKeys: FilterKeys
  filterPathname: string
}): JSX.Element {
  const location = useLocation()
  const navigate = useNavigate()

  const filterParams = useQueryParams(filterKeys)
  const nonEmptyFilterParams = omitBy(
    filterParams,
    (param) => param === ''
  ) as Record<FilterKeys[number], string>

  const lastFilterParams = useRef<
    Record<FilterKeys[number], string> | undefined
  >(isEmpty(nonEmptyFilterParams) ? undefined : nonEmptyFilterParams)

  function onChangeFilter(filterName: FilterKeys[number], filterValue: string) {
    onChangeFilters({ ...nonEmptyFilterParams, [filterName]: filterValue })
  }

  function onChangeFilters(
    filtersToUpdate: Record<FilterKeys[number], string>
  ) {
    const newFilters = { ...nonEmptyFilterParams, ...filtersToUpdate }

    updateFiltersInURL(newFilters)
    lastFilterParams.current = newFilters
  }

  function onClearFilters() {
    onChangeFilters(
      mapValues(nonEmptyFilterParams, () => {
        return ''
      })
    )
  }

  const updateFiltersInURL = useCallback(
    (filters: Record<FilterKeys[number], string>) => {
      const searchParams = new URLSearchParams(location.search)

      if (isEmpty(filters)) {
        forEach(lastFilterParams.current, (_filterValue, filterName) => {
          searchParams.delete(filterName)
        })
      } else {
        forEach(filters, (filterValue, filterName) => {
          if (filterValue) {
            searchParams.set(filterName, filterValue)
          } else {
            // We delete an empty filter because it keeps the URL cleaner instead of having URLs like
            // ?filterKey=&filterKey2=
            searchParams.delete(filterName)
          }
        })
      }

      navigate(`${filterPathname}?${searchParams}`)
    },
    [location.search, navigate, filterPathname]
  )

  useEffect(() => {
    const hasFilterParams = !isEmpty(nonEmptyFilterParams)

    if (location.pathname === filterPathname) {
      // If we are on the filter path and we have filters in the URL, we want to save
      // these as the latest filter values. Otherwise, if we don't have filters in the URL
      // but we have previously saved filters, we should reapply these in the URL.
      if (hasFilterParams) {
        lastFilterParams.current = nonEmptyFilterParams
      } else if (lastFilterParams.current) {
        updateFiltersInURL(lastFilterParams.current)

        lastFilterParams.current = undefined
      }
    }
  }, [
    location.pathname,
    nonEmptyFilterParams,
    updateFiltersInURL,
    filterPathname,
  ])

  return (
    <FiltersContext.Provider
      value={{
        filters: nonEmptyFilterParams,
        onChangeFilter,
        onChangeFilters,
        onClearFilters,
      }}
    >
      <Outlet />
    </FiltersContext.Provider>
  )
}

function useFilters() {
  const context = useContext(FiltersContext)
  if (context === undefined) {
    throw new Error('useFilters must be used in an FiltersProvider')
  }

  return context
}

export { FiltersProvider, useFilters }
