import { ReactSelectValue } from 'types/internal'
import { ReceivingUnit } from 'types/domainModels/purchasedGoods'

export const RECEIVING_UNIT_OPTIONS: ReactSelectValue<ReceivingUnit>[] = [
  {
    label: 'Cases',
    value: 'cases',
  },
  {
    label: 'Packs',
    value: 'packs',
  },
]
