import { TagCategories, Version } from 'types/domainModels/recipes'

import {
  useEditProductionTagsStep,
  useProductionTags,
} from 'hooks/recipes/productionTags'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import { ReactSelectValue } from 'types/internal'
import { Form, Formik } from 'formik'
import FormSelect from 'components/common/FormSelect'
import Button from 'components/common/Button'
import SuccessDisplay from 'components/common/SuccessDisplay'

interface FormData {
  procedureID: string
  prepareTag: ReactSelectValue<TagCategories['prepare']> | null
  cookTag: ReactSelectValue<TagCategories['cook']> | null
  mixTag: ReactSelectValue<TagCategories['mix']> | null
  cannedTag: ReactSelectValue<TagCategories['canned']> | null
  manualTag: ReactSelectValue<TagCategories['manual']> | null
  labelsTag: ReactSelectValue<TagCategories['labels']> | null
}

const VersionDefaultTagsTab = ({
  version,
}: {
  version: Version
}): JSX.Element => {
  const procedureID = version.procedureID

  const {
    data: productionTags,
    error: loadTagsError,
    isError: hasLoadTagsError,
    isLoading: isLoadingProductionTags,
  } = useProductionTags({ procedureID })

  const {
    error: editProductionTagsError,
    isError: hasEditProductionTagsError,
    isSuccess: hasSavedProductionTags,
    mutate: editProductionTags,
  } = useEditProductionTagsStep({
    procedureID,
  })

  return (
    <div className="text-sm">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl">Default Tags</h2>
      </div>

      {hasLoadTagsError && <APIErrorDisplay error={loadTagsError} />}
      {hasEditProductionTagsError && (
        <APIErrorDisplay error={editProductionTagsError} />
      )}
      {isLoadingProductionTags && (
        <div className="space-y-4">
          <div className="h-4 w-1/3 animate-pulse bg-light-grey" />
          <div className="h-4 w-1/3 animate-pulse bg-light-grey" />
        </div>
      )}

      {!isLoadingProductionTags && !hasLoadTagsError && productionTags && (
        <Formik<FormData>
          initialValues={{
            procedureID,
            prepareTag:
              PREPARE_TAG_OPTIONS.find(
                (entry) => entry.value === productionTags.prepareTag
              ) || null,
            cookTag:
              COOK_TAG_OPTIONS.find(
                (entry) => entry.value === productionTags.cookTag
              ) || null,
            mixTag:
              MIX_TAG_OPTIONS.find(
                (entry) => entry.value === productionTags.mixTag
              ) || null,
            cannedTag:
              CANNED_TAG_OPTIONS.find(
                (entry) => entry.value === productionTags.cannedTag
              ) || null,
            manualTag:
              MANUAL_TAG_OPTIONS.find(
                (entry) => entry.value === productionTags.manualTag
              ) || null,
            labelsTag:
              LABELS_TAG_OPTIONS.find(
                (entry) => entry.value === productionTags.labelsTag
              ) || null,
          }}
          onSubmit={(values) => {
            return editProductionTags({
              productionTags: {
                prepareTag: values.prepareTag?.value || 'no_default',
                cookTag: values.cookTag?.value || 'no_default',
                mixTag: values.mixTag?.value || 'no_default',
                cannedTag: values.cannedTag?.value || 'no_default',
                manualTag: values.manualTag?.value || 'no_default',
                labelsTag: values.labelsTag?.value || 'no_default',
              },
            })
          }}
        >
          <div className="mb-4 w-1/3">
            <Form>
              <div className="mb-3">
                <FormSelect
                  inputId="prepareTag"
                  label="Prepare"
                  labelFor="prepareTag"
                  name="prepareTag"
                  options={PREPARE_TAG_OPTIONS}
                />
              </div>
              <div className="mb-3">
                <FormSelect
                  inputId="cookTag"
                  label="Cook"
                  labelFor="cookTag"
                  name="cookTag"
                  options={COOK_TAG_OPTIONS}
                />
              </div>
              <div className="mb-3">
                <FormSelect
                  inputId="mixTag"
                  label="Mix"
                  labelFor="mixTag"
                  name="mixTag"
                  options={MIX_TAG_OPTIONS}
                />
              </div>
              <div className="mb-3">
                <FormSelect
                  inputId="cannedTag"
                  label="Canned"
                  labelFor="cannedTag"
                  name="cannedTag"
                  options={CANNED_TAG_OPTIONS}
                />
              </div>
              <div className="mb-3">
                <FormSelect
                  inputId="manualTag"
                  label="Manual"
                  labelFor="manualTag"
                  name="manualTag"
                  options={MANUAL_TAG_OPTIONS}
                />
              </div>
              <FormSelect
                inputId="labelsTag"
                label="Label"
                labelFor="labelsTag"
                name="labelsTag"
                options={LABELS_TAG_OPTIONS}
              />
              <div className="mt-8">
                <Button type="submit">Save</Button>
              </div>

              {hasSavedProductionTags && (
                <SuccessDisplay>Saved!</SuccessDisplay>
              )}
            </Form>
          </div>
        </Formik>
      )}
    </div>
  )
}

export default VersionDefaultTagsTab

export const PREPARE_TAG_OPTIONS: ReactSelectValue<TagCategories['prepare']>[] =
  [
    { label: 'No Default', value: 'no_default' },
    { label: 'Portion and Freeze', value: 'portion_and_freeze' },
    { label: 'Thaw Frozen', value: 'thaw_frozen' },
  ]

export const COOK_TAG_OPTIONS: ReactSelectValue<TagCategories['cook']>[] = [
  { label: 'No Default', value: 'no_default' },
  { label: 'Kettle', value: 'kettle' },
  { label: 'Oven', value: 'oven' },
  { label: 'Skillet', value: 'skillet' },
]

export const MIX_TAG_OPTIONS: ReactSelectValue<TagCategories['mix']>[] = [
  { label: 'No Default', value: 'no_default' },
  { label: 'Batch Mix', value: 'batch_mix' },
  { label: 'Planetary Mixer', value: 'planetary_mixer' },
  { label: 'Sauce Mix', value: 'sauce_mix' },
]

export const CANNED_TAG_OPTIONS: ReactSelectValue<TagCategories['canned']>[] = [
  { label: 'No Default', value: 'no_default' },
  { label: 'Drain', value: 'drain' },
  { label: 'Open', value: 'open' },
]

export const MANUAL_TAG_OPTIONS: ReactSelectValue<TagCategories['manual']>[] = [
  { label: 'No Default', value: 'no_default' },
  { label: 'Knife', value: 'knife' },
  { label: 'Robo', value: 'robo' },
  { label: 'Stick Blender', value: 'stick_blender' },
  { label: 'VCM', value: 'vcm' },
]

export const LABELS_TAG_OPTIONS: ReactSelectValue<TagCategories['labels']>[] = [
  { label: 'No Default', value: 'no_default' },
  { label: 'Do Not Label', value: 'do_not_label' },
]
