import { orderBy } from 'lodash-es'

import { ReactSelectValue } from 'types/internal'

export function getCategoryOptions(
  categories: string[]
): ReactSelectValue<string>[] {
  const orderedCategories = orderBy(categories)

  return orderedCategories.map((category) => {
    return { label: category, value: category }
  })
}
