import { Formik, FormikErrors, Form, useFormikContext } from 'formik'
import { isEmpty } from 'lodash-es'

import { getCategoryOptions } from 'utils/categories'
import { getStorageLocationOptions } from 'utils/storageLocations'
import { getTCSZoneOptions } from 'utils/tcsZones'
import { PurchasedGood } from 'types/domainModels/purchasedGoods'
import { ReactSelectValue } from 'types/internal'

import {
  useCategories,
  useStorageLocations,
  useTCSZones,
} from 'hooks/purchasedGoods/tools'
import { useCreatePurchasedGood } from 'hooks/purchasedGoods/purchasedGoods'
import FormCheckbox from 'components/common/FormCheckbox'
import FormInput from 'components/common/FormInput'
import FormSelect from 'components/common/FormSelect'
import Modal, {
  ModalBody,
  ModalButtons,
  ModalHeader,
} from 'components/common/Modal'

interface FormData {
  category: ReactSelectValue<string> | null
  isActive: boolean
  name: string
  storageLocation: ReactSelectValue<string> | null
  tcsZone: ReactSelectValue<string> | null
}

interface ValidatedData extends FormData {
  category: ReactSelectValue<string>
  storageLocation: ReactSelectValue<string>
  tcsZone: ReactSelectValue<string>
}

export interface Props {
  onCloseModal(): void
  onCreatePurchasedGood(purchasedGood: PurchasedGood): void
}

const validateFormData = ({
  formData,
}: {
  formData: FormData
}): FormikErrors<FormData> => {
  const errors: FormikErrors<FormData> = {}

  if (!formData.name) {
    errors.name = 'Please enter a name.'
  }

  if (!formData.category) {
    errors.category = 'Please select a category.'
  }

  if (!formData.storageLocation) {
    errors.storageLocation = 'Please select a storage location.'
  }

  if (!formData.tcsZone) {
    errors.tcsZone = 'Please select a TCS zone.'
  }

  return errors
}

const CreatePurchasedGoodModal = ({
  onCloseModal,
  onCreatePurchasedGood,
}: Props): JSX.Element => {
  const {
    error: createPurchasedGoodError,
    isError: hasCreatePurchasedGoodError,
    isLoading: isCreatingPurchasedGood,
    mutate: createPurchasedGood,
  } = useCreatePurchasedGood({
    onSuccess: (purchasedGood) => {
      onCreatePurchasedGood(purchasedGood)
    },
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="w-create-purchased-good-modal">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>
              Create Purchased Good
            </ModalHeader>
          </div>

          <Formik<FormData>
            initialValues={{
              category: null,
              isActive: true,
              name: '',
              storageLocation: null,
              tcsZone: null,
            }}
            onSubmit={(values) => {
              const validatedData = values as ValidatedData

              return createPurchasedGood({
                category: validatedData.category.value,
                ingredientName: validatedData.name,
                isActive: validatedData.isActive,
                storageLocation: validatedData.storageLocation.value,
                warehouseZone: validatedData.tcsZone.value,
              })
            }}
            validate={(formData) => {
              return validateFormData({ formData })
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
          >
            <Form>
              <CreatePurchasedGoodForm
                createError={
                  hasCreatePurchasedGoodError ? createPurchasedGoodError : null
                }
                isCreating={isCreatingPurchasedGood}
                onCancel={onCloseModal}
              />
            </Form>
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CreatePurchasedGoodModal

const CreatePurchasedGoodForm = ({
  createError,
  isCreating,
  onCancel,
}: {
  createError: Error | null
  isCreating: boolean
  onCancel(): void
}): JSX.Element => {
  const { errors } = useFormikContext<FormData>()

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <FormInput
          autoFocus={true}
          id="name"
          label="Name"
          labelFor="name"
          name="name"
        />
        <CategorySelect />
        <StorageLocationSelect />
        <TCSZoneSelect />
        <FormCheckbox
          checkboxLabel="Is Active?"
          label="Active"
          name="isActive"
        />
      </div>

      <div className="mt-8">
        <ModalButtons
          apiError={createError}
          hasValidationErrors={!isEmpty(errors)}
          isLoading={isCreating}
          mainActionBtnText="Create"
          onCancel={onCancel}
        />
      </div>
    </>
  )
}

const CategorySelect = (): JSX.Element => {
  const {
    data: categories = [],
    error: loadCategoriesError,
    isError: hasLoadCategoriesError,
    isLoading: isLoadingCategories,
  } = useCategories()

  const categoryOptions = getCategoryOptions(categories)

  return (
    <FormSelect
      inputId="category"
      isLoading={isLoadingCategories}
      label="Category"
      labelFor="category"
      loadError={
        hasLoadCategoriesError ? loadCategoriesError.message : undefined
      }
      name="category"
      options={categoryOptions}
    />
  )
}

const StorageLocationSelect = (): JSX.Element => {
  const {
    data: storageLocations = [],
    error: loadStorageLocationsError,
    isError: hasLoadStorageLocationsError,
    isLoading: isLoadingStorageLocations,
  } = useStorageLocations()

  const storageLocationOptions = getStorageLocationOptions(storageLocations)

  return (
    <FormSelect
      inputId="storage-location"
      isLoading={isLoadingStorageLocations}
      label="Storage Location"
      labelFor="storage-location"
      loadError={
        hasLoadStorageLocationsError
          ? loadStorageLocationsError.message
          : undefined
      }
      name="storageLocation"
      options={storageLocationOptions}
    />
  )
}

const TCSZoneSelect = (): JSX.Element => {
  const {
    data: tcsZones = [],
    error: loadTCSZonesError,
    isError: hasLoadTCSZonesError,
    isLoading: isLoadingTCSZones,
  } = useTCSZones()

  const tcsZoneOptions = getTCSZoneOptions(tcsZones)

  return (
    <FormSelect
      inputId="tcs-zone"
      isLoading={isLoadingTCSZones}
      label="TCS Zone"
      labelFor="tcs-zone"
      loadError={hasLoadTCSZonesError ? loadTCSZonesError.message : undefined}
      name="tcsZone"
      options={tcsZoneOptions}
    />
  )
}
