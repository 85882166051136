import { orderBy } from 'lodash-es'

import { ReactSelectValue } from 'types/internal'
import { Vendor } from 'types/domainModels/purchasedGoods'

export function getVendorOptions(
  vendors: Vendor[]
): ReactSelectValue<string>[] {
  const orderedVendors = orderBy(vendors, ({ name }) => name)

  return orderedVendors.map((vendor) => {
    return { label: vendor.name, value: vendor.id }
  })
}
