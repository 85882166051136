import { ReactNode } from 'react'

const LabeledField = ({
  children,
  title,
}: {
  children: ReactNode
  title: string
}): JSX.Element => {
  return (
    <div className="grid grid-cols-2" data-testid={`labeled-field-${title}`}>
      <div className="mt-1 text-xs uppercase text-dark-grey">{title}</div>
      <div>{children || 'N/A'}</div>
    </div>
  )
}

export default LabeledField
