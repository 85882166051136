import {
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useState } from 'react'

import { Draft, IngredientRecipe } from 'types/domainModels/recipes'
import { PartWithVersions } from 'types/internal'

import { usePartVersions } from 'hooks/recipes/parts'
import Breadcrumbs from 'components/common/Breadcrumbs'
import Button from 'components/common/Button'
import EditablePartName from './EditablePartName'
import ErrorDisplay from 'components/common/ErrorDisplay'
import Label from 'components/common/Label'
import NewDraftModal from './NewDraftModal'
import PartActiveVersions from './PartActiveVersions'
import { PartBodyLoading } from './PartPageLoading'
import PartVersion from './PartVersion'
import Tabs, { Tab } from 'components/common/Tabs'
import { orderBy } from 'lodash-es'

const PartVersions = ({ part }: { part: IngredientRecipe }): JSX.Element => {
  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            link: '/parts',
            text: 'Parts',
          },
          { link: `/parts/${part.id}/versions`, text: part.name },
          { link: `/parts/${part.id}/versions`, text: 'Versions' },
        ]}
      />

      <div className="mb-2 mt-1 space-y-2">
        <div className="max-w-xs">
          <EditablePartName part={part} />
        </div>
        <div className="flex items-center space-x-4 text-sm">
          <Label>Part ID</Label>
          <div>{part.id}</div>
        </div>
      </div>

      <PartVersionsLoading part={part} />
    </>
  )
}

export default PartVersions

const PartVersionsLoading = ({
  part,
}: {
  part: IngredientRecipe
}): JSX.Element => {
  const {
    data: getPartVersionsResponse,
    error: getPartVersionsError,
    isError: hasLoadPartVersionsError,
    isLoading: isLoadingPartVersions,
  } = usePartVersions({ partID: part.id })

  const versions = getPartVersionsResponse?.versions ?? []
  const partWithVersions = { ...part, versions }

  if (isLoadingPartVersions) {
    return (
      <div className="mt-8">
        <PartBodyLoading />
      </div>
    )
  }

  if (hasLoadPartVersionsError) {
    return (
      <ErrorDisplay
        message={`Could not load versions. Error: ${getPartVersionsError.message}`}
      />
    )
  }

  return (
    <Routes>
      <Route
        element={<PartVersionSelected part={partWithVersions} />}
        path=":versionNumber/*"
      />
      <Route
        element={<PartVersionsAutoSelect part={partWithVersions} />}
        index
      />
    </Routes>
  )
}

const PartVersionsAutoSelect = ({
  part,
}: {
  part: PartWithVersions
}): JSX.Element => {
  const firstVersion = orderBy(
    part.versions,
    ({ versionNumber }) => versionNumber
  )[0]

  if (firstVersion) {
    return <Navigate replace to={`${firstVersion.versionNumber}`} />
  }

  return (
    <p className="text-sm">
      {part.name} does not currently have any versions. Promote a "Tasting
      Approved" draft to create a version.{' '}
      <Link className="text-blue" to="../drafts">
        View Drafts
      </Link>
    </p>
  )
}

const PartVersionSelected = ({
  part,
}: {
  part: PartWithVersions
}): JSX.Element => {
  const { versionNumber } = useParams()

  const drafts = part.recipe.drafts ?? []

  const activeVersion = part.versions.find((version) => {
    return version.versionNumber === Number(versionNumber)
  })

  return (
    <>
      <div className="my-4 w-72">
        <PartActiveVersions partID={part.id} versions={part.versions} />
      </div>

      <PartVersionsTabs drafts={drafts} part={part} />

      <div className="mt-4">
        {activeVersion ? (
          <PartVersion
            key={activeVersion.versionNumber}
            part={part}
            version={activeVersion}
          />
        ) : (
          <ErrorDisplay
            message={`Could not find a version with number: ${versionNumber}`}
          />
        )}
      </div>
    </>
  )
}

const PartVersionsTabs = ({
  drafts,
  part,
}: {
  drafts: Draft[]
  part: PartWithVersions
}): JSX.Element => {
  const navigate = useNavigate()

  const [isCreateNewDraftModalOpen, setIsCreateNewDraftModalOpen] =
    useState(false)

  const orderedVersions = orderBy(
    part.versions,
    ({ versionNumber }) => versionNumber
  )

  return (
    <div className="relative border-b border-light-grey text-sm">
      <Tabs title="Versions">
        {orderedVersions.map((version) => {
          return (
            <Tab key={version.versionNumber} to={`../${version.versionNumber}`}>
              {version.versionNumber}
            </Tab>
          )
        })}
      </Tabs>
      <div className="absolute bottom-2 right-0 w-32">
        <Button
          onClick={() => {
            setIsCreateNewDraftModalOpen(true)
          }}
        >
          + New Draft
        </Button>

        {isCreateNewDraftModalOpen && (
          <NewDraftModal
            existingDrafts={drafts}
            onCloseModal={() => {
              setIsCreateNewDraftModalOpen(false)
            }}
            onNewDraftCreated={(newDraft) => {
              setIsCreateNewDraftModalOpen(false)

              navigate(`/parts/${part.id}/drafts/${newDraft.id}`)
            }}
            part={part}
          />
        )}
      </div>
    </div>
  )
}
