import { cloneDeep, findIndex } from 'lodash-es'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { Draft, Ingredient, Recipe, Version } from 'types/domainModels/recipes'
import { convertRecipeToIngredient } from 'utils/recipes'
import { getIngredient } from 'services/recipes/ingredients'
import { GetVersionsResponse, getVersions } from 'services/recipes/versions'

export function useInvalidatePart() {
  const queryClient = useQueryClient()

  return {
    invalidatePart: (
      partID: string,
      { newDraft, newRecipe }: { newDraft?: Draft; newRecipe?: Recipe } = {}
    ) => {
      if (newRecipe) {
        queryClient.setQueryData<Ingredient | undefined>(
          ['parts', partID],
          () => {
            return convertRecipeToIngredient(newRecipe)
          }
        )
      }

      if (newDraft) {
        queryClient.setQueryData<Ingredient | undefined>(
          ['parts', partID],
          (oldPart) => {
            const newPart = cloneDeep(oldPart)

            if (!newPart?.recipe) {
              return newPart
            }

            const drafts = newPart.recipe.drafts
            const draftIndex = findIndex(drafts, ({ id }) => {
              return id === newDraft.id
            })

            if (draftIndex === -1) {
              drafts.push(newDraft)
            } else {
              drafts[draftIndex] = newDraft
            }

            return newPart
          }
        )
      }

      queryClient.invalidateQueries(['parts', partID])
    },
  }
}

export function useInvalidatePartVersions() {
  const queryClient = useQueryClient()

  return {
    invalidatePartVersions: (
      partID: string,
      { newVersion }: { newVersion?: Version } = {}
    ) => {
      if (newVersion) {
        queryClient.setQueryData<GetVersionsResponse | undefined>(
          ['part-versions', partID],
          (oldVersions) => {
            const newVersions = cloneDeep(oldVersions)

            if (!newVersions) {
              return { versions: [newVersion] }
            }

            const oldVersionIndex = findIndex(
              newVersions.versions,
              (version) => {
                return version.versionNumber === newVersion.versionNumber
              }
            )

            if (oldVersionIndex === -1) {
              newVersions.versions.push(newVersion)
            } else {
              newVersions.versions[oldVersionIndex] = newVersion
            }

            return newVersions
          }
        )
      }

      queryClient.invalidateQueries(['part-versions', partID])
    },
  }
}

export function usePart({
  partID,
  ...rest
}: { partID: string | undefined } & Omit<
  UseQueryOptions<Ingredient, Error>,
  'enabled'
>) {
  return useQuery<Ingredient, Error>(
    ['parts', partID],
    () => {
      if (!partID) {
        throw new Error('No part ID provided.')
      }

      return getIngredient({ ingredientID: partID })
    },
    { ...rest, enabled: !!partID }
  )
}

export function usePartVersions({ partID }: { partID: string | undefined }) {
  return useQuery<GetVersionsResponse, Error>(
    ['part-versions', partID],
    () => {
      if (!partID) {
        throw new Error('No part ID provided.')
      }

      return getVersions({ partID })
    },
    { enabled: !!partID }
  )
}
