import { ReactNode } from 'react'

const HoverHighlight = ({
  children,
  onActivate,
}: {
  children: ReactNode
  onActivate(): void
}): JSX.Element => {
  return (
    <div
      className="relative cursor-pointer before:absolute before:-inset-1 before:hidden before:rounded-md before:bg-lightest-grey hover:before:block"
      onClick={onActivate}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault()

          onActivate()
        }
      }}
      tabIndex={0}
    >
      <div className="relative">{children}</div>
    </div>
  )
}

export default HoverHighlight
