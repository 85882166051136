import { ReactNode } from 'react'

const TableHeader = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <div
      className="border-b border-light-grey pb-1 text-xs uppercase text-dark-grey"
      role="rowgroup"
    >
      {children}
    </div>
  )
}

export default TableHeader
