import { round, sum, values } from 'lodash-es'
import { useState } from 'react'

import { DraftLineItem } from 'types/domainModels/recipes'
import { formatPercent } from 'utils/general'
import { getLineItemScaledWeight } from 'utils/drafts'

import Button from 'components/common/Button'
import FormGroup from 'components/common/FormGroup'
import Input from 'components/common/Input'
import Modal, { ModalBody, ModalHeader } from 'components/common/Modal'
import TableHeader from 'components/common/TableHeader'

const ScaleRecipeModal = ({
  lineItems,
  onCloseModal,
  postCookingYield,
  totalWeight,
}: {
  lineItems: DraftLineItem[]
  onCloseModal(): void
  postCookingYield: number
  totalWeight: number
}): JSX.Element => {
  const [targetWeight, setTargetWeight] = useState<number | ''>('')

  const scaleRecipeTableClasses = 'grid grid-cols-[1fr_125px_125px] gap-4'

  const scaledWeights: { [lineItemID: string]: number | null } = {}
  lineItems.forEach((lineItem) => {
    scaledWeights[lineItem.id] = getLineItemScaledWeight({
      lineItem,
      postCookingYield,
      targetWeight,
      totalWeight,
    })
  })
  const totalScaledWeight = sum(values(scaledWeights))

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="w-scale-recipe-modal text-sm">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>Scale Recipe</ModalHeader>
          </div>

          <div className="flex space-x-8">
            <div className="flex-grow">
              <FormGroup label="Target Weight (G)" labelFor="target-weight">
                <Input
                  autoFocus
                  id="target-weight"
                  name="target-weight"
                  onChange={(event) => {
                    const newTargetWeight = event.target.value

                    setTargetWeight(
                      newTargetWeight ? Number(newTargetWeight) : ''
                    )
                  }}
                  type="number"
                  value={targetWeight}
                />
              </FormGroup>
            </div>

            <div className="flex-grow">
              <FormGroup label="Post Cooking Yield">
                <span>{formatPercent(postCookingYield)}</span>
              </FormGroup>
            </div>
          </div>

          <div aria-label="Scaled Line Items" className="mt-4" role="grid">
            <TableHeader>
              <div
                className={`${scaleRecipeTableClasses} items-end`}
                role="row"
              >
                <div role="columnheader">Line Item</div>
                <div className="text-center" role="columnheader">
                  Weight (G)
                </div>
                <div className="text-center" role="columnheader">
                  Scaled Weight (G)
                </div>
              </div>
            </TableHeader>
            <div role="rowgroup">
              {lineItems.map((lineItem) => {
                const scaledWeight = getLineItemScaledWeight({
                  lineItem,
                  postCookingYield,
                  targetWeight,
                  totalWeight,
                })

                return (
                  <div
                    key={lineItem.id}
                    className={`${scaleRecipeTableClasses} border-b border-light-grey py-2`}
                    role="row"
                  >
                    <span role="cell">{lineItem.name}</span>
                    <span className="text-center" role="cell">
                      {lineItem.quantityGrams}
                    </span>
                    <span className="text-center" role="cell">
                      {scaledWeight === null ? '---' : round(scaledWeight, 2)}
                    </span>
                  </div>
                )
              })}

              <div
                className={`${scaleRecipeTableClasses} py-2 uppercase text-dark-grey`}
                role="row"
              >
                <span role="cell">Total</span>
                <span className="text-center" role="cell">
                  {round(totalWeight, 2)}
                </span>
                <span className="text-center" role="cell">
                  {round(totalScaledWeight, 2)}
                </span>
              </div>
            </div>
          </div>

          <div className="mt-8 flex justify-end">
            <div className="w-24">
              <Button onClick={onCloseModal}>Done</Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ScaleRecipeModal
