import { get } from '../recipes/baseAPI'
import { Ingredient, IngredientShort } from 'types/domainModels/recipes'

export interface GetIngredient {
  ingredientID: string
}

export interface GetIngredients {
  limit?: number
  nextCursor?: string
}

export interface GetIngredientsResponse {
  nextCursor?: string | null
  ingredients: IngredientShort[]
}

export interface SearchIngredients {
  limit?: number
  nameLike: string
}

export interface SearchIngredientsResponse {
  ingredients: IngredientShort[]
}

export const ENDPOINTS = {
  GET_INGREDIENT: ({ ingredientID }: GetIngredient) => ({
    path: `/ingredients/${ingredientID}`,
    version: 'recipes/v1' as const,
  }),
  GET_INGREDIENTS: () => ({
    path: '/ingredients',
    version: 'recipes/v1' as const,
  }),
  SEARCH_INGREDIENTS: () => ({
    path: '/ingredients',
    version: 'recipes/v1' as const,
  }),
}

export async function getIngredient({
  ingredientID,
}: GetIngredient): Promise<Ingredient> {
  return get(ENDPOINTS.GET_INGREDIENT({ ingredientID }))
}

export async function getIngredients({
  limit = undefined,
  nextCursor = undefined,
}: GetIngredients = {}): Promise<GetIngredientsResponse> {
  return get({
    config: {
      params: {
        cursor: nextCursor,
        limit,
      },
    },
    ...ENDPOINTS.GET_INGREDIENTS(),
  })
}

export async function searchIngredients({
  limit = undefined,
  nameLike,
}: SearchIngredients): Promise<SearchIngredientsResponse> {
  return get({
    config: {
      params: {
        limit,
        nameLike,
      },
    },
    ...ENDPOINTS.SEARCH_INGREDIENTS(),
  })
}
