import { patch, post } from './baseAPI'
import { ReceivingUnit, VendorSKU } from 'types/domainModels/purchasedGoods'

export interface CreateVendorSKU {
  data: {
    sku: string
    vendorID: string
  }
  purchasedGoodID: string
}

export interface EditVendorSKU {
  data: Partial<{
    caseWeightLbs: number
    leadTime: number
    minimumOrderQty: number
    notes: string
    packSize: number
    packUOM: string
    packWeightLbs: number
    packsPerCase: number
    palletConfiguration: string
    receivingUnit: ReceivingUnit
    shelfLifeDays: number
    sku: string
    skuDescription: string
  }>
  purchasedGoodID: string
  vendorSKUID: string
}

export const ENDPOINTS = {
  CREATE_VENDOR_SKU: ({
    purchasedGoodID,
  }: Pick<CreateVendorSKU, 'purchasedGoodID'>) => ({
    path: `/purchasedGoods/${purchasedGoodID}/vendorSKUs`,
    version: 'v1' as const,
  }),
  EDIT_VENDOR_SKU: ({
    purchasedGoodID,
    vendorSKUID,
  }: Pick<EditVendorSKU, 'purchasedGoodID' | 'vendorSKUID'>) => ({
    path: `/purchasedGoods/${purchasedGoodID}/vendorSKUs/${vendorSKUID}`,
    version: 'v1' as const,
  }),
}

export function createVendorSKU({ data, purchasedGoodID }: CreateVendorSKU) {
  return post<VendorSKU>({
    data,
    ...ENDPOINTS.CREATE_VENDOR_SKU({ purchasedGoodID }),
  })
}

export function editVendorSKU({
  data,
  purchasedGoodID,
  vendorSKUID,
}: EditVendorSKU) {
  return patch<VendorSKU>({
    data,
    ...ENDPOINTS.EDIT_VENDOR_SKU({ purchasedGoodID, vendorSKUID }),
  })
}
