import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'

import {
  CreateVendor,
  createVendor,
  EditVendorContact,
  editVendorContact,
  getVendor,
  GetVendorsResponse,
  getVendors,
} from 'services/purchasedGoods/vendors'
import { Vendor } from 'types/domainModels/purchasedGoods'

export function useCreateVendor(
  opts: UseMutationOptions<Vendor, Error, CreateVendor['data']>
) {
  const { invalidateVendors } = useInvalidateVendors()

  return useMutation(
    async (data) => {
      return createVendor({ data })
    },
    {
      ...opts,
      onSuccess: (...args) => {
        opts.onSuccess?.(...args)

        invalidateVendors()
      },
    }
  )
}

export function useEditVendorContact({
  vendorID,
  ...rest
}: { vendorID: string } & UseMutationOptions<
  Vendor,
  Error,
  EditVendorContact['data']
>) {
  const { invalidateVendor } = useInvalidateVendor()

  return useMutation(
    async (data) => {
      return editVendorContact({ data, vendorID })
    },
    {
      ...rest,
      onSuccess: (...args) => {
        rest.onSuccess?.(...args)

        invalidateVendor(vendorID, { newVendor: args[0] })
      },
    }
  )
}

export function useInvalidateVendor() {
  const queryClient = useQueryClient()

  return {
    invalidateVendor: (
      vendorID: string,
      {
        newVendor,
      }: {
        newVendor?: Vendor
      }
    ) => {
      if (newVendor) {
        queryClient.setQueryData<Vendor | undefined>(
          ['vendors', vendorID],
          () => {
            return newVendor
          }
        )
      }

      queryClient.invalidateQueries(['vendors', vendorID])
    },
  }
}

export function useInvalidateVendors() {
  const queryClient = useQueryClient()

  return {
    invalidateVendors: () => {
      queryClient.invalidateQueries(['vendors'])
    },
  }
}

export function useVendor({
  vendorID,
  ...rest
}: {
  vendorID: string | undefined
} & Omit<UseQueryOptions<Vendor, Error>, 'enabled' | 'queryFn' | 'queryKey'>) {
  return useQuery<Vendor, Error>({
    ...rest,
    enabled: !!vendorID,
    queryFn: () => {
      if (!vendorID) {
        throw new Error('No vendor ID was provided')
      }

      return getVendor({ vendorID })
    },
    queryKey: ['vendors', vendorID],
  })
}

export function useVendors({
  limit,
  ...rest
}: {
  limit: number
} & Omit<
  UseInfiniteQueryOptions<GetVendorsResponse, Error>,
  'getNextPageParam' | 'queryFn' | 'queryKey'
>) {
  return useInfiniteQuery<GetVendorsResponse, Error>({
    ...rest,
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor
    },
    queryFn: ({ pageParam: nextCursor }) => {
      return getVendors({
        cursor: nextCursor,
        limit,
      })
    },
    queryKey: ['vendors', limit],
  })
}
