import { IngredientRecipe } from 'types/domainModels/recipes'

import { useEditRecipe } from 'hooks/recipes/recipes'
import InlineEditInput from 'components/common/InlineEditInput'

const EditablePartName = ({
  part,
}: {
  part: IngredientRecipe
}): JSX.Element => {
  const { mutateAsync: editRecipe } = useEditRecipe({
    recipeID: part.id,
  })

  return (
    <InlineEditInput
      initialValue={part.name}
      isEditable
      name="part-name"
      onChange={(name) => {
        return editRecipe({ name })
      }}
    >
      <h1 className="text-2xl leading-none">{part.name}</h1>
    </InlineEditInput>
  )
}

export default EditablePartName
