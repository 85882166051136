import { clsx } from 'clsx'
import { ReactNode } from 'react'

export interface Props {
  hasError?: boolean
  label: ReactNode
  labelFor?: string
}

const FormFieldLabel = ({
  hasError = false,
  label,
  labelFor,
}: Props): JSX.Element => {
  return (
    <label
      className={clsx('mb-1 flex items-center text-sm uppercase', {
        'text-red': hasError,
        'text-grey': !hasError,
      })}
      htmlFor={labelFor}
    >
      {label}
    </label>
  )
}

export default FormFieldLabel
