import { ReactNode } from 'react'

const Card = ({ children }: { children: ReactNode }): JSX.Element => {
  return <div className="bg-white shadow-md">{children}</div>
}

export default Card

export const CardBody = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  return <div className="p-4">{children}</div>
}
