import { Form, Formik, FormikErrors, useFormikContext } from 'formik'
import { isEmpty } from 'lodash-es'

import { ReactSelectValue } from 'types/internal'
import { State } from 'types/domainModels/shared'
import { STATE_OPTIONS } from 'utils/states'
import { Vendor } from 'types/domainModels/purchasedGoods'

import { useCreateVendor } from 'hooks/purchasedGoods/vendors'
import FormInput from 'components/common/FormInput'
import FormSelect from 'components/common/FormSelect'
import Modal, {
  ModalBody,
  ModalButtons,
  ModalHeader,
} from 'components/common/Modal'

interface FormData {
  address: {
    city: string
    line1: string
    line2: string
    state: ReactSelectValue<State> | null
    zip: string
  }
  contact: {
    email: string
    name: string
  }
  name: string
}

export interface Props {
  onCloseModal(): void
  onCreateVendor(vendor: Vendor): void
}

const validateFormData = ({
  formData,
}: {
  formData: FormData
}): FormikErrors<FormData> => {
  const errors: FormikErrors<FormData> = {}

  if (!formData.name) {
    errors.name = 'Please enter a name.'
  }

  return errors
}

const CreateVendorModal = ({
  onCloseModal,
  onCreateVendor,
}: Props): JSX.Element => {
  const {
    error: createVendorError,
    isError: hasCreateVendorError,
    isLoading: isCreatingVendor,
    mutate: createVendor,
  } = useCreateVendor({
    onSuccess: (vendor) => {
      onCreateVendor(vendor)
    },
  })

  return (
    <Modal onCloseModal={onCloseModal}>
      <ModalBody>
        <div className="w-create-vendor-modal">
          <div className="mb-4">
            <ModalHeader onClickClose={onCloseModal}>Create Vendor</ModalHeader>
          </div>

          <Formik<FormData>
            initialValues={{
              address: {
                city: '',
                line1: '',
                line2: '',
                state: null,
                zip: '',
              },
              contact: {
                email: '',
                name: '',
              },
              name: '',
            }}
            onSubmit={(values) => {
              return createVendor({
                contact: {
                  address: {
                    city: values.address.city,
                    line1: values.address.line1,
                    line2: values.address.line2,
                    state: values.address.state?.value ?? '',
                    zip: values.address.zip,
                  },
                  email: values.contact.email,
                  name: values.contact.name,
                },
                name: values.name,
              })
            }}
            validate={(formData) => {
              return validateFormData({ formData })
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
          >
            <Form>
              <CreateVendorForm
                createError={hasCreateVendorError ? createVendorError : null}
                isCreating={isCreatingVendor}
                onCancel={onCloseModal}
              />
            </Form>
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CreateVendorModal

const CreateVendorForm = ({
  createError,
  isCreating,
  onCancel,
}: {
  createError: Error | null
  isCreating: boolean
  onCancel(): void
}): JSX.Element => {
  const { errors } = useFormikContext<FormData>()

  return (
    <>
      <div className="space-y-4">
        <FormInput
          autoFocus={true}
          id="vendor-name"
          label="Vendor Name"
          labelFor="vendor-name"
          name="name"
        />

        <div className="space-y-2">
          <h3>Address</h3>
          <div className="grid grid-cols-2 gap-4">
            <FormInput
              id="line1"
              label="Line 1"
              labelFor="line1"
              name="address.line1"
            />
            <FormInput
              id="line2"
              label="Line 2"
              labelFor="line2"
              name="address.line2"
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <FormInput
              id="city"
              label="City"
              labelFor="city"
              name="address.city"
            />
            <FormSelect
              inputId="state"
              label="State"
              labelFor="state"
              name="address.state"
              options={STATE_OPTIONS}
            />
            <FormInput id="zip" label="Zip" labelFor="zip" name="address.zip" />
          </div>
        </div>

        <div className="space-y-2">
          <h3>Contact Information</h3>
          <div className="grid grid-cols-2 gap-4">
            <FormInput
              id="contact-name"
              label="Name"
              labelFor="contact-name"
              name="contact.name"
            />
            <FormInput
              id="email"
              label="Email"
              labelFor="email"
              name="contact.email"
              type="email"
            />
          </div>
        </div>
      </div>

      <div className="mt-8">
        <ModalButtons
          apiError={createError}
          hasValidationErrors={!isEmpty(errors)}
          isLoading={isCreating}
          mainActionBtnText="Create"
          onCancel={onCancel}
        />
      </div>
    </>
  )
}
