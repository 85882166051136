import { Outlet } from 'react-router-dom'

const PurchasedGoodsPageLayout = (): JSX.Element => {
  return (
    <div className="mx-auto max-w-content px-4 pt-8 pb-40">
      <Outlet />
    </div>
  )
}

export default PurchasedGoodsPageLayout
