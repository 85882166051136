import { createPortal } from 'react-dom'
import { motion } from 'framer-motion'

import CheckIcon from './icons/CheckIcon'
import ExclamationCircleIcon from './icons/ExclamationCircleIcon'
import XIcon from './icons/XIcon'

export type ToastType = 'error' | 'success'

let toastRoot = document.getElementById('toast-root')
if (!toastRoot) {
  toastRoot = document.createElement('div')
  toastRoot.setAttribute('id', 'toast-root')
  document.body.appendChild(toastRoot)
}

const variants = {
  closed: { y: '100%' },
  open: { y: '-2rem' },
}

const Toast = ({
  isOpen,
  message,
  onCloseToast,
  type,
}: {
  isOpen: boolean
  message: string
  onCloseToast(): void
  type: ToastType
}): JSX.Element => {
  let icon = (
    <div className="h-6 w-6 flex-shrink-0 text-red">
      <ExclamationCircleIcon />
    </div>
  )

  if (type === 'success') {
    icon = (
      <div className="h-6 w-6 flex-shrink-0 text-green">
        <CheckIcon />
      </div>
    )
  }

  return createPortal(
    <motion.div
      animate={isOpen ? 'open' : 'closed'}
      className="fixed bottom-0 right-8 z-10 flex w-toast space-x-4 rounded border border-light-grey p-4 shadow-md"
      variants={variants}
    >
      <div className="flex grow items-start space-x-2">
        {icon}
        <p>{message}</p>
      </div>
      <div
        className="h-4 w-4 shrink-0 cursor-pointer text-dark-grey  "
        onClick={() => {
          onCloseToast()
        }}
      >
        <XIcon />
      </div>
    </motion.div>,
    toastRoot as HTMLDivElement
  )
}

export default Toast
