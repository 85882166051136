import { flatten, orderBy, sumBy, uniqBy } from 'lodash-es'
import { FormikErrors } from 'formik'

import {
  Draft,
  DraftLineItem,
  DraftLineItemPurchasedGood,
  DraftLineItemRecipe,
  DraftStatus,
  IngredientShort,
  PurchasedGoodCosts,
  PurchasedGoodFacilityNetworkCost,
  RecipeProcurability,
  RecipeFacilityNetworkCost,
} from 'types/domainModels/recipes'
import { FacilityNetworkID } from 'types/domainModels/shared'
import { gramsToLbs } from './general'
import { ReactSelectValue } from 'types/internal'

export interface LineItemFormData {
  ingredientID: ReactSelectValue<string> | null
  weight: number | ''
}

export interface LineItemFormDataValidated {
  ingredientID: ReactSelectValue<string>
  weight: number
}

const DRAFT_STATUS_FSM: { [status in DraftStatus]: DraftStatus[] } = {
  in_development: ['ready_for_tasting'],
  ready_for_tasting: ['tasting_approved', 'tasting_rejected'],
  tasting_approved: [],
  tasting_rejected: [],
}

export const DRAFT_STATUS_OPTIONS: ReactSelectValue<DraftStatus>[] = [
  { label: 'In Development', value: 'in_development' },
  { label: 'Ready for Tasting', value: 'ready_for_tasting' },
  { label: 'Tasting Approved', value: 'tasting_approved' },
  { label: 'Tasting Rejected', value: 'tasting_rejected' },
]

export function canPromoteDraft(draft: Draft) {
  return draft.status === 'tasting_approved'
}

export function getDraftStatusDisplay(status: DraftStatus) {
  return (
    DRAFT_STATUS_OPTIONS.find(({ value }) => {
      return value === status
    })?.label ?? 'Unknown Status'
  )
}

export function getDraftStatusOptions(status: DraftStatus) {
  const allowedStatuses = DRAFT_STATUS_FSM[status]

  return DRAFT_STATUS_OPTIONS.filter(({ value }) => {
    return allowedStatuses.includes(value)
  })
}

function getFacilityNetworkCost({
  costs,
  draft,
  facilityNetworkID,
}: {
  costs: (PurchasedGoodCosts | RecipeProcurability)[]
  draft: Draft
  facilityNetworkID: FacilityNetworkID
}): number | null {
  const totalWeight = getTotalWeight(draft)

  let facilityNetworkCost = 0

  for (let i = 0; i < draft.lineItems.length; i++) {
    const lineItem = draft.lineItems[i]

    const costForLineItem = costs.find((cost) => {
      if (isPurchasedGoodLineItem(lineItem) && 'procurementID' in cost) {
        return cost.procurementID === lineItem.procurementID
      } else if (isRecipeLineItem(lineItem) && 'recipeId' in cost) {
        return cost.recipeId === lineItem.id
      }

      return false
    })

    if (!costForLineItem) {
      return null
    }

    const lineItemPercentWeight = lineItem.quantityGrams / totalWeight
    const { costPerPound } =
      costForLineItem.facilityNetworkCosts[facilityNetworkID] || {}

    if (costPerPound === null || costPerPound === undefined) {
      return null
    }

    facilityNetworkCost += costPerPound * lineItemPercentWeight
  }

  return facilityNetworkCost
}

export function getLineItemCostSums({
  costs,
  draft,
}: {
  costs: (PurchasedGoodCosts | RecipeProcurability)[]
  draft: Draft
}) {
  return {
    chicago: getFacilityNetworkCost({
      costs,
      draft,
      facilityNetworkID: 'chicago',
    }),
    slc: getFacilityNetworkCost({ costs, draft, facilityNetworkID: 'slc' }),
  }
}

export function getLineItemCostVersion(
  cost: PurchasedGoodFacilityNetworkCost | RecipeFacilityNetworkCost | undefined
) {
  if (!cost) {
    return ''
  }

  if ('versionNumber' in cost) {
    return `${cost.versionNumber}`
  }

  return [cost.vendorName, cost.vendorSku].filter((value) => value).join(' / ')
}

export function getLineItemOptions({
  draft,
  ingredients,
  partID,
}: {
  draft: Draft
  ingredients: IngredientShort[]
  partID: string
}): IngredientShort[] {
  const draftIngredients = draft.lineItems.map(({ id }) => id)

  return ingredients.filter(({ id }) => {
    const ingredientAlreadyInDraft = draftIngredients.includes(id)
    const isPartBeingViewed = partID === id

    return !ingredientAlreadyInDraft && !isPartBeingViewed
  })
}

export function getLineItemScaledWeight({
  lineItem,
  postCookingYield,
  targetWeight,
  totalWeight,
}: {
  lineItem: DraftLineItem
  postCookingYield: number
  targetWeight: number | ''
  totalWeight: number
}): number | null {
  if (targetWeight === '' || postCookingYield === 0) {
    return null
  }

  const proportion = targetWeight / (totalWeight * postCookingYield)

  return lineItem.quantityGrams * proportion
}

export function getLineItemsFromDrafts(drafts: Draft[]) {
  return uniqBy(
    flatten(
      drafts.map(({ lineItems }) => {
        return lineItems.map(({ id, name }) => {
          return { id, name }
        })
      })
    ),
    'name'
  )
}

export function getOrderedDrafts(drafts: Draft[]) {
  return orderBy(drafts, ({ draftNumber }) => draftNumber)
}

export function getPostCookingStats(draft: Draft) {
  const totalWeight = getTotalWeight(draft)

  return {
    developmentYield:
      draft.postCookWeightGrams && totalWeight
        ? draft.postCookWeightGrams / totalWeight
        : null,
    portionsPerRecipe:
      draft.postCookWeightGrams && draft.targetPortion
        ? draft.postCookWeightGrams / draft.targetPortion
        : null,
  }
}

export function getTotalPostCookingYield({
  draft,
  postCookingYield,
}: {
  draft: Draft
  postCookingYield: number
}) {
  return sumBy(draft.lineItems, ({ quantityGrams }) =>
    gramsToLbs(quantityGrams * postCookingYield)
  )
}

export function getTotalWeight(draft: Draft) {
  return sumBy(draft.lineItems, ({ quantityGrams }) => Number(quantityGrams))
}

export function isDraftEditable(draft: Draft) {
  return draft.status === 'in_development'
}

export function isPurchasedGoodLineItem(
  lineItem: DraftLineItem
): lineItem is DraftLineItemPurchasedGood {
  return lineItem.ingredientType === 'purchased_good'
}

export function isRecipeLineItem(
  lineItem: DraftLineItem
): lineItem is DraftLineItemRecipe {
  return lineItem.ingredientType === 'recipe'
}

export function validateAddLineItemData(formData: LineItemFormData) {
  const errors: FormikErrors<LineItemFormData> = {}

  if (!formData.ingredientID) {
    errors.ingredientID = 'Required'
  }

  if (formData.weight === '') {
    errors.weight = 'Required'
  }

  return errors
}
