import { useQuery, UseQueryOptions } from 'react-query'

import {
  getCategories,
  getStorageLocations,
  getTCSZones,
} from 'services/purchasedGoods/tools'

export function useCategories(
  opts: Omit<UseQueryOptions<string[], Error>, 'queryFn' | 'queryKey'> = {}
) {
  return useQuery<string[], Error>({
    ...opts,
    queryFn: () => {
      return getCategories()
    },
    queryKey: ['categories'],
  })
}

export function useStorageLocations(
  opts: Omit<UseQueryOptions<string[], Error>, 'queryFn' | 'queryKey'> = {}
) {
  return useQuery<string[], Error>({
    ...opts,
    queryFn: () => {
      return getStorageLocations()
    },
    queryKey: ['storage-locations'],
  })
}

export function useTCSZones(
  opts: Omit<UseQueryOptions<string[], Error>, 'queryFn' | 'queryKey'> = {}
) {
  return useQuery<string[], Error>({
    ...opts,
    queryFn: () => {
      return getTCSZones()
    },
    queryKey: ['tcs-zones'],
  })
}
