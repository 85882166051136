import { omitBy, round } from 'lodash-es'
import { ReactNode } from 'react'

export function formatCommas(value: number): string {
  return new Intl.NumberFormat().format(value)
}

export function formatPercent(value: number): string {
  return `${round(value * 100, 2)}%`
}

export function gramsToLbs(grams: number): number {
  return grams / 453.59237
}

export function highlightMatchingText({
  searchText,
  textToHighlight,
}: {
  searchText: string
  textToHighlight: string
}): ReactNode {
  if (searchText === '' && textToHighlight === '') {
    return ''
  } else if (searchText === '') {
    return textToHighlight
  }

  const labelSearchMatchStart = textToHighlight
    .toLowerCase()
    .indexOf(searchText.toLowerCase())
  if (labelSearchMatchStart === -1) {
    return textToHighlight
  }

  const endOfMatch = labelSearchMatchStart + searchText.length

  const start = textToHighlight.substring(0, labelSearchMatchStart)
  const match = textToHighlight.substring(labelSearchMatchStart, endOfMatch)
  const end = textToHighlight.substring(endOfMatch)

  return (
    <span>
      {start}
      <span className="font-bold">{match}</span>
      {end}
    </span>
  )
}

export function removeQueryParamsEmptyKeys(
  obj: Record<string, string | null | undefined | ''>
) {
  return omitBy(obj, (value) => {
    return value === undefined || value === ''
  })
}
