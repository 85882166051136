import { flatMap } from 'lodash-es'
import { Link, useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import {
  getIngredients,
  GetIngredientsResponse,
} from 'services/recipes/ingredients'
import { IngredientShort } from 'types/domainModels/recipes'

import APIErrorDisplay from 'components/common/APIErrorDisplay'
import Button from 'components/common/Button'
import CreatePartModal from './CreatePartModal'
import InfiniteScrollTrigger from 'components/common/InfiniteScrollTrigger'
import SearchParts from './SearchParts'
import TableHeader from 'components/common/TableHeader'

const ListPartsPage = (): JSX.Element => {
  const {
    data: getIngredientsResults,
    error: loadIngredientsError,
    fetchNextPage,
    hasNextPage,
    isError: hasLoadIngredientsError,
    isFetchingNextPage,
    isLoading: isLoadingIngredients,
  } = useInfiniteQuery<GetIngredientsResponse, Error>(
    ['ingredients'],
    ({ pageParam: nextCursor }) => {
      return getIngredients({
        limit: 50,
        nextCursor,
      })
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
    }
  )

  const ingredients = flatMap(getIngredientsResults?.pages, (page) => {
    return page.ingredients
  })

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl" id="parts-header">
          Parts
        </h1>

        <CreatePart />
      </div>

      <SearchParts />

      <PartsTable
        hasLoadIngredientsError={hasLoadIngredientsError}
        hasNextPage={!!hasNextPage}
        ingredients={ingredients}
        isLoadingIngredients={isLoadingIngredients}
        isLoadingNextPage={isFetchingNextPage}
        loadIngredientsError={loadIngredientsError}
        onLoadMoreIngredients={fetchNextPage}
      />
    </>
  )
}

export default ListPartsPage

const CreatePart = (): JSX.Element => {
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <div className="w-28">
      <Button onClick={() => setIsOpen(true)}>+ New Part</Button>

      {isOpen && (
        <CreatePartModal
          onCloseModal={closeModal}
          onCreatePart={(part) => {
            navigate(`/parts/${part.id}/drafts`)
          }}
        />
      )}
    </div>
  )
}

const PartsTable = ({
  hasLoadIngredientsError,
  hasNextPage,
  ingredients,
  isLoadingIngredients,
  isLoadingNextPage,
  loadIngredientsError,
  onLoadMoreIngredients,
}: {
  hasLoadIngredientsError: boolean
  hasNextPage: boolean
  ingredients: IngredientShort[]
  isLoadingIngredients: boolean
  isLoadingNextPage: boolean
  loadIngredientsError: Error | null
  onLoadMoreIngredients(): void
}): JSX.Element => {
  const partsGridClasses =
    'grid grid-cols-1 gap-4 items-center justify-items-center'
  const partsTableRowClasses = `${partsGridClasses} py-5 sm:text-sm border-b border-light-grey`

  return (
    <div aria-describedby="parts-header" aria-label="Parts" role="grid">
      <div className="sticky top-[45px] bg-white">
        <TableHeader>
          <div className={`${partsGridClasses} pt-4`} role="row">
            <span className="justify-self-start" role="columnheader">
              Part
            </span>
          </div>
        </TableHeader>
      </div>
      <div role="rowgroup">
        {ingredients.length === 0 && (
          <>
            {isLoadingIngredients ? (
              <PartsTableLoadingRows
                partsTableRowClasses={partsTableRowClasses}
              />
            ) : (
              <>
                {hasLoadIngredientsError ? (
                  <div className="mt-2">
                    <APIErrorDisplay error={loadIngredientsError} />
                  </div>
                ) : (
                  <p className="py-2 text-sm">No parts</p>
                )}
              </>
            )}
          </>
        )}

        {ingredients.map(({ id, name }) => {
          return (
            <div key={id} className={partsTableRowClasses} role="row">
              <div className="justify-self-start" role="cell">
                <Link className="text-blue" to={`/parts/${id}`}>
                  {name}
                </Link>
              </div>
            </div>
          )
        })}

        {isLoadingNextPage ? (
          <PartsTableLoadingRows partsTableRowClasses={partsTableRowClasses} />
        ) : hasNextPage ? (
          <InfiniteScrollTrigger onTriggered={onLoadMoreIngredients} />
        ) : null}
      </div>
    </div>
  )
}

const PartsTableLoadingRows = ({
  partsTableRowClasses,
}: {
  partsTableRowClasses: string
}): JSX.Element => {
  return (
    <>
      {new Array(5).map((_unused, i) => {
        return (
          <div key={i} className={partsTableRowClasses}>
            <div className="h-4 w-1/2 animate-pulse justify-self-start bg-light-grey" />
          </div>
        )
      })}
    </>
  )
}
