import { clsx } from 'clsx'
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean
  rightIcon?: ReactNode
}

const Input = forwardRef<HTMLInputElement, Props>(function Input(
  { hasError = false, rightIcon = null, ...rest },
  ref
) {
  const input = (
    <input
      ref={ref}
      className={clsx('h-8 w-full rounded border px-2 text-sm', {
        'border-red': hasError,
        'border-light-grey': !hasError,
        'pr-8': !!rightIcon,
      })}
      data-testid={rest.name}
      {...rest}
    />
  )

  if (rightIcon) {
    return (
      <div className="relative">
        {input}
        <div className="absolute right-2 top-1/2 -translate-y-1/2">
          {rightIcon}
        </div>
      </div>
    )
  }

  return input
})

export default Input
