import {
  Ingredient,
  IngredientPurchasedGood,
  IngredientRecipe,
} from 'types/domainModels/recipes'

export function isIngredientPurchasedGood(
  ingredient: Ingredient
): ingredient is IngredientPurchasedGood {
  return ingredient.purchasedGood !== null
}

export function isIngredientRecipe(
  ingredient: Ingredient
): ingredient is IngredientRecipe {
  return ingredient.recipe !== null
}
