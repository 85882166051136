import CircleLoader from './CircleLoader'
import Input, { Props as InputProps } from 'components/common/Input'

export type Props = InputProps & { isLoading?: boolean }

const InputAsync = ({
  disabled,
  isLoading = false,
  ...rest
}: Props): JSX.Element => {
  const isDisabled = disabled || isLoading

  return (
    <Input
      {...rest}
      disabled={isDisabled}
      rightIcon={isLoading ? <CircleLoader isColored={true} /> : null}
    />
  )
}

export default InputAsync
