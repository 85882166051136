import { get, patch } from './baseAPI'
import { ProductionTags } from 'types/domainModels/recipes'

export interface EditProductionTags {
  data: { productionTags: ProductionTags }
  procedureID: string
}

export interface GetProductionTags {
  procedureID: string
}

export const ENDPOINTS = {
  EDIT_PRODUCTION_TAGS: ({
    procedureID,
  }: Omit<EditProductionTags, 'data'>) => ({
    path: `/procedures/${procedureID}/tags`,
    version: 'procurement/v1' as const,
  }),
  GET_PRODUCTION_TAGS: ({ procedureID }: GetProductionTags) => ({
    path: `/procedures/${procedureID}/tags`,
    version: 'procurement/v1' as const,
  }),
}

export async function editProductionTags({
  data,
  procedureID,
}: EditProductionTags): Promise<ProductionTags> {
  return patch<ProductionTags>({
    ...ENDPOINTS.EDIT_PRODUCTION_TAGS({ procedureID }),
    data,
  })
}

export async function getProductionTags(
  opts: GetProductionTags
): Promise<ProductionTags> {
  return get<ProductionTags>(ENDPOINTS.GET_PRODUCTION_TAGS(opts))
}
