import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { Ingredient } from 'types/domainModels/recipes'
import {
  isIngredientPurchasedGood,
  isIngredientRecipe,
} from 'utils/ingredients'

import { usePart } from 'hooks/recipes/parts'
import Breadcrumbs from 'components/common/Breadcrumbs'
import ErrorDisplay from 'components/common/ErrorDisplay'
import PartDrafts from './PartDrafts'
import PartPageLoading from './PartPageLoading'
import PartVersions from './PartVersions'

const PartPage = (): JSX.Element => {
  const { partID } = useParams()

  const {
    data: part,
    error: getPartError,
    isError: hasLoadPartError,
    isLoading: isLoadingPart,
  } = usePart({ partID })

  if (isLoadingPart) {
    return <PartPageLoading />
  }

  if (!part || hasLoadPartError) {
    return (
      <ErrorDisplay
        message={`Could not load part.${
          getPartError && ` Error: ${getPartError.message}`
        }`}
      />
    )
  }

  return <PartPageLoaded part={part} />
}

export default PartPage

const PartPageLoaded = ({ part }: { part: Ingredient }): JSX.Element => {
  if (isIngredientRecipe(part)) {
    return (
      <Routes>
        <Route element={<PartDrafts part={part} />} path="drafts/*" />
        <Route element={<PartVersions part={part} />} path="versions/*" />
        <Route element={<Navigate replace to="versions" />} path="*" />
      </Routes>
    )
  }

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            link: '/parts',
            text: 'Parts',
          },
          { link: `/parts/${part.id}`, text: part.name },
        ]}
      />

      <h1 className="mb-4 text-2xl">{part.name}</h1>

      {isIngredientPurchasedGood(part) ? (
        <p className="text-sm">
          This part is a purchaed good. For now, use Google Sheets for
          management of purchased goods.
        </p>
      ) : (
        <p className="text-sm">
          This part has not yet been defined as an purchased good or a recipe.
          Please contact support if you were expecting to use this part.
        </p>
      )}
    </>
  )
}
